import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const BreadcrumbContainer = styled.div`
  padding-top: 0.5em;
  padding-bottom: 1em;
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`

export const Breadcrumb = (props) => <Link {...props}>{props.place}</Link>

const Breadcrumbs = (props) => (
  <BreadcrumbContainer>
    {props.breadcrumbs
      .map((link) => <Breadcrumb key={link.place} {...link} />)
      .reduce((prev, curr, i) => [prev, <span key={i}> / </span>, curr])}
  </BreadcrumbContainer>
)

export default Breadcrumbs
