import axios from 'axios'
import { from, merge } from "rxjs"
import { userLogin, userLoginFailure, userLoginSuccess } from './actions'
import { USER_LOGIN, GET_USER_CREDENTIAL, USER_LOGOUT } from './actionTypes'
import { ofType } from 'redux-observable'
import { map, mergeMap, catchError } from 'rxjs/operators'
import apiUrls, {oprUrls} from "constants/apiUrls"

export default (action$) => merge(
  action$.pipe(
    ofType(USER_LOGOUT),
    mergeMap(()=>(
      from(
        axios.get(apiUrls.userLogout)
      ).pipe(
       map(() => (window.location.href = oprUrls.baseUrl))
      )
    ))
  ),
  action$.pipe(
    ofType(USER_LOGIN),
    mergeMap(({action}) => (
      axios.get(apiUrls.userLogin, { headers: action.headers})
        .then(
          ({ data }) => userLoginSuccess(data),
          error => userLoginFailure(error)
        )
    ))
  ),
  action$.pipe(
    ofType(GET_USER_CREDENTIAL),
    mergeMap(() => (
      axios.get(oprUrls.baseUrl + oprUrls.credentials, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": oprUrls.baseUrl,
        }
      }).then(
        ({data}) => userLogin({
          headers: {
            "Authorization": "Bearer " + data?.cognito_access_token,
            "X-Refresh-Token": data?.cognito_refresh_token,
          }
        }),
        error => userLoginFailure(error),
      )
    ))
  )
)