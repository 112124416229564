function roundTimeUp(time) {
  let [hour, minute] = time.split(':').map((x) => Number(x))
  if (minute > 0) {
    hour += 1
  }
  return `${String(hour).padStart(2, '0')}:${String(0).padStart(2, '0')}:00`
}

function roundTimeDown(time, numIntervals) {
  const interval = 60 / numIntervals
  let [hour, minute] = time.split(':').map((x) => Number(x))
  minute = Math.floor(minute / interval) * interval
  return `${String(hour).padStart(2, '0')}:${String(0).padStart(2, '0')}:00`
}

export const createHoursFilter = (hours_of_operation, numIntervals) =>
  hours_of_operation.reduce(
    (hours, entry) => {
      if (entry.time_start === '00:00:00' && entry.time_start === entry.time_end) {
        return hours
      }
      const time_start = roundTimeDown(entry.time_start, numIntervals)
      const time_end = roundTimeUp(entry.time_end)
      return {
        time_start: hours.time_start < time_start ? hours.time_start : time_start,
        time_end: hours.time_end > time_end ? hours.time_end : time_end,
      }
    },
    { time_start: '24:00:00', time_end: '00:00:00' },
  )
