import React, {useState} from 'react'
import { connect } from 'react-redux'
import { withFormik, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { Container } from "react-bootstrap"

import { LoaderContainer } from 'components/shared/Loader'
import { isProgramResident } from 'stores/programs'

import {
  RippleButton,
  ButtonContainer,
  Input,
  FormContainer,
  DateInput,
} from 'components/shared/Forms'
import { DATE_DISPLAY_FORMAT } from 'utils/dateFormatter'
import { Row, NiceHr } from 'components/shared/Layout'
import BlockHeader  from 'components/shared/BlockHeader'

import { actions } from 'stores/resources'
import routes, {url} from "constants/routes";
const { createEmployees } = actions

const EmployeeForm = (props) => {
  const {
    values,
    handleSubmit,
    isSubmitted,
    isValid,
    inspection,
    isProgramResident,
    program,
    /* and other goodies */
  } = props

  const EMPLOYEE = isProgramResident ? 'Employee / Resident' : 'Employee'
  return (
    <Container>
      <Form autoComplete="none">
        <BlockHeader>Add {EMPLOYEE}</BlockHeader>
        <NiceHr />
        <input type="hidden" id="id" name="id" value={values.id} />
        <input type="hidden" id="inspection_id" name="inspection_id" value={values.inspection_id} />
        <input type="hidden" id="program_id" name="program_id" value={values.program_id} />
        <Row>
          <Input name="first_name" label="First Name" className="col-sm-4" required={true} {...props} />
          <Input name="last_name" label="Last Name" className="col-sm-4" required={true} {...props} />
          <Input name="opin" label="OPIN" className="col-sm-4" {...props} />
        </Row>
        <Row>
          <DateInput name="hire_date" label="Date Of Hire" className="col-sm-4" required={true} {...props} />
        </Row>
        <NiceHr />
        <ButtonContainer>
          <RippleButton
            type="submit"
            disabled={!isValid || isSubmitted}
            className={`btn ${isValid ? 'btn-success' : 'btn-warning'}`}
            onClick={handleSubmit}
          >
            {isSubmitted ? 'Submitting...' : `Add ${EMPLOYEE}`}
          </RippleButton>
          {!isSubmitted && (
            <Link to={url({program_id: program.id, inspection_id: inspection.id})(routes.employees)} className="btn btn-default">
              Cancel
            </Link>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  )
}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  handleSubmit: (values, { setSubmitting, props }) => {
    Object.keys(values).forEach((key) => {
      if (key.match(/opin/)) {
        values[key] = values[key].replace('-', '')
      }
    })
    props.submit({
      ...props.employee,
      ...values,
    })
    setSubmitting(false)
    return values
  },
  mapPropsToValues: (props) => {
    const { inspection, program } = props
    return {
      first_name: '',
      last_name: '',
      opin: '',
      hire_date: '',
      inspection_id: inspection.id,
      program_id: program.id,
      ...props.employee,
    }
  },
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    opin: Yup.string().matches(
      /^[0-9]{4}[-]{0,1}[0-9]{4}$/,
      'OPIN must be numeric input in the format XXXXXXXX or XXXX-XXXX',
    ),
    hire_date: Yup.date()
      .max(new Date(), `Date of Hire must be on or before ${moment().format(DATE_DISPLAY_FORMAT)}`)
      .required('Required'),
  }),
  displayName: 'EmployeeForm',
})

const EnhancedForm = formikEnhancer(EmployeeForm)

const AddEmployeeForm = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { employee, ...rest } = props
  const submit = (data) => {
    setIsSubmitted(true)
    props.onSubmit(data)
  }
  const cancel = () => props.cancel()
  if (isSubmitted) {
    return <LoaderContainer />
  }
  return <EnhancedForm employee={employee} {...rest} submit={submit} isSubmitted={isSubmitted} />
}

export default connect(
  (state, props) => ({
    isProgramResident: isProgramResident(state, props.program_id)
  }),
  {
    onSubmit: createEmployees
  },
)(AddEmployeeForm)
