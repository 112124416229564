import React, { useState, useEffect, useRef } from 'react'

const Measure = ({ children, style }) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const ref = useRef(null)

  function update() {
    if (ref.current) {
      const clientRect = ref.current.getClientRects()[0]
      setHeight(clientRect.height)
      setWidth(clientRect.width)
    }
  }

  useEffect(() => {
    function requestUpdate() {
      window.requestAnimationFrame(update)
    }
    requestUpdate()
    window.addEventListener('resize', requestUpdate)
    return () => window.removeEventListener('resize', requestUpdate)
  }, [])

  return (
    <div ref={ref} style={{ ...style, flex: '1 1 auto' }}>
      {children({ width, height })}
    </div>
  )
}

export default Measure
