import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom'
import swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Container } from "react-bootstrap"
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoaderContainer } from 'components/shared/Loader'
import Breadcrumbs from 'components/shared/Breadcrumbs'
import { SectionHeader } from 'components/shared/Layout'
import { RippleButton } from 'components/shared/Forms'
import AddInspectionModal  from 'components/Inspections/AddInspectionModal'
import EmployeeRecordCharts from 'components/EmployeeRecordCharts'
import TransitionProvider from 'components/TransitionProvider'

import { createInspection } from "stores/inspections"
import { getProgram, getProgramById } from "stores/programs"
import { isLoading } from "stores/resources";

const breadcrumbs = [
  {
    to: '/',
    place: 'ERC',
  },
  {
    to: '/programs',
    place: 'Programs',
  },
]

const ReactSwal = withReactContent(swal)

const ProgramLoader = props => {

  const {
    program,
    program_id,
    getProgramConnect,
    createInspection,
    isLoading,
  } = props

  if (!program || (program.id !== program_id)) {
    getProgramConnect(program_id)
    return <LoaderContainer />
  }

  const addInspection = (evaluation_type) => {
    createInspection(program.id, evaluation_type)
  }

  const handleAddInspection = async (event) => {
    const { value: evaluation_type } = await ReactSwal.fire({
      title: 'Add ERC',
      icon: 'info',
      html: <AddInspectionModal submitFn={addInspection} closeFn={ReactSwal.close} actionLabel={'Add ERC'} />,
      showCancelButton: false,
      showConfirmButton: false,
      confirmButtonText: 'Add ERC',
      reverseButtons: true,
      preConfirm: () => {
        return document.getElementById('evaluation_type').value
      },
    })
  }

  const {
    id,
    program_name,
    license_number,
    program_addr_1,
    program_addr_2,
    program_city,
    program_state,
    program_zip,
    program_phone,
  } = program

  return (
    <Container>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h2>{program_name}</h2>
      <h5>{license_number}</h5>
      <section className="mts">
        <h5>Address</h5>
        <div>{program_addr_1}</div>
        <div>{program_addr_2}</div>
        <div>{program_city}</div>
        <div>{program_state}</div>
        <div>{program_zip}</div>
        <div>{program_phone}</div>
      </section>
      <section>
        <SectionHeader>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ flex: 'auto' }}>Employee Record Charts</div>
            <RippleButton
              onClick={handleAddInspection}
              className="btn btn-primary"
              disabled={isLoading}
              style={{ margin: '0em 1em' }}
            >
              {
                isLoading
                ? <>{"Creating ERC "}<FontAwesomeIcon icon={faSpinner} spin /></>
                : "New ERC"
              }
            </RippleButton>
          </div>
        </SectionHeader>
        <br />
        <EmployeeRecordCharts program={program} />
        <TransitionProvider />
      </section>
    </Container>
  )
}

const ProgramLoaderConnected = connect((state, ownProps) => {
  return {
    program: getProgramById(state, ownProps.program_id),
    isLoading: isLoading(state, 'inspections')
  }
}, {
  getProgramConnect: getProgram,
  createInspection,
})(ProgramLoader)

export default (props) => {
  return <ProgramLoaderConnected {...props} {...useParams()} />
}