import React from 'react'
import ReactTable from 'react-table-6'
import { TooltipRippleButton } from 'components/shared/Forms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRemove } from '@fortawesome/free-solid-svg-icons'
import useSticky from "hooks/useSticky";

export default (props) => {
  const { data = [], onEdit = () => false, isEditable, onRemove = () => false } = props
  const age_groups = {
    toddlers: 'Toddlers',
    infants: 'Infants',
    preschool: 'Preschool',
    schoolage: 'Schoolage',
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Age Groups',
      accessor: 'age_groups',
      Cell: row => (
        <span>
          {Object.entries(row.original.age_groups)
            .filter(([key, value]) => value)
            .map(([key, value]) => age_groups[key])
            .join(', ')}
        </span>
      ),
    },
  ]
  if(isEditable) {
    columns.push({
      Header: 'Action',
      accessor: 'id',
      sortable: false,
      Cell: row => (
        <div style={{ display: 'flex' }}>
          <TooltipRippleButton
            onClick={(e) => onEdit(row.original.id)}
            className="btn btn-default mrm"
            tooltip="Edit"
          >
            <FontAwesomeIcon icon={faEdit} />
          </TooltipRippleButton>
          <TooltipRippleButton onClick={(e) => onRemove(row.original.id)} className="btn btn-danger" tooltip="Remove">
            <FontAwesomeIcon icon={faRemove} />
          </TooltipRippleButton>
        </div>
      ),
    })
  }
  const tableName = 'groups-table'
  const {stickyClass} = useSticky(tableName)
  function onPageChange() {
    const offsetTop = document.getElementsByClassName(tableName)?.[0]?.offsetTop
    window.scrollTo(0,  offsetTop ? (offsetTop - 50) : 0)
  }
  return (
    <ReactTable
      columns={columns}
      data={data}
      defaultPageSize={20}
      minRows={2}
      onPageChange={onPageChange}
      className={`${tableName} ${stickyClass}`}
    />
  )
}
