import {
  actionTypes,
  registerActionTypes,
  registerAction,
  createReducer,
} from 'stores/resources'

import apiUrls from "constants/apiUrls"

export default () => {

  registerActionTypes('contributor')

  registerAction('getContributors', (id) => ({
    type: actionTypes.GET_CONTRIBUTORS,
    payload: { id },
    url: apiUrls.contributors,
    method: 'get',
  }))

  registerAction('getContributorsSuccess', (contributors) => ({
    type: actionTypes.GET_CONTRIBUTORS_SUCCESS,
    payload: { contributors },
  }))

  return createReducer('contributors')
}