import {
  GET_INSPECTION,
  GET_INSPECTION_SUCCESS,
  GET_EVALUATION_TYPES,
  CREATE_INSPECTION,
  CREATE_INSPECTION_SUCCESS,
  UPDATE_INSPECTION,
  UPDATE_INSPECTION_SUCCESS,
  GET_INSPECTIONS,
  GET_INSPECTIONS_SUCCESS,
  DELETE_INSPECTION,
  DELETE_INSPECTION_SUCCESS,
  COMPLETE_ERC,
  COMPLETE_ERC_SUCCESS,
  UNLOCK_ERC,
  UNLOCK_ERC_SUCCESS,
  CLONE_ERC,
  CLONE_ERC_SUCCESS,
  GET_EVALUATION_TYPES_SUCCESS,
} from './actionTypes'

export const getInspection = (id) => ({ type: GET_INSPECTION, payload: { id } })
export const getInspectionSuccess = (inspection) => ({ type: GET_INSPECTION_SUCCESS, payload: { inspection } })

export const getInspections = (program_id) => ({ type: GET_INSPECTIONS, payload: { program_id } })
export const getInspectionsSuccess = (inspections) => ({ type: GET_INSPECTIONS_SUCCESS, payload: { inspections } })

export const getEvaluationTypes = (inspection_id) => ({
  type: GET_EVALUATION_TYPES,
  payload: { inspection_id },
})
export const getEvaluationTypesSuccess = (evaluation_types) => ({
  type: GET_EVALUATION_TYPES_SUCCESS,
  payload: { evaluation_types },
})

export const createInspection = (program_id, evaluation_type) => ({
  type: CREATE_INSPECTION,
  payload: { program_id, evaluation_type },
})
export const createInspectionSuccess = (inspection) => ({ type: CREATE_INSPECTION_SUCCESS, payload: { inspection } })

export const updateInspection = (inspection) => ({
  type: UPDATE_INSPECTION,
  payload: { inspection },
})
export const updateInspectionSuccess = (inspection) => ({ type: UPDATE_INSPECTION_SUCCESS, payload: { inspection } })

export const deleteInspection = (id) => ({ type: DELETE_INSPECTION, payload: { id } })
export const deleteInspectionSuccess = (id) => ({ type: DELETE_INSPECTION_SUCCESS, payload: { id } })

export const completeERC = (id, evaluation_types) => ({ type: COMPLETE_ERC, payload: { id, evaluation_types } })
export const completeERCSuccess = ({ inspections }) => ({ type: COMPLETE_ERC_SUCCESS, payload: { inspections } })

export const unlockERC = (id, reason) => ({ type: UNLOCK_ERC, payload: { id, reason } })
export const unlockERCSuccess = (inspections) => ({ type: UNLOCK_ERC_SUCCESS, payload: { inspections } })

export const cloneERC = (id, evaluation_types) => ({ type: CLONE_ERC, payload: { id, evaluation_types } })
export const cloneERCSuccess = (inspections) => ({ type: CLONE_ERC_SUCCESS, payload: { inspections } })
