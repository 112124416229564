import styled from 'styled-components'

export default styled.tr`
  td {
    width: 60px;
    border-left: solid 2px gray;
    border-right: solid 2px gray;
  }
  th {
    width: 60px;
    text-align: center;
  }
  margin-right: 17px;
`
