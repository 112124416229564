// 1. We will first consider REACT_APP_OPR_URL in the .env file (.env.development or .env.production.local)
// 2. if not set, we will attempt to retrieve it from the window object (var set in server/app/Views/config.html)
// 3. all else failed, we will derive the opr url from our current url
const {origin, host} = window.location;
const isLocal = ["erc.registry.test", "localhost"].indexOf(host) !== -1;
const oprBase = process.env.REACT_APP_OPR_URL
  || window.localStorage.getItem("oprUrl")
  || String(origin).replace("erc.", isLocal ? "" : "registry.");

export const oprUrls = {
  baseUrl: oprBase,
  credentials: "/api/public/auth/credentials",
}

export default {
  // inspections
  inspections: '/api/inspections', // get, get one, post, put, delete
  finalizeErc: '/api/inspections/finalize',
  unlockErc: '/api/inspections/unlock',
  cloneErc: '/api/inspections/clone',
  checkId: '/api/inspections/check_id',

  // groups
  groups: '/api/groups',

  // is this used anywhere?
  evaluationTypes: '/api/inspection/evaluation_types',

  // contributors
  contributors: '/api/inspections/contributors',

  // employees
  employees: '/api/employees', // get, get one, create, delete
  addEmployee: '/api/inspections/add_professional',
  employeeSearch: '/api/employees/search',

  // evaluations
  evaluations: '/api/evaluations', // get, get one, put

  // programs
  programs: '/api/programs', // get, get one

  // schedule
  staffSchedule: '/api/staffschedule', // get, post, delete

  // training overview
  trainingOverview: "/api/employees/training_overview",

  // training overview
  roleHistory: "/api/employees/roles",

  //coverage tool
  coverageTool: "/api/coveragetool",

  userLogin: "/api/auth/login",
  userLogout: "/api/auth/logout",
};
