import React from 'react'

import { withFormik, Form } from 'formik'
import { SelectButtonInput, FormContainer, ButtonContainer, RippleButton } from 'components/shared/Forms'
import { Row, Column, SectionHeader, NiceHr } from 'components/shared/Layout'
import * as Yup from 'yup'

const AddInspection = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    course,
    cancel,
    submit,
    actionLabel,
    /* and other goodies */
  } = props
  //   debugger
  return (
    <FormContainer>
      <Form>
        <Row>
          <SelectButtonInput
            name="evaluation_types"
            label="Choose an ERC Type"
            {...props}
            options={[
              { key: 'Licensing ERC', value: 'Licensing ERC' },
              { key: 'SUTQ ERC', value: 'SUTQ ERC' },
              { key: 'Licensing ERC, SUTQ ERC', value: 'Combined ERC' },
            ]}
          />
        </Row>
      </Form>
      <NiceHr />
      <ButtonContainer>
        <RippleButton
          type="submit"
          disabled={!isValid}
          className={`btn ${isValid ? 'btn-success' : 'btn-warning'}`}
          onClick={handleSubmit}
        >
          {actionLabel}
        </RippleButton>
        <RippleButton type="button" className="btn btn-default" onClick={props.closeFn}>
          Close
        </RippleButton>
      </ButtonContainer>
    </FormContainer>
  )
}

const formikEnhancer = withFormik({
  // enableReinitialize: true,
  isInitialValid: (props) => !!props.evaluation_types,
  handleSubmit: (values, { setSubmitting, props }) => {
    if (typeof props.submitFn === 'function') {
      props.submitFn(values.evaluation_types)
    }
    props.closeFn(values.evaluation_types)
    setSubmitting(false)
    return values.evaluation_types
  },
  mapPropsToValues: (props) => {
    return {
      evaluation_types: props.evaluation_types
        ? props.evaluation_types
            .split(',')
            .map((x) => x.trim())
            .join(', ')
        : '',
    }
  },
  validationSchema: Yup.object().shape({
    evaluation_types: Yup.string().required('Required'),
  }),
  displayName: 'SelectEvalTypeForm',
})

const EnhancedForm = formikEnhancer(AddInspection)

export default (props) => <EnhancedForm {...props} />
