import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { withFormik, Form, FieldArray, ErrorMessage } from 'formik'
import swal from 'sweetalert2'
import * as Yup from 'yup'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { convertDisplayDateToDB } from 'utils/dateFormatter'
import {
  SelectInput,
  Input,
  FormContainer,
  DateInput,
  RippleButton,
  Checkbox,
} from 'components/shared/Forms'
import { Row, SectionHeader } from 'components/shared/Layout'
import { ScheduleTable } from './ScheduleTable'
import AddButton  from 'components/shared/Buttons/AddButton'
import Error from 'components/shared/Forms/Error'
import apiUrls from "constants/apiUrls";
import serviceErrorHandler from "utils/serviceErrorHandler";

const showDays = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
}
const dayMap = { 1: 'sunday', 2: 'monday', 3: 'tuesday', 4: 'wednesday', 5: 'thursday', 6: 'friday', 7: 'saturday' }

const createNewPosition = (isLicensing) => {
  const licensing = isLicensing
    ? {
        licensing_start_date: '',
        licensing_end_date: '',
        licensing_position: '',
      }
    : {}

  return {
    group: '',
    role_job_title: '',
    role_start_date: '',
    role_end_date: '',
    selected: true,
    schedule: [],
    visible: true,
    ...licensing,
  }
}

const colors = [
  '#ea838a',
  '#eab083',
  '#83a7ea',
  '#9283ea',
  '#83dbea',
  '#ea83bd',
  '#9283ea',
  '#83dbea',
  '#83eab0',
  '#83eae3',
  '#8aea83',
]

const getSelected = (schedules) => {
  return schedules.reduce((selected, group, index) => {
    return group.selected ? selected.concat({ key: `schedules.${index}.schedule`, value: colors[index] }) : selected
  }, [])
}

const getSchedule = (schedules = []) => {
  return schedules.reduce((schedule, group, index) => {
    if (group.visible) {
      group.color = colors[index]
      const { selected, id, visible } = group
      const groupSchedule = {
        color: colors[index],
        selected,
        visible,
        id,
        schedule: group.schedule,
      }
      return schedule.concat(groupSchedule)
    }
    return schedule
  }, [])
}

const StaffScheduleForm = (props) => {
  const {
    values,
    dirty,
    handleSubmit,
    isEditable,
    isSubmitting,
    isLicensing,
    onRemove,
    setFieldValue,
    validateForm,
    inspection = {},
  } = props

  useEffect(() => {
    validateForm()
    return () => {
      !isSubmitting && dirty && handleSubmit()
    }
  }, [])

  const [groups, setGroups] = useState([])
  const { id: erc_id } = inspection

  const fetchGroups = () => {
    if (erc_id) {
      axios
        .get(`${apiUrls.groups}?erc_id=${erc_id}`)
        .then(
          ({ data }) => {
            setGroups(data)
            setFieldValue("groups", data)
          }
        )
        .catch(serviceErrorHandler)
    }
  }

  useEffect(() => {
    fetchGroups()
  }, [erc_id])

  const { hours_of_operation = [] } = inspection
  const days_of_operation = hours_of_operation.reduce((acc, x) => ({ ...acc, [dayMap[x.day_of_week]]: true }), showDays)
  return (
    <FormContainer>
      <Form autoComplete={'off'}>
        <SectionHeader noMarginTop>
          <div className="flex__row">
            <div className="flex--auto">Staff Schedule</div>
            {isEditable && (
              <div className="flex--nogrow">
                <RippleButton className="btn btn-warning" type="submit">
                  Save
                </RippleButton>
              </div>
            )}
          </div>
        </SectionHeader>
        <Row>
          <div className="col-md-6">
            <FieldArray
              name="schedules"
              render={(arrayHelpers) => (
                <div>
                  <h5>Groups</h5>
                  <Checkbox
                    name="is_variable_schedule"
                    disabled={!isEditable}
                    label="Is Schedule Variable"
                    {...props}
                  />
                  {values.schedules.map((group, index) => {
                    const isVisible = values.schedules[index].visible
                    const isActive = values.schedules[index].selected
                    return (
                      <div
                        key={index}
                        style={{
                          // border: `solid thin gray`,
                          borderRadius: '1em',
                          border: isActive ? `thick solid ${colors[index]}` : 'none',
                          display: 'flex',
                          marginTop: '0.5em',
                          marginBottom: '0.5em',
                          overflow: 'hidden',
                          backgroundColor: '#FEFEFE',
                          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                        }}
                      >
                        <div className="flex--auto flex__row flex__row--center-center" key={index}>
                          <div className="flex--auto">
                            <ErrorMessage
                              name={`schedules.${index}.schedule`}
                              component={Error}
                              style={{ padding: '5px 10px' }}
                            />
                            <div className="flex__row flex__row--center-center">
                              <SelectInput
                                name={`schedules.${index}.group_id`}
                                label="Group"
                                className="flex--auto pas"
                                disabled={!isEditable}
                                style={{ padding: '0.5em 0.5em' }}
                                {...props}
                              >
                                <optgroup>
                                  <option value="">Select an option.</option>
                                </optgroup>
                                {groups.map((d) => (
                                  <option key={`role-${index}-group-${d.id}`} value={d.id}>{d.name}</option>
                                ))}
                              </SelectInput>

                              {isEditable && (
                                <div className="flex--auto" style={{ paddingTop: '1.6em' }}>
                                  <RippleButton
                                    type="button"
                                    id={'group-selector'}
                                    name="group-selector"
                                    className={`btn ${isActive ? 'btn-success' : 'btn-default'}`}
                                    disabled={!isEditable}
                                    onClick={() => {
                                      setFieldValue(`schedules.${index}.visible`, true)
                                      values.schedules.forEach((group, gindex) =>
                                        setFieldValue(`schedules.${gindex}.selected`, gindex === index),
                                      )
                                    }}
                                  >
                                    {isActive ? 'Selected' : 'Select'}
                                  </RippleButton>
                                </div>
                              )}

                              <div className="flex--auto" style={{ paddingTop: '1.6em' }}>
                                <RippleButton
                                  type="button"
                                  className={`btn ${isVisible ? 'btn-success' : 'btn-default'}`}
                                  onClick={() => {
                                    setFieldValue(`schedules.${index}.visible`, !isVisible)
                                  }}
                                >
                                  <FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash} size={"sm"} />
                                  {' '}
                                  {isVisible ? 'Shown' : 'Hidden'}
                                </RippleButton>
                              </div>

                              {isEditable && (
                                <div className="flex--auto" style={{ paddingTop: '1.6em' }}>
                                  <RippleButton
                                    type="button"
                                    className={`btn ${isVisible ? 'btn-default' : 'btn-default'}`}
                                    onClick={async () => {
                                      const { value: result } = await swal.fire({
                                        icon: 'warning',
                                        // typ
                                        text: `Remove ${values.schedules[index].group || ''} - ${values.schedules[index]
                                          .role_job_title || ''}?`,
                                        showCancelButton: true,
                                        confirmButtonText: 'Remove',
                                        confirmButtonColor: '#f8bb86',
                                        reverseButtons: true,
                                      })
                                      if (result) {
                                        if (values.schedules[index] && values.schedules[index].id) {
                                          onRemove(values.schedules[index].id)
                                        }
                                        arrayHelpers.remove(index)
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} size={"sm"} /> Remove
                                  </RippleButton>
                                </div>
                              )}
                            </div>
                            <div className="flex__row">
                              <Input
                                name={`schedules.${index}.role_job_title`}
                                label="Job Title"
                                className="flex-auto pas"
                                disabled={!isEditable}
                                {...props}
                              />

                              <DateInput
                                name={`schedules.${index}.role_start_date`}
                                label="Role Start Date"
                                className="flex-auto pas"
                                bottom={true}
                                disabled={!isEditable}
                                {...props}
                              />

                              <DateInput
                                name={`schedules.${index}.role_end_date`}
                                label="Role End Date"
                                bottom={true}
                                className="flex-auto pas"
                                disabled={!isEditable}
                                {...props}
                              />
                            </div>
                            {isLicensing && (
                              <div className="flex__row">
                                <Input
                                  name={`schedules.${index}.licensing_position`}
                                  label="Licensing Position"
                                  className="flex-auto pas"
                                  disabled={!isEditable}
                                  {...props}
                                />

                                <DateInput
                                  name={`schedules.${index}.licensing_start_date`}
                                  label="Licensing Start Date"
                                  className="flex-auto pas"
                                  bottom={true}
                                  disabled={!isEditable}
                                  {...props}
                                />

                                <DateInput
                                  name={`schedules.${index}.licensing_end_date`}
                                  label="Licensing End Date"
                                  className="flex-auto pas"
                                  bottom={true}
                                  disabled={!isEditable}
                                  {...props}
                                />
                              </div>
                            )}
                          </div>
                          <div
                            className="flex--nogrow"
                            style={{
                              height: '100%',
                              width: '1em',
                              backgroundColor: colors[index],
                            }}
                          />
                        </div>

                        <br />
                      </div>
                    )
                  })}
                  {isEditable && (
                    <div className="flex__row flex__row--center-center" style={{ margin: '1em' }}>
                      <AddButton
                        type="button"
                        onClick={() => {
                          values.schedules.forEach((schedule, index) => {
                            setFieldValue(`schedules.${index}.selected`)
                          })
                          arrayHelpers.push(createNewPosition(isLicensing))
                        }}
                        className="btn-primary"
                      />
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <div className="col-lg-6">
            <ScheduleTable
              {...props}
              restrictTimes={true}
              numIntervals={4}
              schedule={getSchedule(values.schedules)}
              selected={getSelected(values.schedules)}
              isSelectable={isEditable}
              {...{ days_of_operation, hours_of_operation }}
            />
          </div>
        </Row>
      </Form>
    </FormContainer>
  )
}

const formikEnhancer = withFormik({
  initialValues: { groups: [] },
  enableReinitialize: false,
  handleSubmit: (values, bag) => {
    const { setSubmitting, props } = bag
    const { submitFn } = props
    const {schedules = [], groups = []} = values
    const data = schedules && schedules.map(group => {
      const selectedGroup = groups && groups.find(g => g.id === group.group_id);
      Object.entries(group).forEach(([key, value]) => {
        if (key.match(/date/)) {
          group[key] = value && value !== '0000-00-00' ? convertDisplayDateToDB(value) : value
        } else if (key === "group" && selectedGroup) {
          group.group = selectedGroup.name
        }
      })
      return group
      }
    );
    submitFn(
      data,
      !values.is_variable_schedule,
    )
    setSubmitting(false)
    return values
  },
  validationSchema: (values) => {
    const arrayShape = {
      schedule: Yup.array().when('role_job_title', {
        is: value => (value && String(value).toLowerCase().match(/(resident|substitute)/)),
        then: () => Yup.array(),
        otherwise: () => Yup.array().when('role_end_date', {
          is: value => (!value || value === '0000-00-00'),
          then: () => Yup.array().min(1, 'A schedule must be defined on the right for this group.'),
          otherwise: () => Yup.array(),
        }),
      }),
    }

    return Yup.object().shape({
      schedules: Yup.array().of(Yup.object().shape(arrayShape)),
    })
  },
  mapPropsToValues: ({ schedules = [] }) => {
    return {
      is_variable_schedule: schedules.length > 0 ? schedules[0].is_regular_schedule === '0' : false,
      schedules,
    }
  },
  displayName: 'ProgramInformationForm',
})

const EnhancedForm = formikEnhancer(StaffScheduleForm)

export default (props) => {
  return <EnhancedForm {...props} />
}
