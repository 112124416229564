import axios from 'axios'
import { from, merge, of } from 'rxjs'
import { filter, map, mergeMap, catchError } from 'rxjs/operators'
import { actions } from './index'
import { getErrorMessage } from "utils/serviceErrorHandler";

function convertTypeToAction(name) {
  return name
    .split('_')
    .map((x, index) => `${index === 0 ? x[0].toLowerCase() : x[0]}${x.slice(1).toLowerCase()}`)
    .join('')
}

export default (action$) =>
  action$.pipe(
    filter((action) => {
      return action.url
    }),
    mergeMap((action) => {
      const params = !!['get', 'delete'].find((x) => action.method === x) ? { params: action.payload } : action.payload
      return from(axios[action.method](action.url, params)).pipe(
        map(({ data }) => actions[convertTypeToAction(`${action.type}_SUCCESS`)](data)),
        catchError((error) => {
          return of({
            type: `${action.type}_FAILURE`,
            payload: getErrorMessage(error) ,
            error: error,
          })
          }
        ),
      )
    }),
  )
