import React, { Fragment, useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import InputContainer from './InputContainer'
import CharCounter from './CharCounter'
import Error from './Error'
import getValue from './getValue'

const isRequired = (message) => (value) => (!!value ? undefined : message)

const Checkbox = (props) => {
  const {
    errors,
    touched,
    name,
    id,
    disabled = false,
    required = false,
    hideError,
    errorMessage,
    label,
    values,
    className,
    maxLength,
    setFieldValue,
    handleChange,
    setFieldTouched,
    hideLabel = false,
  } = props
  const showError = errors[name] && touched[name]

  useEffect(() => {
    if (value === null) {
      setFieldValue(name, '', false)
    }
  }, [])

  const value = getValue(values, name)
  let style = { display: 'flex', alignItems: 'center' }
  style = showError
    ? Object.assign(style, { border: 'thin solid red', borderRadius: '0.5em', padding: '0.5em' })
    : style
  return (
    <Fragment>
      <div className={className} style={style}>
        <Field
          id={id || name}
          name={name}
          disabled={disabled}
          required={required}
          type="checkbox"
          onChange={(e) => {
            setFieldTouched(name, true)
            handleChange(e)
            props.setFieldValue(name, e.target.checked)
          }}
          onBlur={props.handleBlur}
          checked={value > 0 ? value : false}
          value={value || ''}
          validate={isRequired(required ? (errorMessage ? errorMessage : 'This field is required') : undefined)}
          style={{ margin: 0}}
          className={(showError ? 'error' : '') + ''}
        />
        {!hideLabel && (
          <label htmlFor={name} style={{ margin: '0.5em 2em 0.5em 0.5em'}}>
            {typeof label == 'Function' ? label() : label}
          </label>
        )}
      </div>
      {!hideError && <ErrorMessage name={props.name} component={Error} />}
    </Fragment>
  )
}

export default Checkbox
