import React, { useEffect } from 'react'

import { Navigate } from 'react-router-dom'

import { connect } from 'react-redux'
import { clearTransition, getTransition } from 'stores/transition'

const TransitionProvider = (props) => {
  const { clearTransition, transition } = props

  useEffect(() => {
    if (transition) {
      clearTransition()
    }
  }, [transition])

  if (transition) {
    return <Navigate to={transition} />
  }
  return <div />
}

export default connect((state) => ({ transition: getTransition(state) }), {
  clearTransition,
})(TransitionProvider)
