import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

const Close = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 2em;
  height: 2em;
  align-items: center;
  display: flex;
  justify-content: center;
  :hover {
    background-color: #efefef;
    border-radius: 3em;
  }
`

export default ({ handleClick }) => (
  <Close onClick={handleClick}>
    <FontAwesomeIcon icon={faAngleLeft} className="mrs"/>
  </Close>
)
