import React from 'react'
import { ScheduleTable } from 'components/StaffSchedule/ScheduleTable'
import { Container } from "react-bootstrap"

const numIntervals = 4
const columns = [
  { label: 'FA', key: 'first_aid', width: '36px' },
  { label: 'CPR', key: 'cpr', width: '36px' },
  { label: 'MCD', key: 'mcd', width: '36px' },
  { label: 'CA/N 6/3', key: 'can63', width: '36px' },
  { label: 'CA 1 Hour', key: 'can1', width: '36px' },
]
const CoverageTool = (props) => {
  const { schedule, erc } = props
  const { days_of_operation, hours_of_operation = [{ time_start: '00:00:00', time_end: '23:00:00' }] } = erc

  return (
    <Container fluid>
      <div style={{width: "fit-content", margin: "auto", marginTop: "20px"}}>
        <ScheduleTable
          restrictTimes={true}
          numIntervals={numIntervals}
          isSelectable={false}
          columns={columns}
          schedule={schedule}
          selected={[]}
          {...{ days_of_operation, hours_of_operation }}
        />
      </div>
    </Container>
  )
}

export default CoverageTool
