import React from 'react'
import { Field, ErrorMessage } from 'formik'
import InputContainer from './InputContainer'
import CharCounter from './CharCounter'
import Error from './Error'
import getValue from './getValue'

const isRequired = (message) => (value) => (!!value ? undefined : message)

const Input = (props) => {
  const {
    errors,
    touched,
    name,
    disabled,
    required = false,
    label,
    optional,
    min,
    max,
    values,
    className,
    style,
    maxLength,
    type = 'text',
    component = 'input',
    hideLabel = false,
    placeholder,
    step,
    customValidation,
    setFieldValue,
    ...rest
  } = props
  const properties = { min, max, type, component, name, placeholder, step }
  const showError = getValue(errors, name) && getValue(touched, name)

  return (
    <InputContainer className={className} style={style}>
      {!hideLabel && (
        <label htmlFor={name}>
          {label} {optional && <em style={{ color: 'gray' }}>(optional)</em>}
        </label>
      )}
      <Field
        id={name}
        disabled={disabled || false}
        required={required}
        {...properties}
        onChange={props.onChange || props.handleChange}
        onBlur={e => {
          setFieldValue(name, e.target.value.trim())
          props.handleBlur(e)
        }}
        validate={customValidation ? customValidation : isRequired(required ? 'This field is required' : undefined)}
        style={showError ? { borderColor: 'red' } : {}}
        className={(showError ? 'text-input error' : 'text-input') + ' form-control col-sm-12'}
      />
      {maxLength && <CharCounter value={getValue(values, name)} maxLength={maxLength} />}
      <ErrorMessage 
        style={showError ? { color: 'red' } : {}}
        name={props.name} 
        component={Error} 
      />
    </InputContainer>
  )
}

export default Input
