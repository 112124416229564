import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { actions, getResourceBy } from 'stores/resources'
import { isEditable, isLicensing, isSUTQ } from 'stores/inspections'
import EmployeeView  from 'components/Employee/EmployeeView'
import { LoaderContainer } from 'components/shared/Loader'
import { Container } from "react-bootstrap"

const EmployeeContainer = (props) => {
  const { erc_employee_id, employee, inspection_id: erc_id, getEmployees } = props

  useEffect(() => {
    if(!employee) {
      getEmployees(erc_id)
    }
  }, [employee])

  if (!employee) {
    return <LoaderContainer />
  }
  return (
    <Container>
      <EmployeeView {...{ ...props, erc_id }} />
    </Container>
  )
}

export default connect(
  (state, { erc_employee_id, inspection_id }) => ({
    employee: getResourceBy(state, 'employees', { key: 'erc_employee_id', value: erc_employee_id })[0],
    isEditable: isEditable(state, { id: inspection_id }),
    isLicensing: isLicensing(state, inspection_id),
    isSUTQ: isSUTQ(state, inspection_id),
  }),
  {
    getEmployees: actions.getEmployees,
  },
)(EmployeeContainer)
