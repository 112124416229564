import { List } from 'immutable'

export const getInspectionsByProgram = (state, id) =>
  List(Object.values(state.inspections).filter((x) => x.program_id === id))
export const getInspectionById = (state, id) => Object.values(state.inspections).find((x) => x.id === id)
export const accessInspections = (state) => List(Object.values(state.inspections))
export const isLoading = (state) => state.inspections.isLoading

export const isLicensing = (state, id) => {
  const inspection = getInspectionById(state, id)
  return inspection.evaluation_abbrs && inspection.evaluation_abbrs.includes('Licensing ERC')
}

export const isSUTQ = (state, id) => {
  const inspection = getInspectionById(state, id)
  return inspection.evaluation_abbrs && inspection.evaluation_abbrs.includes('SUTQ ERC')
}

export const isEditable = (state, props) => {
  const { id } = props
  const inspection = getInspectionById(state, id)
  return Boolean(Number(inspection.is_editable)) && !inspection.completed_by
}
