import swal from 'sweetalert2'
import goToLocation  from 'utils/goToLocation'
import serviceErrorHandler from "utils/serviceErrorHandler";

const updateState = (state, resources, overwrite) =>
  resources.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr,
    }),
    { ...(overwrite ? {} : state), isLoading: false },
  )

const removeItems = (state, resources) =>
  Object.keys(state)
    .filter((id) => !resources.find((x) => x === id))
    .reduce(
      (prev, id) => ({
        ...prev,
        [id]: state[id],
      }),
      {},
    )

const arrayifyPayload = (payload) => {
  if (!Array.isArray(payload)) {
    return [payload]
  }
  return payload
}

const setLoading = (state, payload) => {
  return {
    ...state,
    isLoading: true,
  }
}

export default (resource, initialState = {}) => {
  const resourceName = resource.toUpperCase()
  return (state = initialState, action) => {
    if (!action.payload) {
      return state
    }
    let resources = arrayifyPayload(action.payload[resource])
    const { overwrite = false } = action.payload
    switch (action.type) {
      case `CREATE_${resourceName}`:
      case `SAVE_${resourceName}`:
      case `GET_${resourceName}`:
      case `DELETE_${resourceName}`:
        return setLoading(state, action.payload)
      case `CREATE_${resourceName}_SUCCESS`:
        if (action.toast) {
          swal.fire({
            title: action.toast,
            icon: 'success',
          })
        }
        if (action.goto) {
          goToLocation(action.goto)
        }
        return updateState(state, resources, overwrite)
      case `GET_${resourceName}_SUCCESS`:
        return updateState(state, resources)
      case `UPDATE_${resourceName}`:
        return updateState(state, resources)
      case `SAVE_${resourceName}_SUCCESS`:
        return updateState(state, resources)
      case `SAVE_${resourceName}_FAILURE`:
        if(action.payload) {
          swal.fire({
            title: "Error",
            html: action.payload,
            icon: 'error',
          })
        }
        return {
          ...state,
          isLoading: false,
        }
      case `DELETE_${resourceName}_SUCCESS`:
        return {
          ...removeItems(state, resources),
          isLoading: false,
        }
      default:
        if (action.type.match(`${resourceName}_FAILURE`)) {
          serviceErrorHandler(action.error)
        }
        if (action.type.match(`${resourceName}`)) {
          return {
            ...state,
            isLoading: false,
          }
        }
        return state
    }
  }
}
