import React, { Component } from 'react'
import Loader from 'components/shared/Loader'

class PageContainer extends Component {
  render() {
    return this.props.is_loading ? (
      <div className="flex__row flex__row--center-center" style={{ minHeight: '200px' }}>
        <Loader />
      </div>
    ) : (
      <div style={{ fontSize: '16px', paddingTop: "50px", paddingBottom: "20px" }}>
        {this.props.children}
      </div>
    )
  }
}

export default PageContainer
