import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Container } from "react-bootstrap"

import { Link } from 'react-router-dom'
import { isProgramResident } from 'stores/programs'
import { SectionHeader } from 'components/shared/Layout'
import { EmployeeTable } from 'components/Employees'
import TableControls from 'components/shared/TableControls'
import routes, {url} from "constants/routes"

const TrainingOverview = (props) => {
  const [query, setQuery] = useState('')
  const [employees, setEmployees] = useState(props.employees)

  useEffect(() => {
    setEmployees(
      props.employees.filter((x) =>
        Object.values(x).some((value) =>
          String(value)
            .toLowerCase()
            .match(query.toLowerCase()),
        ),
      ),
    )
  }, [props.employees, query])

  const {
    inspection_id,
    program_id,
    inspection,
    removeEmployees,
    isLoading,
    isEvaluationsLoading,
    isFormDirty,
    isLicensing,
    isSUTQ,
    isProgramResident,
    isEditable,
    children
  } = props
  if (!inspection) {
    return <div>Loading!</div>
  }
  const employeesText = isProgramResident ? 'Employees / Residents' : 'Employees'
  const search = (event) => setQuery(event.target.value)
  return (
    <Container>
      {children}
      <SectionHeader>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ flex: 'auto' }}>{employeesText}</div>
          {isEditable && (
            <Link
              to={url({inspection_id, program_id})(routes.employeesFind)}
              className="btn btn-primary btn-ripple"
              style={{ margin: '0em 1em' }}
            >
              Add {employeesText}
            </Link>
          )}
        </div>
      </SectionHeader>
      <TableControls query={query} onSearch={search} placeholder={`Filter ${employeesText}...`} noPadding />
      {
        isEditable &&
          <div className="flex__row--end-center mbm">
            <button
              disabled={!isFormDirty || isEvaluationsLoading}
              className="btn btn-primary btn-ripple"
              onClick={
                () => props.onSave && props.onSave()
              }
            >
              Save
            </button>
          </div>
      }
      <EmployeeTable
        employees={employees}
        columns={props.columns}
        isLoading={isLoading || isEvaluationsLoading}
        {...{ isLicensing, isSUTQ, removeEmployees, isEditable }}
        onSave={props.onSave}
      />
    </Container>
  )
}

export default connect((state, props) => ({ isProgramResident: isProgramResident(state, props.program_id) }))(
  TrainingOverview,
)
