import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

import { LoaderContainer } from 'components/shared/Loader'
import CoverageTool from './CoverageTool'
import { isLicensing } from 'stores/inspections'
import { isProgramCenter, centersWithCoverage } from 'stores/programs'
import apiUrls from "constants/apiUrls";
import serviceErrorHandler from "utils/serviceErrorHandler";

const CoverageToolContainer = (props) => {
  const { inspection, isLicensing, isProgramCenter, centersWithCoverage } = props
  const [schedule, setSchedule] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getData = () => {
    axios.get(`${apiUrls.coverageTool}/${inspection.id}`).then(
      ({data}) => {
        data.forEach((entry) => {
          const { time_start, time_end } = entry
          let [hour, minutes, seconds] = time_start.split(':')
          if (minutes == '15') {
            entry.time_start = `${hour}:00:00`
          } else if (minutes == '45') {
            entry.time_start = `${hour}:30:00`
          }
        })
        setSchedule([
          {
            group: 'Coverage Tool',
            color: 'green',
            selected: true,
            visible: true,
            schedule: data,
          },
        ])
        setIsLoading(false)
      }
    ).catch(serviceErrorHandler)
  }

  useEffect(() => {
    getData()
  }, [])

  if (!(isLicensing && centersWithCoverage)) {
    return <Navigate to="/" />
  }

  if (isLoading) {
    return <LoaderContainer />
  }
  return <CoverageTool erc={inspection} schedule={schedule} />
}

export default connect((state, { inspection, program_id }) => ({
  isLicensing: isLicensing(state, inspection.id),
  isProgramCenter: isProgramCenter(state, { program_id }),
  centersWithCoverage: centersWithCoverage(state, { program_id }),
}))(CoverageToolContainer)
