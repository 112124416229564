import React, { Fragment } from 'react'
import moment from 'moment';
import { SelectInput, DateInput } from 'components/shared/Forms'
import { Row, SectionHeader } from 'components/shared/Layout'
import getValue from 'components/shared/Forms/getValue'

export default (props) => {
  const { values, isEditable, isProgramTypeA, isProgramTypeB, adminOptions, handleAdminChange, isProgramCenter } = props

  const today = moment();
  const minDate = isProgramCenter && today.isAfter('2020-12-31') ? moment().subtract(5, 'years').toDate() : null;
  const maxDate = isProgramCenter ? today.toDate() : null;
  
  if (isProgramTypeB) return <Fragment />
  return (
    <Fragment>
      <Row>
        <SectionHeader isInRow>Administrators</SectionHeader>
        <SelectInput
          name="administrators[0].employee_id"
          label="Administrator"
          className="col-sm-6"
          disabled={!isEditable}
          {...props}
          handleChange={handleAdminChange}
        >
          <option value="" disabled>
            Please select an administrator
          </option>
          {adminOptions}
        </SelectInput>
        {!isProgramTypeA && (
          <DateInput
            name="administrators[0].date_training"
            label="Date Administrator Training Completed"
            className="col-sm-6"
            required={!!getValue(values, 'administrators[1].employee_id')}
            minDate={minDate}
            maxDate={maxDate}
            disabled={!isEditable || !getValue(values, 'administrators[0].employee_id')}
            {...props}
          />
        )}
      </Row>

      <Row>
        <SelectInput
          name="administrators[1].employee_id"
          label="Administrator"
          className="col-sm-6"
          {...props}
          disabled={!isEditable}
          handleChange={handleAdminChange}
        >
          <option value="" />
          {adminOptions}
        </SelectInput>
        {!isProgramTypeA && (
          <DateInput
            name="administrators[1].date_training"
            label="Date Administrator Training Completed"
            className="col-sm-6"
            required={!!getValue(values, 'administrators[1].employee_id')}
            minDate={minDate}
            maxDate={maxDate}
            disabled={!isEditable || !getValue(values, 'administrators[1].employee_id')}
            {...props}
          />
        )}
      </Row>

      <Row>
        <SelectInput
          name="administrators[2].employee_id"
          label="Administrator"
          className="col-sm-6"
          {...props}
          disabled={!isEditable}
          handleChange={handleAdminChange}
        >
          <option value="" />
          {adminOptions}
        </SelectInput>
        {!isProgramTypeA && (
          <DateInput
            name="administrators[2].date_training"
            label="Date Administrator Training Completed"
            className="col-sm-6"
            required={!!getValue(values, 'administrators[2].employee_id')}
            minDate={minDate}
            maxDate={maxDate}
            disabled={!isEditable || !getValue(values, 'administrators[2].employee_id')}
            {...props}
          />
        )}
      </Row>
    </Fragment>
  )
}
