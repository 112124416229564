import axios from 'axios'
import {from, of} from "rxjs"
import {getProgramSuccess} from './actions'
import {GET_PROGRAM} from './actionTypes'
import { ofType } from 'redux-observable'
import { map, mergeMap, catchError } from 'rxjs/operators'
import apiUrls from "constants/apiUrls"
import { getErrorMessage } from "utils/serviceErrorHandler";

const errorHandler = (actionType) => (error) => {
  return of({
    type: actionType,
    payload: getErrorMessage(error),
    error: error,
  })
}

export default (action$) => action$.pipe(
    ofType(GET_PROGRAM),
    mergeMap(action =>
      from(axios.get(`${apiUrls.programs}/${action.payload.id}`)).pipe(
        map(({ data }) => getProgramSuccess(data)),
        catchError(errorHandler(action.type + "_FAILURE"))
      ))
)