import React, { useEffect, useState } from 'react'
import {Link, unstable_usePrompt as usePrompt} from "react-router-dom";

import {Container, OverlayTrigger, Popover} from "react-bootstrap"

import { connect } from 'react-redux'
import { isProgramResident } from "stores/programs"
import { putEmployeeEvaluations } from 'stores/evaluations'
import { actions, getResourceBy, isLoading } from 'stores/resources'
import { getInspection, isEditable, isSUTQ, isLicensing } from 'stores/inspections'
import { LoaderContainer } from 'components/shared/Loader'
import { SectionHeader } from "components/shared/Layout"
import TableControls from "components/shared/TableControls"
import { EmployeeTable } from "components/Employees/index"
import routes, { url } from "constants/routes"
import {data_set} from "utils/evaluationUtils";
import opinFormatter from "utils/opinFormatter";
import CountForSUTQ from "./CountForSUTQ";
import InRatio from "./InRatio";
import {displayDate} from "utils/dateFormatter";
import {setOnbeforeunloadEvent, removeOnbeforeunloadEvent} from "utils/eventUtils";

const { getEmployees, deleteEmployees } = actions

const dateFilter = (filter, row, column) => {
  return row[column.id] && row[column.id].split(',').some((value) => displayDate(value).match(filter.value))
}

const CellWithPopper = ({displayValue}) => (
  <OverlayTrigger
    placement="bottom"
    overlay={
      <Popover id="button-tooltip-2">
        <Popover.Body>
          {displayValue}
        </Popover.Body>
      </Popover>
    }
  >
    <div className="overflow--hidden">{displayValue}</div>
  </OverlayTrigger>
)

const baseColumns = ({isEditable, isLicensing = false, isSUTQ = false, updateFormData, updatedEvaluations}) => [
  {
    Header: 'Name',
    id: 'full_name',
    style: { fontSize: '16px' },
    accessor: (d) => `${d.last_name}, ${d.first_name}`,
    Cell: (row) => {
      const { id, program_id, inspection_id, erc_employee_id } = row.original
      return (
        <Link to={url({program_id, inspection_id, erc_employee_id})(routes.employee)}>
          {row.value}
        </Link>
      )
    },
  },
  {
    Header: 'OPIN',
    style: { fontSize: '16px' },
    accessor: 'opin',
    Cell: (row) => opinFormatter(row.value),
  },
  {
    Header: 'EDU',
    style: { fontSize: '16px' },
    accessor: 'counts_for_edu',
    isSUTQ: true,
    Filter({ filter, onChange }) {
      return (
        <select
          className={"width--full"}
          onChange={(e) => onChange(e.target.value)}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">All</option>
          <option value="true">Counts</option>
          <option value="false">Excluded</option>
        </select>
      )
    },
    filterMethod(filter, row) {
      switch (filter.value) {
        case 'true':
          return Boolean(Number(row.counts_for_edu)) === true
        case 'false':
          return Boolean(Number(row.counts_for_edu)) === false
        default:
          return true
      }
    },
    width: 100,
    Cell: (row) => (
      <CountForSUTQ
        name="counts_for_edu"
        {...{
          erc_id: row.original.inspection_id,
          isEditable,
          erc_employee_id: row.original.erc_employee_id,
          value: row.value,
          updatedEvaluations,
        }}
        onUpdate={(event) => {
          updateFormData({
            erc_employee_id: row.original.erc_employee_id,
            updates: {"sutq.sutq.accounting.counts_for_edu": event.target.checked}
          })
        }}
      />
    ),
  },
  {
    Header: 'Ratio',
    style: { fontSize: '16px' },
    accessor: 'used_in_ratio',
    width: 70,
    Filter({ filter, onChange }) {
      return (
        <select
          onChange={(e) => onChange(e.target.value)}
          value={filter ? filter.value : 'all'}
          className={"width--full"}
        >
          <option value="all">All</option>
          <option value="true">In Ratio</option>
          <option value="false">Excluded</option>
        </select>
      )
    },
    filterMethod(filter, row) {
      switch (filter.value) {
        case 'true':
          return Boolean(Number(row.used_in_ratio)) === true
        case 'false':
          return Boolean(Number(row.used_in_ratio)) === false
        default:
          return true
      }
    },
    Cell: (row) => (
      <InRatio
        name="used_in_ratio"
        {...{
          erc_id: row.original.inspection_id,
          isEditable,
          erc_employee_id: row.original.erc_employee_id,
          value: row.value,
          isLicensing,
          isSUTQ,
          updateFormData,
          updatedEvaluations
        }}
      />
    ),
  },
  {
    Header: 'Date of Hire',
    style: { fontSize: '16px' },
    accessor: 'hire_date',
    Cell: (row) => displayDate(row.value),
    filterMethod: dateFilter,
  },
  {
    Header: 'Start Date',
    style: { fontSize: '16px' },
    accessor: 'start_date',
    Cell: (row) => displayDate(row.value),
    filterMethod: dateFilter,
  },
  {
    Header: 'Role',
    style: { fontSize: '16px' },
    accessor: 'role',
    Cell: (row) => (
      <CellWithPopper
        displayValue={(row.value && row.value.replace(/[,]/g, ', ')) || ''}
      />
    )
  },

  {
    Header: 'Role Date',
    style: { fontSize: '16px' },
    accessor: 'role_start_dates',
    Cell: (row) => (
      <CellWithPopper
        displayValue={
          row.value &&
          row.value
            .split(',')
            .map((date) => displayDate(date))
            .join(', ')
        }
      />
    ),
    filterMethod: dateFilter,
  },
  {
    Header: 'Position',
    style: { fontSize: '16px' },
    accessor: 'position',
    isLicensing: true,
    Cell: (row) => <CellWithPopper displayValue={row.value}/>
  },
  {
    Header: 'Position Date',
    style: { fontSize: '16px' },
    accessor: 'licensing_start_date',
    isLicensing: true,
    Cell: (row) => displayDate(row.value),
    filterMethod: dateFilter,
  },
]

const InspectionEmployeeContainer = (props) => {
  const {
    inspection,
    getEmployees,
    inspection_id,
    program_id,
    putEmployeeEvaluations,
    isEvaluationLoading,
  } = props
  const { isEditable, isLoading, isLicensing, isSUTQ, removeEmployees } = props
  const [query, setQuery] = useState('')
  const [employees, setEmployees] = useState(props.employees)
  const [updatedEvaluations, setUpdatedEvaluations] = useState({})
  const isFormDirty = () => Object.keys(updatedEvaluations).length

  useEffect(() => {
    if(!props.employees) {
      getEmployees(inspection.id)
    }
    return () => {
      removeOnbeforeunloadEvent();
    }
  }, [])

  useEffect(() => {
    if(isEvaluationLoading === false) {
      setUpdatedEvaluations({})
      removeOnbeforeunloadEvent()
    }
  }, [isEvaluationLoading])

  useEffect(() => {
    if(props.employees) {
      setEmployees(
        props.employees.filter((x) =>
          query
            .toLowerCase()
            .replace(',', ' ')
            .split(' ')
            .filter((q) => q)
            .every((q) =>
              Object.values(x).some((value) =>
                String(value)
                  .toLowerCase()
                  .match(q),
              ),
            ),
        ),
      )
    }
  }, [props.employees, query])

  usePrompt({
    message: "Are you sure? You have unsaved changes and they will be discarded.",
    when: isFormDirty
  })

  if (!inspection || !employees) {
    return <LoaderContainer />
  }

  const employeesText = props.isProgramResident ? 'Employees / Residents' : 'Employees'
  const search = (event) => setQuery(event.target.value)
  const columns = baseColumns({isEditable, isLicensing, isSUTQ, updateFormData, updatedEvaluations})

  function updateFormData({erc_employee_id, updates}) {
    let data = updatedEvaluations[erc_employee_id] || {};
    Object.keys(updates).forEach(formKey => {
      data = data_set(formKey, data, updates[formKey]);
    })

    setUpdatedEvaluations(currentUpdates => ({
      ...currentUpdates,
      [erc_employee_id]: data,
    }))
    setOnbeforeunloadEvent()
  }

  function onSave() {
    const data = []
    Object.keys(updatedEvaluations).forEach(key => {
      data.push({
        "erc_id": inspection_id,
        "erc_employee_id": key,
        "evaluation_types": updatedEvaluations[key]
      })
    })
    if(data?.length) {
      putEmployeeEvaluations({ employees: data, erc_id: inspection_id })
    }
  }
  return (
    <Container>
      <SectionHeader>
        <div className="flex__row flex__row--center-center">
          <div style={{ flex: 'auto' }}>{employeesText}</div>
          {isEditable && (
            <Link
              to={url({inspection_id, program_id})(routes.employeesFind)}
              className="btn btn-primary btn-ripple"
            >
              Add {employeesText}
            </Link>
          )}
        </div>
      </SectionHeader>
      <TableControls query={query} onSearch={search} placeholder={`Filter ${employeesText}...`} noPadding />
      {
        isEditable &&
        <div className="flex__row--end-center mbm">
          <button
            disabled={!isFormDirty || isEvaluationLoading}
            className="btn btn-primary btn-ripple"
            onClick={onSave}
          >
            Save
          </button>
        </div>
      }
      <EmployeeTable
        columns={columns}
        isLoading={isLoading || isEvaluationLoading}
        {...{ isEditable, isLicensing, isSUTQ, removeEmployees }}
        employees={employees}
        isProgramResident={props.isProgramResident}
        onSave={onSave}
      />
    </Container>
  )
}

export default connect(
  (state, { inspection, inspection_id,  program_id}) => ({
    isLoading: isLoading(state, 'employees'),
    isEditable: isEditable(state, { id: inspection_id }),
    employees: getResourceBy(state, 'employees', { key: 'inspection_id', value: inspection_id }),
    isLicensing: isLicensing(state, inspection_id),
    isSUTQ: isSUTQ(state, inspection_id),
    isProgramResident: isProgramResident(state, program_id),
    isEvaluationLoading: isLoading(state, 'evaluations')
  }),
  {
    getInspection,
    getEmployees,
    removeEmployees: deleteEmployees,
    putEmployeeEvaluations
  },
)(InspectionEmployeeContainer)
