import React from 'react'
import { connect } from 'react-redux'

import BlockHeader  from 'components/shared/BlockHeader'
import formatOpin  from 'utils/opinFormatter'
import { isProgramResident } from 'stores/programs'

const EmployeeViewHeader = (props) => {
  const { employee = {}, program = {}, isProgramResident } = props
  const { last_name, first_name, opin } = employee
  const { program_name, license_number } = program

  const Header = () => (
    <BlockHeader>
      <div>
        Employee{isProgramResident && ' / Resident'}: {[last_name, first_name].join(', ')} - {formatOpin(opin)}
      </div>
      <div style={{ fontColor: 'darkgray' }}>
        <small>
          {program_name} - {license_number}
        </small>
      </div>
    </BlockHeader>
  )

  return <Header />
}

export default connect((state, props) => ({ isProgramResident: isProgramResident(state, props.program.id) }))(
  EmployeeViewHeader,
)
