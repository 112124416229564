import React, { useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import Select from 'react-select'

import InputContainer from './InputContainer'
import Error from './Error'
import getValue from './getValue'

const isRequired = (message) => (value) => (!!value ? undefined : message)

export const MultiSelectInput = (props) => {
  const {
    errors,
    touched,
    name,
    disabled,
    required = false,
    label,
    values,
    children,
    options = [],
    className,
    setFieldValue,
    setFieldTouched,
    closeMenuOnSelect = true,
    hideLabel = false,
    isMulti = true,
    fieldValueOverride,
    error,
    classNamePrefix
  } = props
  const showError = getValue(errors, name) && getValue(touched, name)

  const value = getValue(values, name)

  useEffect(() => {
    if (value === null) {
      setFieldValue(name, '', false)
    }
  }, [])

  return (
    <InputContainer className={className}>
      {!hideLabel && <label htmlFor={name}>{label}</label>}
      <Field
        classNamePrefix={classNamePrefix} //for className based styling
        id={name}
        name={name}
        component={Select}
        isMulti={isMulti}
        options={options}
        isDisabled={disabled || false}
        required={required}
        value={value}
        styles={{
          multiValueLabel: (provided) => ({
            ...provided,
            maxWidth: '280px'
          }),
          control: (provided, state) => (showError ? { ...provided, borderColor: 'red' } : provided),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        onChange={(value) => {
          if (fieldValueOverride) {
            setFieldValue(name, fieldValueOverride(value))
          } else {
            setFieldValue(name, value)
          }
        }}
        onBlur={() => setFieldTouched(name, true)}
        validate={isRequired(required ? 'This field is required' : undefined)}
        onBlur={props.handleBlur}
        className={showError ? 'error' : ''}
        closeMenuOnSelect={closeMenuOnSelect}
      >
        {children}
      </Field>
      <ErrorMessage name={name}>{(msg) => <Error>{error || msg}</Error>}</ErrorMessage>
    </InputContainer>
  )
}
