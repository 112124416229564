import React from 'react'
import CircleButton  from './CircleButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const AddButton = (props) => (
  <CircleButton {...props} type="button">
    <FontAwesomeIcon icon={faPlus}/>
  </CircleButton>
)

export default AddButton
