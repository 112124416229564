import React from 'react'
import styled from 'styled-components'
import './selected.css'
import TRow from './TableRow'

const ROW_HEIGHT = 56
const COL_WIDTH = '36px'
function getBackground(colors) {
  if (!Array.isArray(colors)) {
    return
  }
  if (colors && colors.length === 1) {
    return colors[0]
  }
  return `repeating-linear-gradient(
          45deg,
          ${colors[0]},
          ${colors
            .map((color, index) => {
              return color + ' 10px'
            })
            .join(',')},
          ${colors[colors.length - 1]} 20px
      )`
}
const HourColumn = ({ numIntervals, data }) => (
  <td
    rowSpan={numIntervals}
    style={{
      maxHeight: `${ROW_HEIGHT / numIntervals}px`,
      fontSize: '12px',
      minWidth: '50px',
      width: '50px',
      textAlign: 'center',
      boxSizing: 'border-box',
      borderTop: `solid 2px gray`,
      borderBottom: `solid 2px gray`,
    }}
  >
    {data.replace(':00', '')}
  </td>
)

const MinuteColumn = ({ intervals, isMajor, isLast, rowIndex }) => (
  <td
    style={{
      maxHeight: `${ROW_HEIGHT / intervals.length}px`,
      height: `${ROW_HEIGHT / intervals.length}px`,
      width: '24px',
      minWidth: '24px',
      fontSize: '9px',
      textAlign: 'center',
      boxSizing: 'border-box',
      borderTop: `solid 2px ${isMajor ? 'grey' : 'lightgrey'}`,
      borderBottom: `solid 2px ${isLast ? 'gray' : 'lightgrey'}`,
    }}
  >
    {intervals[rowIndex % intervals.length]}
  </td>
)

const TimeSlot = styled.div`
  height: ${({ height = 12 }) => height}px;
  max-height: ${({ height = 12 }) => height}px;
  box-sizing: border-box;
  border-top: solid thin ${(props) => (props.doesntCount ? 'darkslategray' : 'gray')};
  border-bottom: solid thin ${(props) => (props.doesntCount ? 'darkslategray' : 'gray')};
  touch-action: none;
  background: ${(props) =>
    props.doesntCount
      ? 'darkgray'
      : props.selected
      ? getBackground(props.colors)
      : props.doesntCount
      ? 'darkgray'
      : '#EFEFEF'};
  ${(props) => {
    const color = props.selected ? props.colors[0] : '#EFEFEF'
    const topBorderColor = props.doesntCount
      ? 'gray'
      : props.selected
      ? props.colors[0]
      : props.isMajor
      ? 'gray'
      : 'lightgray'
    const borderColor = props.doesntCount ? 'gray' : props.selected ? props.colors[0] : 'lightgray'
    const setColor = (property, color) => property + ': ' + color + ' !important;'
    return setColor('border-top-color', topBorderColor).concat(setColor('border-bottom-color', borderColor))
  }};
`

const SelectableTimeSlot = TimeSlot

function ScheduleTableRow({
  activeCells = false,
  data,
  rowKey,
  rowIndex,
  hoursFilter,
  timeSlots,
  handleClick,
  intervals,
  columns = [],
  numColumns = 7,
}) {
  const numIntervals = intervals.length
  const isMajor = rowIndex % numIntervals == 0
  const isLast = rowIndex % numIntervals == numIntervals - 1
  const colWidth = numColumns <= 7 ? '60px' : COL_WIDTH

  const isSelectable = (key) => {
    return (
      rowKey >= hoursFilter.time_start &&
      rowKey < hoursFilter.time_end &&
      (!activeCells || (activeCells && activeCells[key]))
    )
  }

  return (
    <TRow key={rowIndex}>
      {isMajor && <HourColumn {...{ numIntervals, data }} />}
      <MinuteColumn {...{ intervals, isMajor, isLast, rowIndex }} />
      {columns.map((x, colIndex) => {
        const key = `${x.key}-${rowKey}`
        const selectable = isSelectable(key)
        return (
          <td key={key} style={{ width: colWidth, minWidth: colWidth, padding: 0 }}>
            <SelectableTimeSlot
              className={selectable ? 'selectable' : ''}
              key={key}
              id={key}
              doesntCount={!selectable}
              onClick={() => handleClick(key)}
              colors={timeSlots[key]}
              height={ROW_HEIGHT / numIntervals + 2}
              isMajor={isMajor}
              selected={!!timeSlots[key]}
              selectableKey={key}
            ></SelectableTimeSlot>
          </td>
        )
      })}
    </TRow>
  )
}

export default ScheduleTableRow
