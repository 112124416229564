import styled from 'styled-components'

export default styled.div`
  display: flex;
  ${(props) => (props.bgColor ? 'background-color: ' + props.bgColor : '')}
  flex-direction: ${(props) => (props.isVertical ? 'column' : props.left ? 'row' : 'row-reverse')};
  margin-top: ${(props) => (props.top ? props.top : '2em')};
  .btn {
    margin: 0.5em;
    margin-left: ${(props) => (props.isVertical ? '0em' : '0.5em')};
  }
  ${(props) => (props.center ? 'justify-content: center;' : '')};
  ${(props) => (props.verticalCenter ? 'align-self: center;' : '')};
  button,
  a.btn {
    margin: 0.5em;
  }
  button:first-child,
  a.btn:first-child {
    margin-left: 0em;
  }
`
