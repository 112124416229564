import styled from 'styled-components'

export default styled.span`
  font-size: 24px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #1e242b;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  margin: 0 0 0.4em;
  margin-top: 0.5em;
  padding: 0;
  color: #595959;
  position: relative;
  text-transform: none;
  word-wrap: break-word;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`
