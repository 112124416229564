import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const FileUploader = (props) => {
  const { name, required, accept, values } = props
  const className = required ? 'input-required' : ''

  const loadFile = (e) => {
    const name = e.target.id.replace('_fileupload', '')
    const filename = e.target.files[0].name
    props.setFieldValue(name, filename)
  }

  return (
    <div className="input-group">
      <input
        id={name}
        name={name}
        type="text"
        className={`form-control ${className}`}
        placeholder="Select a file..."
        disabled
        value={values && values[name]}
      />
      <input id={`${name}_file_id`} type="hidden" />
      <span className="input-group-btn" style={{ cursor: 'pointer' }}>
        <span className="btn btn-success btn-file custom-file fileinput-button" style={{ cursor: 'pointer' }}>
          <span>Select a File&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faFile} className="mrs"/>
          <FileInput
            id={`${name}_fileupload`}
            name={`${name}_fileupload`}
            type="file"
            accept={accept}
            onChange={loadFile}
            className="custom-file-input"
          />
          <input type="hidden" id={`${name}_id`} name={`${name}_id`} value="0" />
        </span>
      </span>
    </div>
  )
}

export default FileUploader
