import { registerAction } from 'stores/resources'
import apiUrls from "constants/apiUrls"


import evaluations from './reducers'
export * from './actionTypes'
export * from './actions'
export { default as evaluationEpics } from './epics'
export * from './selectors'

export default () => {

  registerAction('getAllEvaluations', (erc_id) => ({
    type: 'GET_ALL_EVALUATIONS',
    url: `${apiUrls.evaluations}/${erc_id}`,
    method: 'get',
  }))

  registerAction('getAllEvaluationsSuccess', (evaluations) => ({
    type: 'GET_ALL_EVALUATIONS_SUCCESS',
    payload: { evaluations },
    toast: 'Evaluations successfully loaded!',
  }))

  return evaluations
}