export const QUALIFICATIONS = 'qualifications'
export const SCHEDULE = 'schedule'
export const TRAINING = 'training'
export const STAFF_REQUIREMENTS = 'staff_requirements'
export const STAFF_WORKSHEET = 'sutq'
export const TYPE_A_HOME = 'Type-a Home'
export const TYPE_B_HOME = 'Type-b Home'
export const JFS_CENTER = 'Jfscenter'
export const TRAINING_OVERVIEW = 'training_overview'
export const ROLE_HISTORY = 'role_history'
export const ODE_CENTER = 'Odecenter'
export const DAY_CAMP = 'Day Camp'
export const COMPLIANCE_CODES = {
  OUT_OF_COMPLIANCE: 'O',
  NOT_VERIFIED: 'NV',
  IN_COMPLIANCE: 'I',
  PREVIOUSLY_VERIFIED: 'PV',
  NOT_APPLICABLE: 'NA',
  OTHER: 'other',
}

export const INVALID_COMPLIANCE_DATE_COMBO_MESSAGE = "This training has an expiration date in the future but is flagged as Out of Compliance. Are you sure this is correct?"
