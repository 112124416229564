import { Set } from 'immutable'

export function getHour(x) {
  return Number(x.split(':')[0])
}
export function getHours(intervals, hoursFilter = {}) {
  const start = hoursFilter.time_start || '00:00:00'
  const end = hoursFilter.time_end || '24:00:00'
  const hourStart = getHour(start)
  const hourEnd = getHour(end)
  const hours = []
  for (let i = hourStart; i < hourEnd; i += 1) {
    const x = i
    const hour = x % 12 == 0 ? 12 : x % 12
    for (let j = 0; j < intervals.length; j += 1) {
      hours.push({
        key: `${String(x).padStart(2, '0')}:${intervals[j]}:00`,
        value: `${hour}:${intervals[j]} ${x / 12 >= 1 ? 'PM' : 'AM'}`,
      })
    }
  }
  return hours
}

export function createIntervals(numIntervals) {
  const interval = 60 / numIntervals
  return Array(numIntervals)
    .fill()
    .map((x, i) => String(interval * i).padStart(2, '0'))
}

const processTimeIntervals = (intervals, start_time = '00:00:00') => (time) => {
  const [hours, minutes, seconds] = time.split(':')

  const row = (hours - 1 - getHour(start_time)) * intervals.length + Math.round((minutes / 60) * intervals.length)
  return row
}

const addIntervalToTime = (time, intervals, x) => {
  const [hours, minutes, seconds] = time.split(':')
  const nextTime = Number(minutes) + x * (60 / intervals.length)
  const nextMinutes = nextTime % 60
  const nextHours = Number(hours) + Math.floor(nextTime / 60)
  return `${String(nextHours).padStart(2, '0')}:${String(nextMinutes).padStart(2, '0')}:00`
}

export function processSchedule(schedule, intervals, columns, start_time) {
  const processTime = processTimeIntervals(intervals, start_time)
  return schedule.reduce((schedule, group) => {
    group.schedule &&
      group.schedule.forEach((timeSlot) => {
        const { time_start, time_end, day_of_week, field } = timeSlot
        const startRow = processTime(time_start, intervals)
        const endRow = processTime(time_end, intervals)
        const columnIndex = columns.findIndex(
          (column) => column.day_of_week == day_of_week && (!field || (field && column.field === field)),
        )
        if (columnIndex <= -1) {
          return
        }
        for (let i = 0; i < endRow - startRow; i++) {
          const columnarIndex = columns[columnIndex].key
          const id = `${columnarIndex}-${addIntervalToTime(time_start, intervals, i)}`
          schedule[id] = (schedule[id] || []).concat(group.color)
        }
      })
    return schedule
  }, {})
}

export function createTimeRange(timeslot, intervals) {
  let [currHour, currMinute] = timeslot.time_start.split(':').map((x) => Number(x))
  const interval = 60 / intervals.length
  let adjustedMinute = Math.floor(currMinute / interval) * interval
  let currTime = `${String(currHour).padStart(2, '0')}:${String(adjustedMinute).padStart(2, '0')}:00`
  let range = []
  while (currTime < timeslot.time_end) {
    range = range.concat(currTime)
    if (adjustedMinute + interval !== 60) {
      adjustedMinute += interval
    } else {
      currHour += 1
      adjustedMinute = (adjustedMinute + interval) % 60
    }
    currTime = `${String(currHour).padStart(2, '0')}:${String(adjustedMinute).padStart(2, '0')}:00`
  }
  return range
}

const timeSlotToTimeIntervals = (intervals) => (timeslot) => {
  const hour = String(Math.floor(timeslot / intervals.length) + 1).padStart(2, '0')
  const minutes = intervals[timeslot % intervals.length]
  return `${hour}:${minutes}:00`
}

export const determineViewSettings = (setHeight, timeSlots, intervals) => {
  const rows = Object.keys(timeSlots)
    .filter((x) => !!timeSlots[x])
    .map((x) => getRowColumn(x).row)
    .sort()

  const interval = intervals.length
  const defaultStart = 7 * interval
  const defaultDuration = 9 * interval
  const rowHeight = 14

  const firstKey = rows[0] ? Number(rows[0].split(':')[0]) * interval : defaultStart
  const lastKey = rows.length
    ? Number(rows[rows.length - 1].split(':')[0]) * interval + 1
    : defaultStart + defaultDuration
  setHeight(`${Math.max(lastKey - firstKey, 9 * interval) * rowHeight}px`)
  const isDefaultScroll = firstKey >= 7 * interval && lastKey <= defaultStart + defaultDuration

  return isDefaultScroll ? defaultStart * rowHeight : firstKey * rowHeight
}

export const getNewEntry = (timeSlots, timeslot, selectedColors) => {
  let entry = Set(timeSlots[timeslot] || undefined)

  Set(selectedColors)
    .toJS()
    .forEach((color) => {
      if (entry.has(color)) {
        entry = entry.remove(color)
      } else {
        entry = entry.add(color)
      }
    })

  if (entry.size === 0) {
    return false
  }
  return entry.toJS()
}

export const getRowColumn = (key) => {
  if (key && typeof key === 'string') {
    const [column, row] = key.split('-')
    return { row, column }
  }
  return {}
}

function timeBFollowsA(timeA, timeB, intervals) {
  return addIntervalToTime(timeA, intervals, 1) === timeB
}

export function convertToTimes(timeSlots = [], intervals) {
  let start = false
  let lastRow = false
  let lastDay = false
  let day = false
  let schedule = []

  const timeSlotToTime = timeSlotToTimeIntervals(intervals)
  timeSlots.sort().forEach((timeslot) => {
    let { row, column } = getRowColumn(timeslot)
    const [day_of_week, columnIndex] = column.split(':')
    const time = row
    if (!start) {
      start = time
      day = day_of_week
      lastDay = day_of_week
      lastRow = time
    } else if (!timeBFollowsA(lastRow, time, intervals) || lastDay !== day_of_week) {
      schedule.push({
        day_of_week: lastDay,
        time_start: start,
        time_end: addIntervalToTime(lastRow, intervals, 1),
      })

      start = time
      lastDay = day_of_week
      lastRow = time
      day = day_of_week
    } else {
      lastRow = time
      lastDay = day_of_week
    }
  })

  if (start) {
    schedule.push({
      day_of_week: lastDay,
      time_start: start,
      time_end: addIntervalToTime(lastRow, intervals, 1),
    })
  }
  return schedule
}
