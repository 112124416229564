import {
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  GET_USER_CREDENTIAL,
  USER_LOGOUT,
} from "./actionTypes";

export const getUserCredential = () => ({ type: GET_USER_CREDENTIAL })
export const userLogin = (action) => ({ type: USER_LOGIN, action, })
export const userLoginSuccess = (user) => ({ type: USER_LOGIN_SUCCESS, payload: { user }})
export const userLoginFailure = (error) => ({ type: USER_LOGIN_FAILURE, payload: { error }})
export const userLogout = () => ({ type: USER_LOGOUT })