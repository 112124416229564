export const CREATE_INSPECTION = 'CREATE_INSPECTION'
export const CREATE_INSPECTION_SUCCESS = 'CREATE_INSPECTION_SUCCESS'
export const CREATE_INSPECTION_FAILURE = 'CREATE_INSPECTION_FAILURE'

export const UPDATE_INSPECTION = 'UPDATE_INSPECTION'
export const UPDATE_INSPECTION_SUCCESS = 'UPDATE_INSPECTION_SUCCESS'
export const UPDATE_INSPECTION_FAILURE = 'UPDATE_INSPECTION_FAILURE'

export const GET_INSPECTION = 'GET_INSPECTION'
export const GET_INSPECTION_SUCCESS = 'GET_INSPECTION_SUCCESS'
export const GET_INSPECTION_FAILURE = 'GET_INSPECTION_FAILURE'

export const GET_INSPECTIONS = 'GET_INSPECTIONS'
export const GET_INSPECTIONS_SUCCESS = 'GET_INSPECTIONS_SUCCESS'
export const GET_INSPECTIONS_FAILURE = 'GET_INSPECTIONS_FAILURE'

export const GET_EVALUATION_TYPES = 'GET_EVALUATION_TYPES'
export const GET_EVALUATION_TYPES_SUCCESS = 'GET_EVALUATION_TYPES_SUCCESS'
export const GET_EVALUATION_TYPES_FAILURE = 'GET_EVALUATION_TYPES_FAILURE'

export const DELETE_INSPECTION = 'DELETE_INSPECTION'
export const DELETE_INSPECTION_SUCCESS = 'DELETE_INSPECTION_SUCCESS'
export const DELETE_INSPECTION_FAILURE = 'DELETE_INSPECTION_FAILURE'

export const COMPLETE_ERC = 'COMPLETE_ERC'
export const COMPLETE_ERC_SUCCESS = 'COMPLETE_ERC_SUCCESS'
export const COMPLETE_ERC_FAILURE = 'COMPLETE_ERC_FAILURE'

export const UNLOCK_ERC = 'UNLOCK_ERC'
export const UNLOCK_ERC_SUCCESS = 'UNLOCK_ERC_SUCCESS'
export const UNLOCK_ERC_FAILURE = 'UNLOCK_ERC_FAILURE'

export const CLONE_ERC = 'CLONE_ERC'
export const CLONE_ERC_SUCCESS = 'CLONE_ERC_SUCCESS'
export const CLONE_ERC_FAILURE = 'CLONE_ERC_FAILURE'
