export const baseEvaluationFields = {
  licensing: {
    qualifications: {
      cpl: {
        assessed_level: '',
        current_level: '',
      },
      development: {
        acl: '',
        acl_expiration: '',
        cda: '',
        cda_expiration: '',
        sae: '',
        sae_expiration: '',
      },
      education: {
        compliance_code: '',
        hs_diploma: '',
        level: '',
      },
    },
    staff_requirements: {
      driver: {
        is_driver: 'no',
      },
      driver_license: {
        compliance_code: '',
        date_expiration: '',
      },
      driver_training: {
        compliance_code: '',
        date_completed: '',
      },
      jfs_01176: {
        compliance_code: '',
        eligibility: '',
        on_file: '',
      },
      medical: {
        compliance_code: '',
        date_completed: '',
      },
    },
    training: {
      can1: {
        compliance_code: '',
        expiration_date: '',
      },
      can63: {
        compliance_code: '',
        expiration_date: '',
      },
      cpr: {
        compliance_code: '',
        expiration_date: '',
      },
      first_aid: {
        compliance_code: '',
        expiration_date: '',
      },
      licensing: {
        compliance_code: '',
        pd: '',
      },
      mcd: {
        compliance_code: '',
        expiration_date: '',
      },
      orientation: {
        compliance_code: '',
        date_completed: '',
      },
    },
  },
}
