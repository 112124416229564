import React, {useEffect, useState} from "react";

export default function (classSelector, stickyClass = "sticky") {
  const [sticky, setSticky] = useState(false)
  useEffect(() => {
    const stickyElement = document.getElementsByClassName(classSelector)?.[0]
    const scrollEvent = () => {
      const tableTop = stickyElement.offsetTop
      if ((window.scrollY + 30) >= tableTop && !sticky) {
        setSticky(true)
      } else if ((window.scrollY + 50) < tableTop && sticky) {
        setSticky(false)
      }
    }
    if(stickyElement) {
      window.addEventListener('scroll', scrollEvent)
    }
    return () => stickyElement && window.removeEventListener('scroll', scrollEvent)
  })
  return {sticky, stickyClass: sticky ? stickyClass : ""}
}