import serviceErrorHandler from "utils/serviceErrorHandler";

import {
  GET_EMPLOYEE_EVALUATIONS_SUCCESS,
  GET_EMPLOYEE_EVALUATIONS_FAILURE,
  PUT_EMPLOYEE_EVALUATIONS,
  PUT_EMPLOYEE_EVALUATIONS_SUCCESS,
  PUT_EMPLOYEE_EVALUATIONS_FAILURE,
} from './actionTypes'

const initialState = {}

const updateEmployeeEvaluation = (state, evaluation) => {
  return {
    ...state,
    isLoading: false,
    [evaluation.erc_employee_id]: {
      ...evaluation,
      evaluations: evaluation.evaluation_types, //.reduce((c, e) => ({ ...c, [e.key]: parseEvaluations(e) }), {}),
    },
  }
}

const updateEvaluations = (state, evals) => {
  // essentially array wrap
  const evaluations = [].concat(evals)

  const newState = { ...state }

  evaluations.map((evaluation) => {
    newState[evaluation.erc_employee_id] = {
      ...evaluation,
      evaluations: evaluation.evaluation_types || {},
    }
  })

  return {
    ...newState,
    isLoading: false,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_EVALUATIONS_SUCCESS':
      return updateEvaluations(state, action.payload.evaluations)
    case GET_EMPLOYEE_EVALUATIONS_SUCCESS:
      return updateEvaluations(state, action.payload.employee_evaluations)
    case GET_EMPLOYEE_EVALUATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case PUT_EMPLOYEE_EVALUATIONS:
      return {
        ...state,
        isLoading: true
      }
    case PUT_EMPLOYEE_EVALUATIONS_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case PUT_EMPLOYEE_EVALUATIONS_SUCCESS:
      return updateEvaluations(state, action.payload.employee_evaluations)
    default:
      if (action.type.match('EMPLOYEE_EVALUATIONS')) {
        if (!action.type.match('FAILURE')) {
          return {
            ...state,
            isLoading: true,
          }
        }
        if (action.error) {
          serviceErrorHandler(action.error);
        }
        return {
          ...state,
          isLoading: false,
        }
      }
      return state
  }
}
