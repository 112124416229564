import React from 'react'
import { connect } from 'react-redux'

import {
  getEmployeeEvaluations,
  putEmployeeEvaluations,
  isLoading,
  getLicensingEvaluation,
} from 'stores/evaluations'

import QualificationsForm from './QualificationsForm'

const mapStateToProps = (state, props) => ({
  evaluation: getLicensingEvaluation(state, props),
  isLoading: isLoading(state),
})
const mapDispatchToProps = { getEmployeeEvaluations, putEmployeeEvaluations }

export default connect(mapStateToProps, mapDispatchToProps)(QualificationsForm)
