import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { actions } from 'stores/resources'
import InspectionHeader from './InspectionHeader'
import routes, {subRoutes, url} from "constants/routes";
import ProgramInformation from "components/ProgramInformation";
import Groups from "components/Groups";
import InspectionEmployeeContainer, { AddEmployeeForm, EmployeeSearch } from "components/Employees";
import { ErcEmployeesOverviewContainer } from "components/ErcEmployeesOverview";
import { LoaderContainer } from "components/shared/Loader";
import CoverageTool from "components/CoverageTool";

const { getEmployees } = actions

function getChildComponent(location) {
  const componentMap = {
    [subRoutes.program_info]: ProgramInformation,
    [subRoutes.groups]: Groups,
    [subRoutes.employees]: InspectionEmployeeContainer,
    [subRoutes.employeesFind]: EmployeeSearch,
    [subRoutes.employeesAdd]: AddEmployeeForm,
    [subRoutes.licensingOverview]: ErcEmployeesOverviewContainer,
    [subRoutes.sutqOverview]: ErcEmployeesOverviewContainer,
    [subRoutes.coverage_tool]: CoverageTool,
  }

  return (
    componentMap[location.hash]
    || componentMap[location.hash?.split("/").slice(0,-1).join("/")]
    || LoaderContainer
  )
}
const InspectionContainer = (props) => {
  const {
    program,
    inspection,
    getEmployees,
  } = props

  useEffect(() => {
    if (inspection) {
      // needed to show the administrator portion
      getEmployees(inspection.id)
    }
  }, [inspection])
  const location = useLocation()
  const isNoViewSelected = !location.hash

  if (isNoViewSelected) {
    return (
      <Navigate
        to={url({program_id: program.id, inspection_id: inspection.id})(routes.programInformation)}
      />
    )
  }

  const ChildComponent = getChildComponent(location)

  return (
    <>
      <InspectionHeader
        program={program}
        inspection={inspection}
      />
      <ChildComponent {...props} />
    </>
  )
}

export default connect(
  null,
  {
    getEmployees,
  },
)(InspectionContainer)
