import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { isEditable } from 'stores/inspections'
import { connect } from 'react-redux'
import Groups  from './Groups'
import { LoaderContainer } from 'components/shared/Loader'
import apiUrls from "constants/apiUrls";
import serviceErrorHandler from "utils/serviceErrorHandler";

const UnconnectedGroupsController = (props) => {
  const { inspection_id: erc_id, isEditable } = props
  const url = apiUrls.groups
  const [groups, setGroups] = useState(false)

  const fetchGroups = () => {
    if (erc_id) {
      axios
        .get(`${url}?erc_id=${erc_id}`)
        .then(({ data }) => setGroups(data))
        .catch(serviceErrorHandler)
    }
  }

  useEffect(() => {
    fetchGroups()
  }, [erc_id])

  const saveGroup = (group) => {
    const method = group.id ? 'put' : 'post'
    return axios[method](url, { group, erc_id })
      .then(fetchGroups)
  }
  const removeGroup = (id) =>
    axios
      .delete(`${url}?id=${id}`)
      .then(fetchGroups)

  if (Boolean(groups)) {
    return <Groups {...{ groups, saveGroup, removeGroup, isEditable }} />
  }
  return <LoaderContainer height="400px" message="Loading Groups..." />
}

export default connect((state, props) => {
  return {
    isEditable: isEditable(state, { id: props.inspection_id }),
  }
})(UnconnectedGroupsController)
