import React from 'react'
import { connect } from 'react-redux'
import { counts_for } from 'stores/evaluations'

const CountForSUTQ = (props) => {
  const {
    name,
    erc_employee_id,
    isEditable,
    onUpdate,
    isChecked,
    updatedEvaluations,
  } = props

  const checked = updatedEvaluations?.[erc_employee_id]?.sutq?.sutq?.accounting?.[name] !== undefined
    ? updatedEvaluations[erc_employee_id].sutq.sutq.accounting[name]
    : isChecked

  const onClick = (event) => {
    onUpdate && onUpdate({target: {checked: Number(!Boolean(Number(checked)))}})
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <form>
        <input
          type="checkbox"
          name={name}
          onChange={onClick}
          disabled={!isEditable}
          checked={Boolean(Number(checked))}
        />
      </form>
    </div>
  )
}

export default connect(
  (state, props) => ({
    isChecked: counts_for(state, props),
  })
)(CountForSUTQ)
