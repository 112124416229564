import React from 'react'

export default () => (
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
)
