import {
  actionTypes,
  createReducer,
  registerActionTypes,
  registerAction,
} from '../resources'
import apiUrls from "constants/apiUrls"

export default () => {

  registerActionTypes('staff_schedule')

  registerAction('getStaffSchedule', (erc_id, erc_employee_id) => ({
    type: actionTypes.GET_STAFF_SCHEDULE,
    payload: { erc_id, erc_employee_id },
    url: apiUrls.staffSchedule,
    method: 'get',
  }))

  registerAction('getStaffScheduleSuccess', (staff_schedule) => {
    return ({
      type: 'GET_STAFF_SCHEDULE_SUCCESS',
      payload: { staff_schedule },
    })
  })

  registerAction('createStaffSchedule', (erc_id, erc_employee_id, is_regular_schedule, schedules) => {
    const groups = schedules
    return {
      type: actionTypes.CREATE_STAFF_SCHEDULE,
      payload: { erc_id, erc_employee_id, is_regular_schedule, groups },
      url: apiUrls.staffSchedule,
      method: 'post',
    }
  })

  registerAction('createStaffScheduleSuccess', (staff_schedule) => {
    return ({
      type: 'CREATE_STAFF_SCHEDULE_SUCCESS',
      payload: { staff_schedule, overwrite: true },
    })
  })

  registerAction('deleteStaffSchedule', (erc_id, ids) => ({
    type: actionTypes.DELETE_STAFF_SCHEDULE,
    payload: { staff_schedule: ids, erc_id },
    url: apiUrls.staffSchedule,
    method: 'delete',
  }))

  registerAction('deleteStaffScheduleSuccess', (staff_schedule) => ({
    type: 'DELETE_STAFF_SCHEDULE_SUCCESS',
    payload: { staff_schedule },
  }))

  return createReducer('staff_schedule')
}
