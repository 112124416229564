import { TYPE_A_HOME, TYPE_B_HOME, JFS_CENTER, ODE_CENTER, DAY_CAMP } from 'constants'

export const getProgramById = (state, id) => state.programs[id]

export const isProgramResident = (state, id) => {
  const program = state.programs[id] || false
  return program && (program.center_type === TYPE_A_HOME || program.center_type === TYPE_B_HOME)
}

export const isProgramTypeA = (state, props) => {
  const { program_id } = props
  const program = state.programs[program_id] || false
  return program && program.center_type === TYPE_A_HOME
}

export const isProgramTypeB = (state, props) => {
  const { program_id } = props
  const program = state.programs[program_id] || false
  return program && program.center_type === TYPE_B_HOME
}

export const isProgramCenter = (state, props) => {
  const { program_id } = props
  const program = state.programs[program_id] || false
  return (program && program.center_type === JFS_CENTER) || program.center_type === ODE_CENTER
}

export const centersWithCoverage = (state, props) => {
  const { program_id } = props
  const program = state.programs[program_id] || false
  return (program && program.center_type === JFS_CENTER) || (program.center_type === ODE_CENTER) || (program.center_type === DAY_CAMP)
}

export const isProgramJfsCenter = (state, props) => {
  const { program_id } = props
  const program = state.programs[program_id] || false
  return program && program.center_type === JFS_CENTER
}
