import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { actions, getResourceBy, isLoading } from 'stores/resources'
import { LoadingContainer } from 'components/shared/Loader'
import { isLicensing } from 'stores/inspections'
import StaffScheduleForm from './StaffScheduleForm'

const { getStaffSchedule, createStaffSchedule, deleteStaffSchedule } = actions

export const StaffScheduleUnconnected = (props) => {
  const {
    schedule,
    schedules,
    inspection_id,
    erc_employee_id,
    getStaffSchedule,
    createStaffSchedule,
    isLicensing,
    isLoading,
  } = props

  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    getStaffSchedule(inspection_id, erc_employee_id)
    setIsFirstLoad(false)
  }, [inspection_id, erc_employee_id])

  if (isFirstLoad || isLoading) {
    return <LoadingContainer />
  }

  return (
    <StaffScheduleForm
      {...props}
      onRemove={(id) => props.deleteStaffSchedule(inspection_id, [id])}
      submitFn={(values, is_regular_schedule) => {
        props.createStaffSchedule(inspection_id, erc_employee_id, is_regular_schedule, values)
      }}
    />
  )
}

export const StaffSchedule = connect(
  (state, props) => {
    const { erc_employee_id } = props
    let schedules = getResourceBy(state, 'staff_schedule', { key: 'erc_employee_id', value: erc_employee_id })
    schedules = schedules
      ? schedules.map((x, index) => ({ ...x, visible: true, selected: index === 0 }))
      : schedules
    return {
      schedules,
      isLoading: isLoading(state, 'staff_schedule'),
      isLicensing: isLicensing(state, props.inspection_id),
    }
  },
  { getStaffSchedule, createStaffSchedule, deleteStaffSchedule },
)(StaffScheduleUnconnected)
