import React from 'react'

import styled from 'styled-components'

export default styled.h2`
  font-size: 2em;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0.4em;
  margin-top: 0.5em;
  padding: 0;
  color: rgb(51, 122, 183);
  position: relative;
  text-align: left;
  word-wrap: break-word;
`
