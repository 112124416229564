import React from "react";
import { connect } from "react-redux";

import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faRightFromBracket, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import {oprUrls} from "constants/apiUrls";
import { userLogout } from "stores/users";

const PageHeader = (props) => (
  <Navbar className="erc-navbar" fixed="top" expand="md">
    <Container>
      <Navbar.Brand href="https://occrra.org">
        <img
          width="90px"
          src="/logo-white-green.png"
          alt="OCCRRA"
          className="img-responsive" id="logo-header"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" >
        <FontAwesomeIcon icon={faBars}/>
      </Navbar.Toggle>
      <Navbar.Collapse className="justify-content-end">
        <Nav className="me-auto">
          <Nav.Link target="_blank" href="https://occrra.org/our-resources-page/">
            RESOURCES
          </Nav.Link>
        </Nav>
        { !!props.user &&
          <Nav>
            <NavDropdown title={ `${props.user.firstName} ${props.user.lastName}`}>
              <NavDropdown.Item href={oprUrls.baseUrl}>
                <FontAwesomeIcon icon={faAnglesLeft}/> RETURN TO REGISTRY
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => props.userLogout()}>
                <FontAwesomeIcon icon={faRightFromBracket}/> SIGN OUT
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        }
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

export default connect(
  (state, props) => ({
    user: state.users?.user
  }),
  {userLogout: userLogout},
)(PageHeader)