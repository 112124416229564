import React, { Fragment } from 'react'
import { useLocation } from "react-router-dom";

import {
  QUALIFICATIONS,
  SCHEDULE,
  TRAINING,
  STAFF_REQUIREMENTS,
  STAFF_WORKSHEET,
  TRAINING_OVERVIEW,
  ROLE_HISTORY,
} from 'constants'

import Measure from 'components/shared/Measure'
import { StickyHeader } from 'components/shared/Layout'
import { LoaderContainer } from 'components/shared/Loader'

import SUTQ from 'components/SUTQ'
import Training from "components/Training"
import RoleHistory from "components/RoleHistory"
import Qualifications from "components/Qualifications"
import { StaffSchedule } from 'components/StaffSchedule'
import StaffRequirements from "components/StaffRequirements"
import EmployeeTrainingOverview from 'components/EmployeeTrainingOverview'

import Tabs from './Tabs'
import EmployeeViewHeader from './EmployeeViewHeader'

function getLastPathElement(location) {
  return location?.hash?.split('/').pop()
}

const getView = (view) => {
  switch (view) {
    case TRAINING:
      return [TRAINING, Training]
    case STAFF_REQUIREMENTS:
      return [STAFF_REQUIREMENTS, StaffRequirements]
    case STAFF_WORKSHEET:
      return [STAFF_WORKSHEET, SUTQ]
    case QUALIFICATIONS:
      return [QUALIFICATIONS, Qualifications]
    case TRAINING_OVERVIEW:
      return [TRAINING_OVERVIEW, EmployeeTrainingOverview]
    case ROLE_HISTORY:
      return [ROLE_HISTORY, RoleHistory]
    default:
      return [SCHEDULE, StaffSchedule]
  }
}

const SCROLL_START = 20
const OCCRRA_HEADER_HEIGHT = 50
const DISPLAY_BREAKPOINT = 540
const EmployeeView = (props) => {
  const {
    employee = {},
    erc_employee_id,
    isLicensing,
    isSUTQ,
    inspection_id,
    inspection,
    program_id,
    program,
    children,
  } = props

  const location = useLocation()
  const lastPath = getLastPathElement(location)

  if (!program || !inspection) {
    return <LoaderContainer />
  }

  const [view, ViewComponent] = getView(lastPath)

  return (
    <div>
      <Measure>
        {({ width, height }) => {
          const isWide = width > DISPLAY_BREAKPOINT
          return (
            <StickyHeader scrollStart={SCROLL_START} top={OCCRRA_HEADER_HEIGHT} height={isWide ? 120 : 92}>
              {({ scrollTop }) => {
                const isScrolled = isWide && scrollTop > SCROLL_START
                return (
                  <Fragment>
                    <div className={isScrolled ? 'container' : ''} style={{ padding: isScrolled ? '0em 2em' : '' }}>
                      <EmployeeViewHeader program={program} employee={employee} width={width} />
                    </div>
                    <div style={{ background: '#015a9b' }}>
                      <div className={isScrolled ? 'container' : ''} style={{ padding: isScrolled ? '0em 1em' : '' }}>
                        <Tabs {...{ erc_employee_id, inspection_id, program_id, view, isLicensing, isSUTQ, width }} />
                      </div>
                    </div>
                  </Fragment>
                )
              }}
            </StickyHeader>
          )
        }}
      </Measure>

      <ViewComponent {...props} />
    </div>
  )
}

export default EmployeeView
