import React, { useState, Fragment } from 'react'
import { LoaderContainer } from 'components/shared/Loader'

import { StickyHeader } from 'components/shared/Layout'
import BlockHeader  from 'components/shared/BlockHeader'
import Measure from 'components/shared/Measure'
import InspectionTabs from './InspectionTabs'

import { Container } from "react-bootstrap"

const WhileLoading = () => <LoaderContainer />

const SCROLL_START = 20
const OCCRRA_HEADER_HEIGHT = 50
const DISPLAY_BREAKPOINT = 540

const InspectionHeader = (props) => {
  const { program, inspection } = props

  if (!program || !inspection) {
    return <WhileLoading />
  }
  const { program_name, license_number } = program

  return (
    <Container>
      <Measure>
        {({ width, height }) => {
          const isWide = width > DISPLAY_BREAKPOINT
          return (
            <StickyHeader scrollStart={SCROLL_START} top={OCCRRA_HEADER_HEIGHT} height={isWide ? 120 : 92}>
              {({ scrollTop }) => {
                const isScrolled = isWide && scrollTop > SCROLL_START
                return (
                  <Fragment>
                    <div className={isScrolled ? 'container' : ''} style={{ padding: isScrolled ? '0em 2em' : '' }}>
                      <BlockHeader>
                        <div>Employee Record Chart: {program_name}</div>
                        <div>
                          <small>{license_number}</small>
                        </div>
                      </BlockHeader>
                    </div>
                    <div style={{ background: '#015a9b' }}>
                      <div className={isScrolled ? 'container' : ''} style={{ padding: isScrolled ? '0em 2em' : '' }}>
                        <InspectionTabs inspection_id={inspection.id} program_id={program.id} />
                      </div>
                    </div>
                  </Fragment>
                )
              }}
            </StickyHeader>
          )
        }}
      </Measure>
    </Container>
  )
}

export default InspectionHeader
