import React from 'react'
import { Field, ErrorMessage } from 'formik'
import InputContainer from './InputContainer'
import Error from './Error'
import getValue from './getValue'
import DatePicker from 'react-datepicker'

const setHours = (date, hours) => {
  date.setHours(hours)
  return date
}

const setMinutes = (date, minutes) => {
  date.setMinutes(minutes)
  return date
}

const setSeconds = (date, seconds) => {
  date.setSeconds(seconds)
  return date
}

const timeToDate = (time) => {
  if (time) {
    let [hours, minutes] = time.split(':')
    return setHours(setMinutes(setSeconds(new Date(), 0), minutes), hours)
  }
  return ''
}

const getTime = (date) => {
  const toString = (value) => String(value).padStart(2, '0')
  return `${toString(date.getHours())}:${toString(date.getMinutes())}:${toString(date.getSeconds())}`
}

const MIN_TIME = '00:00:00'
const MAX_TIME = '23:59:59'

export default (props) => {
  const {
    errors,
    touched,
    name,
    disabled,
    required,
    label,
    values,
    timeIntervals = 30,
    setFieldValue,
    children,
    className,
    minTime = MIN_TIME,
    maxTime = MAX_TIME,
  } = props
  const showError = getValue(errors, name) && getValue(touched, name)

  let value = getValue(values, name)
  if (value) {
    value = timeToDate(value)
  }

  return (
    <InputContainer className={className}>
      <label htmlFor={name}>{label}</label>
      <Field
        id={name}
        name={name}
        component={DatePicker}
        selected={value || ''}
        disabled={disabled || false}
        required={required || false}
        onChange={(value) => {
          setFieldValue(name, value ? getTime(value) : '')
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        timeCaption="Time"
        dateFormat="h:mm aa"
        minTime={timeToDate(minTime || MIN_TIME)}
        maxTime={timeToDate(maxTime || MAX_TIME)}
        onBlur={props.handleBlur}
        style={showError ? { borderColor: 'red' } : {}}
        className={(showError ? 'text-input error' : 'text-input') + ' form-control col-sm-12'}
      />
      <ErrorMessage name={name} component={Error} />
    </InputContainer>
  )
}
