import { USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE, USER_LOGOUT } from "./actionTypes";

const initialState = {
    user: null,
    userLoginError: null,
    userLoginSuccess: false,
    userLogout: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            const { user } = action.payload
            return {
                user,
                userLoginError: null,
                userLoginSuccess: true,
                userLogout: false,
            }
        case USER_LOGIN_FAILURE:
            return {
                user: null,
                userLoginError: action.payload?.error,
                userLoginSuccess: false,
            }
        case USER_LOGOUT:
            return {
                ...initialState,
                userLogout: true,
            }
        default:
            return state
    }
}