import {
  actionTypes,
  registerActionTypes,
  registerAction,
  createReducer,
} from 'stores/resources'
import apiUrls from "constants/apiUrls"
import routes, { url } from 'constants/routes'

const module_key = 'employee';

export default () => {

  registerActionTypes(module_key)

  registerAction('getEmployees', (erc_id) => ({
    type: actionTypes.GET_EMPLOYEES,
    payload: { erc_id },
    url: apiUrls.employees,
    method: 'get',
  }))

  registerAction('getEmployeesSuccess', (employees) => ({
    type: actionTypes.GET_EMPLOYEES_SUCCESS,
    payload: { employees }
  }))

  registerAction('saveEmployees', payload => ({
    type: actionTypes.SAVE_EMPLOYEES,
    payload,
    url: apiUrls.addEmployee,
    method: 'post'
  }))

  registerAction('saveEmployeesSuccess', employees => ({
    type: actionTypes.SAVE_EMPLOYEES_SUCCESS,
    payload: { employees },
  }))

  registerAction('createEmployees', (payload) => ({
    type: actionTypes.CREATE_EMPLOYEES,
    payload,
    url: apiUrls.employees,
    method: 'post',
  }))

  registerAction('createEmployeesSuccess', (employees) => ({
    type: actionTypes.CREATE_EMPLOYEES_SUCCESS,
    payload: { employees },
    toast: 'Employee successfully added!',
    goto: url({
      program_id: employees[0].program_id,
      inspection_id: employees[0].inspection_id
    })(routes.employees),
  }))

  registerAction('deleteEmployees', (employees) => ({
    type: actionTypes.DELETE_EMPLOYEES,
    payload: { ...employees },
    url: apiUrls.employees,
    method: 'delete',
  }))

  registerAction('deleteEmployeesSuccess', (employees) => ({
    type: actionTypes.DELETE_EMPLOYEES_SUCCESS,
    payload: { employees },
  }))

  return createReducer('employees')
}