import React, { useState, useEffect } from 'react'
import { SectionHeader } from 'components/shared/Layout'
import EditGroupForm from "./EditGroupForm"
import GroupTable from "./GroupTable"
import RippleButton  from 'components/shared/Buttons/RippleButton'
import Swal from 'sweetalert2'
import { Toast } from 'components/shared/Modals'
import serviceErrorHandler from "utils/serviceErrorHandler";

import { Container } from "react-bootstrap"

const promiseFn = (value) => (value ? Promise.resolve(false) : Promise.reject())

export default (props) => {
  const { organization, groups, isEditable, teachers, removeGroup = promiseFn, saveGroup = promiseFn } = props
  const [showEditor, setShowEditor] = useState(false)
  const [group, setGroup] = useState({})

  const isCreate = Object.keys(group).length === 0

  const editGroup = (id) => {
    const group = groups.find((x) => x.id === id) || {}
    setGroup(group)
    setShowEditor(true)
  }

  const handleRemoveGroup = async (id) => {
    const group = groups.find((x) => x.id === id) || {}
    const { value } = await Swal.fire({
      title: 'Warning',
      text: `Remove Group ${group.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Remove',
    })
    if (value) {
      removeGroup(id)
        .then(() => Toast.fire({ title: 'Group Removed', icon: 'success' }))
        .catch(serviceErrorHandler)
    }
  }

  const handleSaveGroup = (values) => {
    saveGroup(values)
      .then(() => {
        setShowEditor(false)
        Toast.fire({ title: 'Group Saved', icon: 'success' })
      })
      .catch(serviceErrorHandler)
  }

  return (
    <Container>
      <SectionHeader>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ flex: 'auto' }}>Groups</div>
          {isEditable && (
            <RippleButton
              className="btn btn-primary"
              disabled={showEditor}
              onClick={() => {
                setGroup({})
                setShowEditor(true)
              }}
            >
              Add Group
            </RippleButton>
          )}
        </div>
      </SectionHeader>
      <GroupTable
        data={groups}
        teachers={teachers}
        isEditable={isEditable}
        onEdit={editGroup}
        onRemove={handleRemoveGroup}
      />
      {showEditor && (
        <EditGroupForm
          group={group}
          groups={groups}
          teachers={teachers}
          isCreate={isCreate}
          onSubmit={handleSaveGroup}
          onCancel={() => setShowEditor(false)}
        />
      )}
    </Container>
  )
}
