import React from 'react'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import store from 'stores'
import { unlockERC } from 'stores/inspections'
import ReasonForUnlockModal from './ReasonForUnlockModal'

const ReactSwal = withReactContent(swal)

const Index = (callback = () => {}) => async (event) => {
  const message = 'You are about to unlock this ERC'
  const { id } = event.target.dataset
  let reason = false
  const submitFn = (value) => (reason = value)
  const { value: accept } = await ReactSwal.fire({
    title: message,
    icon: 'info',
    html: <ReasonForUnlockModal submitFn={submitFn} closeFn={ReactSwal.close} />,
    showCancelButton: false,
    showConfirmButton: false,
    confirmButtonText: 'Unlock ERC',
  })
  if (reason) {
    store.dispatch(unlockERC(id, reason))
    callback()
  }
}

export default Index
