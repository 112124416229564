import React, { Fragment, useState, useEffect } from 'react'
import ReactTable from "react-table-6";
import TableControls from 'components/shared/TableControls'
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import useSticky from "hooks/useSticky";

const ProfessionalTrainingList = (props) => {
  const { data, loading } = props
  const [trainings, setTrainings] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    const queryMatch = String(query).toLowerCase()
    setTrainings(
      data.filter((training) =>
        Object.values(training).some((value) =>
          String(value)
            .toLowerCase()
            .match(queryMatch),
        ),
      ),
    )
  }, [data, query])

  const search = (event) => setQuery(event.target.value)

  const tableName = 'professional-training-table'
  const {stickyClass} = useSticky(tableName)
  function onPageChange() {
    const offsetTop = document.getElementsByClassName(tableName)?.[0]?.offsetTop
    window.scrollTo(0,  offsetTop ? (offsetTop - 50) : 0)
  }

  return (
    <Fragment>
      <TableControls query={query} onSearch={search} placeholder={`Filter Trainings...`} noPadding />
      <ReactTable
        loading={loading}
        onPageChange={onPageChange}
        className={`${tableName} ${stickyClass}`}
        data={trainings}
        columns={[
          {
            Header: 'Course Name',
            accessor: 'title',
            style: { 'whiteSpace': 'unset' }
          },
          {
            Header: 'Date Completed',
            accessor: 'date_completed',
            Cell: (row) => {
              return <div>{moment(row.original.date_completed).format('MM/DD/YYYY')}</div>
            },
          },
          {
            Header: 'OA',
            accessor: 'is_oa',
            Cell: (row) => (
              <FontAwesomeIcon icon={Boolean(Number(row.original.is_oa)) ? faCheckSquare : faSquare } />
            )
          },
          {
            Header: 'College Coursework',
            accessor: 'is_college_coursework',
            Cell: (row) => (
              <FontAwesomeIcon icon={Boolean(Number(row.original.is_college_coursework)) ? faCheckSquare : faSquare } />
            )
          },
          {
            Header: 'PD Topics',
            accessor: 'pd_topics',
            Cell: (row) => {
              const pdTopics = JSON.parse((row.original.pd_topics || null))
              if(!!pdTopics?.length) {
                return (
                  <div>
                    {
                      pdTopics.map(pdTopic => <div style={{whiteSpace: "break-spaces"}}>{pdTopic}<br></br></div>)
                    }
                  </div>
                )
              }
            }
          },
          {
            Header: 'Hours',
            accessor: 'hours',
          },
          {
            Header: 'Instructor',
            accessor: 'instructor',
          },
        ]}
        defaultSorted={[
          {
            id: 'title',
            desc: false,
          },
        ]}
      />
    </Fragment>
  )
}

export default ProfessionalTrainingList
