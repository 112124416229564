import React from 'react'

const SelectionBox = (props) => {
  const { from, to } = props
  let x = from.x
  let y = from.y
  let width = to.x - from.x
  let height = to.y - from.y

  if (width < 0) {
  }

  if (to.x < from.x) {
    x = to.x
    width = from.x - to.x
  }

  if (to.y < from.y) {
    y = to.y
    height = from.y - to.y
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: y,
        left: x,
        width: `${width}px`,
        height: `${height}px`,
        border: '3px dotted darkgray',
      }}
    ></div>
  )
}

export default SelectionBox
