import React from "react";
import { Button, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

export default () => (
  <footer id="footer">
    <Container className="footer flex__row">
      <div className="flex--15 flex__row flex__row--start-center">
        &copy; OCCRRA {new Date().getUTCFullYear()}.{' '}
        {/*<a href="/privacy-policy" className="hide">*/}
        {/*  Privacy Policy*/}
        {/*</a>*/}
      </div>
      <div className="flex--70 flex__row flex__row--center-center">
        <div className="flex--nogrow">2469 Stelzer Road, Columbus, OH 43219</div>
        <div className="flex--nogrow">Phone: 614-396-5959</div>
        <div className="flex--nogrow">Toll Free: 877-547-6978</div>
        <div className="flex--nogrow">Email: support@occrra.org</div>
      </div>
      <div className="flex--15" >
        <Button
          size="sm"
          variant={"outline-light"}
          onClick={()=>{window.scrollTo({top:0})}}
        >
          <FontAwesomeIcon icon={faAngleUp} />
        </Button>
      </div>
    </Container>
  </footer>
)