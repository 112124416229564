import React from 'react'

import { withFormik, Form } from 'formik'
import { SelectButtonInput, FormContainer, ButtonContainer, RippleButton } from 'components/shared/Forms'
import { Row, NiceHr } from 'components/shared/Layout'
import * as Yup from 'yup'

const ReasonForUnlockForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    course,
    cancel,
    submit,
    actionLabel,
    /* and other goodies */
  } = props
  return (
    <FormContainer>
      <Form>
        <Row>
          <SelectButtonInput
            name="reason_for_unlock"
            label="Provide a reason for unlock"
            {...props}
            options={[
              { key: 'Request for Review', value: 'Request for Review' },
              { key: 'Change', value: 'Change' },
              { key: 'Correction', value: 'Correction' },
            ]}
          />
        </Row>
      </Form>
      <NiceHr />
      <ButtonContainer>
        <RippleButton
          type="submit"
          disabled={!isValid}
          className={`btn ${isValid ? 'btn-success' : 'btn-warning'}`}
          onClick={handleSubmit}
        >
          Unlock
        </RippleButton>
        <RippleButton type="button" className="btn btn-default" onClick={props.closeFn}>
          Cancel
        </RippleButton>
      </ButtonContainer>
    </FormContainer>
  )
}

const formikEnhancer = withFormik({
  handleSubmit: (values, { setSubmitting, props }) => {
    if (typeof props.submitFn === 'function') {
      props.submitFn(values.reason_for_unlock)
    }
    props.closeFn(values.reason_for_unlock)
    setSubmitting(false)
    return values.reason_for_unlock
  },
  mapPropsToValues: (props) => {
    return {
      reason_for_unlock: false,
    }
  },
  validationSchema: Yup.object().shape({
    reason_for_unlock: Yup.string().required('Required'),
  }),
  displayName: 'ReasonForUnlockForm',
})

const EnhancedForm = formikEnhancer(ReasonForUnlockForm)

const ReasonForUnlockModal = (props) => <EnhancedForm {...props} />

export default ReasonForUnlockModal
