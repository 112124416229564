import React, {useState, useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ErcTable from './ErcTable'
import AddInspectionModal from 'components/Inspections/AddInspectionModal'
import ReasonForUnlockModal  from 'components/ReasonForUnlockModal'
import { deleteInspection, cloneERC, completeERC, unlockERC } from 'stores/inspections'
import ToggleView from 'components/shared/ToggleView'
import TableControls from 'components/shared/TableControls'

import apiUrls from "constants/apiUrls"
import serviceErrorHandler from "utils/serviceErrorHandler";

const ReactSwal = withReactContent(swal)

function trimQuery(query) {
  return (query || '')
    .replace(/[\,\.]/g, ' ')
    .split(' ')
    .map((x) => x.trim())
    .filter((x) => x)
    .join(' ')
}
let debouncer

const Index = (props) => {
  const { deleteInspection, completeERC, unlockERC, cloneERC, program } = props

  const [employeeRecordCharts, setEmployeeRecordCharts] = useState([])
  const [isSupervisor, setIsSupervisor] = useState(null)
  const [isODJFSManager, setIsODJFSManager] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [pages, setPages] = useState(1)
  const [query, setQuery] = useState('')
  const [showMine, setShowMine] = useState(true)
  const [manualRefresh, setManualRefresh] = useState()

  function getInspections(state = {}, instance) {
    setIsLoading(true)
    const { pageSize = 10, page = 0, sorted = [{ id: 'date_updated', desc: true }] } = state
    const params = {
      pageSize,
      page,
      sorted,
      showMine,
      query: trimQuery(query),
    }
    if (program && program.id) {
      params.program_id = program.id
    }
    axios
      .get(apiUrls.inspections, { params })
      .then(({ data }) => {
        setEmployeeRecordCharts(data.data)
        setPages(data.pages)
        setIsSupervisor(data.isSupervisor)
        setIsODJFSManager(data.isODJFSManager)
      })
      .catch(serviceErrorHandler)
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (debouncer) {
      clearTimeout(debouncer)
    }
    debouncer = setTimeout(() => {
      if(manualRefresh) {
        getInspections()
        setManualRefresh(false)
      }
    }, 300)
    return () => clearTimeout(debouncer)
  }, [manualRefresh])

  useEffect(() => {
    setManualRefresh(true)
  }, [showMine, query])

  const search = (event) => setQuery(event.target.value)

  const handleRemoveInspection = async (event) => {
    const { id, status } = event.target.dataset
    const message = `Delete ${status} ERC?`
    const { value: accept } = await swal.fire({
      title: message,
      icon: 'error',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Remove ERC',
      customClass: {
        confirmButton: 'btn btn-danger mam',
        cancelButton: 'btn btn-default mam',
      },
      buttonsStyling: false,
      reverseButtons: true,
    })
    if (accept) {
      setTimeout(() => setManualRefresh(true), 500)
      return deleteInspection(id)
    }
  }

  const completeSingleEvaluationType = async (erc) => {
    const { id, evaluation_types } = erc
    const { value: accept } = await swal.fire({
      title: 'Complete this ERC',
      icon: 'info',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Complete ERC',
      reverseButtons: true,
    })
    if (accept) {
      setTimeout(() => setManualRefresh(true), 500)
      return completeERC(id, evaluation_types)
    }
  }

  const trimmer = (string) => string.split(',').map((x) => x.trim())

  const completeMultiEvaluationTypes = async (erc) => {
    const { id, evaluation_types, evaluation_abbrs } = erc
    let result = false
    let submitFn = (value) => (result = value)
    const { value: accept } = await ReactSwal.fire({
      title: 'Complete this ERC',
      icon: 'info',
      html: (
        <div>
          <AddInspectionModal
            submitFn={submitFn}
            evaluation_types={trimmer(evaluation_abbrs)
              .sort((a, b) => (a < b ? -1 : 1))
              .join(', ')}
            closeFn={ReactSwal.close}
            actionLabel={'Select ERC to complete'}
          />
        </div>
      ),
      showCancelButton: false,
      showConfirmButton: false,
    })

    if (result) {
      const index = evaluation_abbrs
        .split(',')
        .map((x) => x.trim())
        .findIndex((x) => x == result)
      const evaluation_type = evaluation_types.split(',').map((x) => x.trim())[index]
      setTimeout(() => setManualRefresh(true), 500)
      return completeERC(id, index === -1 ? evaluation_types : evaluation_type)
    }
  }

  const handleCompleteERC = async (event) => {
    const { id } = event.target.dataset
    const erc = employeeRecordCharts.find((x) => x.id === id)
    const { evaluation_types } = erc

    if (evaluation_types && evaluation_types.split(',').length > 1) {
      completeMultiEvaluationTypes(erc)
    } else {
      completeSingleEvaluationType(erc)
    }
  }

  const handleCloneERC = async (event) => {
    const message = 'Clone this ERC'
    const { id } = event.target.dataset
    const erc = employeeRecordCharts.find((x) => x.id === id)
    const evaluation_abbrs = erc.evaluation_abbrs
    const evaluation_types = erc.evaluation_types

    if (evaluation_abbrs && evaluation_abbrs.split(',').map((x) => x.trim()).length > 1) {
      let result = false
      let submitFn = (value) => (result = value)
      const { value: accept } = await ReactSwal.fire({
        title: message,
        icon: 'info',
        html: (
          <div>
            <AddInspectionModal
              submitFn={submitFn}
              evaluation_type={evaluation_abbrs}
              closeFn={ReactSwal.close}
              actionLabel={'Select ERC to clone'}
            />
          </div>
        ),
        showCancelButton: false,
        showConfirmButton: false,
      })
      if (result) {
        const index = evaluation_abbrs
          .split(',')
          .map((x) => x.trim())
          .findIndex((x) => x == result)
        const evaluation_type = evaluation_types.split(',').map((x) => x.trim())[index]
        setTimeout(() => setManualRefresh(true), 500)
        return cloneERC(id, result === evaluation_abbrs ? evaluation_types : evaluation_type)
      }
    } else {
      const { value: accept } = await swal.fire({
        title: 'Clone this ERC',
        icon: 'info',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Clone ERC',
        reverseButtons: true,
      })
      if (accept) {
        setTimeout(() => setManualRefresh(true), 500)
        return cloneERC(id, evaluation_types)
      }
    }
  }

  const handleUnlockERC = ReasonForUnlockModal(() => setTimeout(() => setManualRefresh(true), 500))

  return (
    <Fragment>
      <ToggleView value={showMine} setValue={setShowMine} />

      <TableControls query={query} onSearch={search} placeholder="Filter Employee Record Charts..." noPadding />

      <ErcTable
        ercs={employeeRecordCharts}
        pages={pages}
        onFetchData={state => (manualRefresh === false && getInspections(state))}
        isLoading={isLoading}
        onComplete={handleCompleteERC}
        onDelete={handleRemoveInspection}
        onClone={handleCloneERC}
        onUnlock={handleUnlockERC}
        isSupervisor={isSupervisor}
        isODJFSManager={isODJFSManager}
      />
    </Fragment>
  )
}

export default connect(null, { deleteInspection, cloneERC, completeERC, unlockERC })(Index)
