import React from "react"
import { connect } from 'react-redux'
import { getResourceBy } from 'stores/resources'
import { isProgramCenter, isProgramTypeA, isProgramTypeB } from 'stores/programs'
import {updateInspection, isEditable, isSUTQ, isLicensing, isLoading} from 'stores/inspections'

import { LoaderContainer } from "components/shared/Loader"
import ProgramInformationForm from './ProgramInformationForm'

import goToLocation  from 'utils/goToLocation'
import routes from "constants/routes"

const ProgramInformation = (props) => {
  const { inspection, employees } = props

  if (inspection && employees) {
    return <ProgramInformationForm {...props} />
  }
  return <LoaderContainer />
}


export default connect((state, props) => {
  const { inspection } = props
  return {
    isProgramTypeA: isProgramTypeA(state, props),
    isProgramTypeB: isProgramTypeB(state, props),
    isProgramCenter: isProgramCenter(state, props),
    isSUTQ: isSUTQ(state, inspection.id),
    isLicensing: isLicensing(state, inspection.id),
    isEditable: isEditable(state, { id: inspection.id }),
    contributors: getResourceBy(state, 'contributors', inspection.id),
    employees: getResourceBy(state, 'employees', { key: 'inspection_id', value: inspection.id }),
    cancel: () => goToLocation(routes.base),
    isLoading: isLoading(state)
  }
}, {
  submit: updateInspection,
})(ProgramInformation)
