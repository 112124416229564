import React from 'react'

import { Field, ErrorMessage } from 'formik'
import DatePicker from 'react-datepicker'
import InputContainer from './InputContainer'
import Error from './Error'
import moment from 'moment'
import getValue from './getValue'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.scss'

import swal from "sweetalert2";

const DateInput = (props) => {
  const {
    id,
    name,
    label,
    bottom,
    className,
    inputClassName = '',
    errors,
    touched,
    required,
    disabled,
    maxDate,
    minDate,
    setFieldValue,
    handleChange = () => {},
    handleBlur = () => {},
    values,
    expired = false,
    hideLabel = false,
    shouldConfirmChange,
    confirmText="",
  } = props
  const showError = expired || (getValue(errors, name) && getValue(touched, name))

  let date = false
  let value = getValue(values, name)

  if (value === '0000-00-00' || !value) {
    value = ''
  } else {
    date = moment(value)
    value = moment(value).toDate()
  }

  const confirmChange = async (newDate) => {
    const result = await swal.fire({
      title: "Are You Sure?",
      text: confirmText,
      confirmButtonText: "I am sure",
      denyButtonText: "Cancel change",
      showDenyButton: true,
    })
    if(result.isConfirmed) {
      handleChange(newDate)
      setFieldValue(name, newDate || '')
    }
  }

  const handleDateSelect = (v) => {
    const newDate = (v && moment(v).format('YYYY-MM-DD')) || ''
    if(shouldConfirmChange && shouldConfirmChange(newDate)) {
      confirmChange(newDate)
    } else {
      handleChange(newDate)
      setFieldValue(name, newDate || '')
    }
    handleBlur(newDate)
  }

  const onBlur = ({ target: { value = '' } }) => {
    const dateValue = new Date(value);
    const dateParts = value.split('/')

    if (
      dateParts.length !== 3
      || dateParts[2].length < 2
      || dateParts[2].length > 4
    ) {
      handleChange('')
      setFieldValue(name, '')
      handleBlur('');
    } else {
      if (dateValue > maxDate || dateValue < minDate) {
        handleChange('')
        setFieldValue(name, '')
        handleBlur('');
      } else {
        handleDateSelect(value)
      }
    }
  };

  return (
    <InputContainer className={className}>
      {!hideLabel && (
        <label htmlFor={name}>
          <div className="flex__row">
            <span className="flex--auto">{label}</span>
            {!bottom && <span>{date && date.isValid() ? date.format('MMMM Do, YYYY') : ''}</span>}
          </div>
        </label>
      )}

      <Field
        id={id || name}
        name={name}
        component={DatePicker}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        required={required}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={onBlur}
        onSelect={handleDateSelect}
        selected={value}
        disabled={disabled}
        className={`${(showError ? 'text-input error' : 'text-input') + ' validate-date form-control col-sm-12'} ${inputClassName}`}
      />
      <ErrorMessage name={name} component={Error} />
      {bottom && (
        <span style={{ color: 'darkgray', fontSize: '0.9em' }}>
          {date && date.isValid() ? date.format('MMMM Do, YYYY') : ''}
        </span>
      )}
    </InputContainer>
  )
}

export default DateInput
