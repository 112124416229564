import React from 'react'
import styled from 'styled-components'
import SectionHeader from './SectionHeader'
const PaperContainer = styled.div`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 0em;
  margin-bottom: 2em;
`
const PaperContent = styled.div`
  padding: 1em;
`

export const Paper = ({ header, children }) => (
  <PaperContainer className="container-fluid">
    {header}
    {children && <PaperContent>{children}</PaperContent>}
  </PaperContainer>
)

export const PaperHeader = styled(SectionHeader)`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#173c56')};
  color: white;
  padding: 0.5em 1em;
  margin-top: 0em;
  margin-bottom: 0em;
  position: relative;
  height: ${({ height }) => (height ? height : '54px')};
  display: flex;
  justify-content: center;
`
