import React, { useState } from 'react'
import { SelectInput, MultiSelectInput, Input } from 'components/shared/Forms'
import getValue from 'components/shared/Forms/getValue'

import { COMPLIANCE_CODES, TYPE_A_HOME, TYPE_B_HOME, JFS_CENTER, ODE_CENTER } from 'constants'
import swal from "sweetalert2";
const { OUT_OF_COMPLIANCE, NOT_VERIFIED, NOT_APPLICABLE, IN_COMPLIANCE, PREVIOUSLY_VERIFIED, OTHER } = COMPLIANCE_CODES

export const JFS_TB_MEDICAL = [
  'TB Required - Not Completed',
  'Positive TB - No Additional Test',
  'Physically Present - Active/Latent and No Documentation',
]

export const REASONS = {
  'staff_requirements.medical': [
    'No Medical',
    'Medical Not Within 1 Year',
    'Date of Exam Missing',
    'Signature Missing',
    'Doctor Info Missing',
    'Doctor Does Not Meet',
    'Immunization Info Missing',
    'Required Statement Missing',
    'Requested Exam Needed',
    'Falsified',
  ],
  'staff_requirements.jfs_01176': [
    'Not Requested',
    'No JFS 01176',
    'No JFS 01176 – Sole Responsibility',
    'No Preliminary Approval – Near Children',
    'Not Updated',
    'Employee Refused',
    'Not Eligible – Still Employed',
    'Other – Requirement Not Met',
    'Falsified',
  ],
  'staff_requirements.driver_training': ['No Driver Training', 'Training Expired', 'Falsified'],
  'staff_requirements.driver_license': ['License Not on File', 'License Expired', 'Non-Valid License', 'Falsified'],
  'training.can1': [ 'Expired', 'No Training', 'Falsified' ],
  'training.can63': [
    'Expired',
    'No Training',
    'Trainer Qualifications',
    'Form Not Complete',
    'Form Does Not Meet Req.',
    'No Original Course',
    'Falsified',
  ],
  'training.cpr': [
    'Expired',
    'No Training',
    'Trainer Qualifications',
    'Form Not Complete',
    'Form Does Not Meet Req.',
    'No Original Course',
    'Falsified',
  ],
  'training.first_aid': [
    'Expired',
    'No Training',
    'Trainer Qualifications',
    'Form Not Complete',
    'Form Does Not Meet Req.',
    'No Original Course',
    'Falsified',
  ],
  'training.licensing': ['Insufficient PD Hours', 'PD Not Acceptable ', 'Falsified'],
  'training.mcd': [
    'Expired',
    'No Training',
    'Trainer Qualifications',
    'Form Not Complete',
    'Form Does Not Meet Req.',
    'No Original Course',
    'Falsified',
  ],
  'training.orientation': ['No Orientation', 'No Orientation – Sole Responsibility', 'Falsified'],

  'qualifications.education': [
    'No Educational Verification',
    'Not High School Grad',
    'HS Needs Translated Equivalency',
    'Home School Documentation Missing',
    'Missing Refugee Documentation',
    'Vocational Information Missing',
    'College Credit Information Missing',
    'Falsified',
  ],
}

export const ComplianceField = (props) => {
  const {
    stem,
    values,
    handleChange,
    setFieldValue,
    centerType,
    shouldConfirmChange,
    confirmText = ""
  } = props
  const [prevValue, setPrevValue] = useState(false)
  const reasons = (REASONS[stem] || []).map((x, i) => ({ value: x, label: x }))
  const compliance_code = getValue(values, `${stem}.compliance_status`)

  if (centerType === JFS_CENTER && stem === 'staff_requirements.medical') {
    reasons.push(...JFS_TB_MEDICAL.map((x) => ({ value: x, label: x })))
  }

  const confirmChange = async (value) => {
    const result = await swal.fire({
      title: "Are You Sure?",
      text: confirmText,
      confirmButtonText: "I am sure",
      denyButtonText: "Cancel change",
      showDenyButton: true,
    })
    if(result.isConfirmed) {
      complianceChangeHandler(value)
    } else {
      complianceChangeHandler(prevValue)
    }
  }

  const onChange = (event) => {
    const value = event.target.value
    if(shouldConfirmChange && shouldConfirmChange(value)) {
      confirmChange(value)
    } else {
      complianceChangeHandler(value)
    }
  }

  const complianceChangeHandler = (value) => {
    if (prevValue !== value) {
      setFieldValue(`${stem}.compliance_reasons`, [])
    }
    setPrevValue(value)

    if (value !== OTHER) {
      setFieldValue(`${stem}.compliance_status_text`, '')
    }
    handleChange(value)
    setFieldValue(`${stem}.compliance_status`, value)
  }

  return (
    <React.Fragment>
      <SelectInput
        {...props}
        label="Compliance Status"
        name={`${stem}.compliance_status`}
        onChange={onChange}
      >
        <option value=""> - </option>
        <option value={IN_COMPLIANCE}>I - In Compliance</option>
        <option value={OUT_OF_COMPLIANCE}>O - Out of Compliance</option>
        <option value={NOT_APPLICABLE}>NA - Not Applicable</option>
        <option value={PREVIOUSLY_VERIFIED}>PV - Previously Verified</option>
        <option value={NOT_VERIFIED}>NV - Not Verified</option>
      </SelectInput>
      {compliance_code === OUT_OF_COMPLIANCE && (
        <MultiSelectInput
          options={reasons}
          {...props}
          label="Compliance Reasons"
          name={`${stem}.compliance_reasons`}
          closeMenuOnSelect={false}
        />
      )}
    </React.Fragment>
  )
}
