import React from 'react'
import styled from 'styled-components'
import Loader from './Loader'

const LoadingContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height || '100vh'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoaderContainer = ({ height, message }) => (
  <LoadingContainer height={height}>
    <Loader />
    <div>{message}</div>
  </LoadingContainer>
)

export default LoaderContainer
