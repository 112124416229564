import { ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { map, mergeMap, catchError, concatMap } from 'rxjs/operators'
import axios from 'axios'
import { getErrorMessage } from "utils/serviceErrorHandler";

import {
  getInspectionSuccess,
  getInspectionsSuccess,
  cloneERCSuccess,
  createInspectionSuccess,
  updateInspectionSuccess,
  deleteInspectionSuccess,
  unlockERCSuccess,
  completeERCSuccess,
  getEvaluationTypesSuccess,
} from './actions'
import {
  GET_INSPECTION,
  GET_INSPECTIONS,
  GET_EVALUATION_TYPES,
  CLONE_ERC,
  CLONE_ERC_FAILURE,
  CREATE_INSPECTION,
  UPDATE_INSPECTION,
  DELETE_INSPECTION,
  DELETE_INSPECTION_FAILURE,
  COMPLETE_ERC,
  COMPLETE_ERC_FAILURE,
  UNLOCK_ERC,
  UNLOCK_ERC_FAILURE,
} from './actionTypes'
import apiUrls from 'constants/apiUrls'

const mapper = (fn) => ({ data }) => fn(data)
const errorHandler = (actionType) => (error) => {
  return of({
    type: actionType,
    payload: getErrorMessage(error),
    error: error,
  })
}

export default (action$) =>
  merge(
    action$.pipe(
      ofType(GET_INSPECTION),
      mergeMap((action) =>
        from(axios.get(apiUrls.inspections, { params: action.payload })).pipe(
          map(mapper(getInspectionSuccess)),
          catchError(errorHandler(`${action.type}_FAILURE`)),
        ),
      ),
    ),
    action$.pipe(
      ofType(GET_INSPECTIONS),
      mergeMap((action) =>
        from(axios.get(apiUrls.inspections, { params: action.payload })).pipe(
          map(({ data }) => getInspectionsSuccess(data.data)),
          catchError(errorHandler(`${action.type}_FAILURE`)),
        ),
      ),
    ),
    action$.pipe(
      ofType(GET_EVALUATION_TYPES),
      mergeMap((action) =>
        from(axios.get(apiUrls.evaluationTypes, { params: action.payload })).pipe(
          map(({ data }) => getEvaluationTypesSuccess(data)),
          catchError(errorHandler(`${action.type}_FAILURE`)),
        ),
      ),
    ),
    action$.pipe(
      ofType(CREATE_INSPECTION),
      mergeMap((action) =>
        from(axios.post(apiUrls.inspections, action.payload)).pipe(
          map(({ data }) => createInspectionSuccess(data)),
          catchError(errorHandler(`${action.type}_FAILURE`)),
        ),
      ),
    ),
    action$.pipe(
      ofType(UPDATE_INSPECTION),
      mergeMap((action) =>
        from(axios.put(apiUrls.inspections, action.payload)).pipe(
          map(({ data }) => updateInspectionSuccess(data.inspection)),
          catchError(errorHandler(`${action.type}_FAILURE`)),
        ),
      ),
    ),
    action$.pipe(
      ofType(DELETE_INSPECTION),
      mergeMap((action) =>
        from(axios.delete(`${apiUrls.inspections}/${action.payload.id}`)).pipe(
          concatMap(({ data }) => {
            const deleted = data.filter((x) => Number(x.is_deleted))
            const updated = data.filter((x) => !Number(x.is_deleted))
            if (updated.length) {
              return of(deleteInspectionSuccess(deleted[0].id), updateInspectionSuccess(updated[0]))
            } else {
              return of(deleteInspectionSuccess(deleted[0].id))
            }
          }),
          catchError(errorHandler(DELETE_INSPECTION_FAILURE)),
        ),
      ),
    ),
    action$.pipe(
      ofType(COMPLETE_ERC),
      mergeMap((action) =>
        from(axios.post(apiUrls.finalizeErc, action.payload)).pipe(
          map(({ data }) => completeERCSuccess(data)),
          catchError(errorHandler(COMPLETE_ERC_FAILURE)),
        ),
      ),
    ),
    action$.pipe(
      ofType(UNLOCK_ERC),
      mergeMap((action) =>
        from(axios.post(apiUrls.unlockErc, action.payload)).pipe(
          map(({ data }) => unlockERCSuccess(data)),
          catchError(errorHandler(UNLOCK_ERC_FAILURE)),
        ),
      ),
    ),
    action$.pipe(
      ofType(CLONE_ERC),
      mergeMap((action) =>
        from(axios.post(apiUrls.cloneErc, action.payload)).pipe(
          map(({ data }) => cloneERCSuccess([data])),
          catchError(errorHandler(CLONE_ERC_FAILURE)),
        ),
      ),
    ),
  )
