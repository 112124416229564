import React, {useEffect, useState} from 'react';
import axios from 'axios'
import ReactTable from "react-table-6";

import apiUrls from "constants/apiUrls";
import { SectionHeader } from "components/shared/Layout";
import useSticky from "hooks/useSticky";

export default (props) => {
  const { erc_employee_id } = props
  const [ roles, setRoles ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if(erc_employee_id) {
      setLoading(true)
      axios.get(`${apiUrls.roleHistory}?erc_employee_id=${erc_employee_id}`)
        .then(
          res => {
            if(res?.data) {
              setRoles(res.data)
            }
            setLoading(false)
          },
          err => console.log("err", err) && setLoading(false)
        )
        .catch(error => console.log("error", error) && setLoading(false))
    }
  }, [erc_employee_id])

  const tableName = 'role-history-table'
  const {stickyClass} = useSticky(tableName)

  function onPageChange() {
    const offsetTop = document.getElementsByClassName(tableName)?.[0]?.offsetTop
    window.scrollTo(0,  offsetTop ? (offsetTop - 50) : 0)
  }

  return (
    <div>
      <SectionHeader>Role History</SectionHeader>

      <ReactTable
        className={`${tableName} ${stickyClass}`}
        onPageChange={onPageChange}
        data={roles}
        loading={loading}
        columns={[
          {
            Header: 'Role Name',
            accessor: 'role_job_title',
            style: { 'whiteSpace': 'unset' }
          },
          {
            Header: 'Employer',
            accessor: '',
            Cell: (row) => props.program?.program_name || "",
          },
          {
            Header: 'Start Date',
            accessor: 'role_start_date',
          },
          {
            Header: 'End Date',
            accessor: 'role_end_date',
          },
          {
            Header: 'Status',
            accessor: 'role_status',
          },
        ]}
        defaultSorted={[
          {
            id: 'role_start_date',
            desc: false,
          },
        ]}
      />
    </div>
  )
}

