import { compose, createStore, applyMiddleware, combineReducers } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

import programs, { programEpics } from "./programs"
import users, { usersEpics } from "./users"
import inspections, { inspectionEpics } from "./inspections"
import { resourceEpics } from "./resources"
import registerEvaluations, {evaluationEpics} from "./evaluations"
import transition from "./transition"
import registerEmployees from "./employees"
import registerContributors from "./contributors"
import registerStaffSchedule from "./staffSchedule"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const epicMiddleware = createEpicMiddleware()
const rootEpic = combineEpics(
  usersEpics,
  programEpics,
  inspectionEpics,
  resourceEpics,
  evaluationEpics,
)
const rootReducer = combineReducers({
  users,
  programs,
  inspections,
  transition,
  employees: registerEmployees(),
  evaluations: registerEvaluations(),
  contributors: registerContributors(),
  staff_schedule: registerStaffSchedule(),
})

function configureStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)))

  epicMiddleware.run(rootEpic)

  return store
}

const store = configureStore()

export default store
