import React from 'react'

import styled from 'styled-components'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'

const ViewToggleContainer = styled.div`
`

const ToggleView = (props) => {
  const { value, setValue } = props
  return (
    <ViewToggleContainer id="cy-toggle-container">
      <InputGroup size="lg" className="mtm mbs">
        <InputGroup.Text>Viewing: </InputGroup.Text>
        <Button
          className={"use-default-style"}
          variant={!!value ? "success" : "outline-success"}
          onClick={()=>setValue(true)}
        >
          Mine
        </Button>
        <Button
          className={"use-default-style"}
          variant={!value ? "success" : "outline-success"}
          onClick={()=>setValue(false)}
        >
          All
        </Button>
      </InputGroup>
    </ViewToggleContainer>
  )
}

export default ToggleView
