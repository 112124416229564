export const opinFormatter = (string = '') => {
  if (!string) {
    return ''
  }

  const SEPERATOR = '-'

  if (string.includes(SEPERATOR)) {
    return string
  }

  return [string.slice(0, 4), SEPERATOR, string.slice(4)].join('')
}

export default opinFormatter