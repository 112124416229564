import React, { Fragment, useState, useRef } from 'react'
import { RippleButton } from './'
import {Overlay, Tooltip} from "react-bootstrap";

export const TooltipRippleButton = (props) => {
  const { tooltip, children,  placement = 'bottom' } = props
  const [visible, setVisible] = useState(false)
  const [id] = useState(Date.now())

  const tooltipId = `${id}_tooltip`
  const buttonId = `${id}_button`
  const target = useRef(null);

  return (
    <Fragment>
      <Overlay target={target.current} show={visible} placement={placement}>
        {(props) => (
          <Tooltip id={tooltipId} {...props}>
            {tooltip}
          </Tooltip>
        )}
      </Overlay>
      <RippleButton
        ref={target}
        {...props}
        id={buttonId}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {children}
      </RippleButton>
    </Fragment>
  )
}
