import React, { useState } from 'react'

import routes from 'constants/routes'
import {
  QUALIFICATIONS,
  SCHEDULE,
  TRAINING,
  STAFF_REQUIREMENTS,
  STAFF_WORKSHEET,
  TRAINING_OVERVIEW,
  ROLE_HISTORY,
} from 'constants'
import { MenuCollapse } from 'components/shared/MenuCollapse'
import { TabNavRouter } from 'components/shared/TabNavRouter'

const tabList = [
  {
    key: SCHEDULE,
    name: 'Active Roles & Schedules',
    url: routes.employeeStaffSchedule,
    isLicensing: true,
    isSUTQ: true,
  },
  {
    key: QUALIFICATIONS,
    name: 'Qualifications',
    url: routes.employeeQualifications,
    isLicensing: true,
    isSUTQ: false,
  },
  {
    key: TRAINING,
    name: 'Training & PD',
    url: routes.employeeTraining,
    isLicensing: true,
    isSUTQ: false,
  },
  {
    key: STAFF_REQUIREMENTS,
    name: 'Staff Requirements',
    url: routes.employeeStaffRequirements,
    isLicensing: true,
    isSUTQ: false,
  },
  {
    key: STAFF_WORKSHEET,
    name: 'SUTQ ERC',
    url: routes.employeeStaffWorksheet,
    isLicensing: false,
    isSUTQ: true,
  },
  {
    key: TRAINING_OVERVIEW,
    name: 'Training Overview',
    url: routes.trainingOverview,
    isLicensing: true,
    isSUTQ: true,
  },
  {
    key: ROLE_HISTORY,
    name: "Role History",
    url: routes.roleHistory,
    isLicensing: true,
    isSUTQ: true,
  },
]

const EmployeeTabs = (props) => {
  const { inspection_id, program_id, erc_employee_id, isLicensing, isSUTQ, view, width } = props
  const [isOpen, setIsOpen] = useState(false)
  const getUrl = (url) =>
    url
      .replace(':inspection_id', inspection_id)
      .replace(':program_id', program_id)
      .replace(':erc_employee_id', erc_employee_id)

  const isEmployeeView = !!['employees', 'find', 'add'].find((x) => x === view)

  const tabs = tabList
    .filter((tab) => (tab.isLicensing && isLicensing) || (tab.isSUTQ && isSUTQ))
    .map((x) => ({ ...x, url: getUrl(x.url) }))
  if (width > 1000) {
    return <TabNavRouter tabs={tabs} active={view} />
  } else {
    return <MenuCollapse items={tabs} active={view} />
  }
}

export default EmployeeTabs
