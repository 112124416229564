import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useLocation, unstable_usePrompt as usePrompt } from 'react-router-dom'
import { Formik } from 'formik'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Map } from 'immutable'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { isProgramJfsCenter } from 'stores/programs/selectors'
import { isProgramResident } from 'stores/programs'
import { getInspection, isSUTQ, isLicensing, isEditable } from 'stores/inspections'
import { actions, getResourceBy, isLoading } from 'stores/resources'
import EducationTypes from 'constants/EducationTypes'
import routes, { url } from 'constants/routes'

import { putEmployeeEvaluations } from 'stores/evaluations'
import { ButtonContainer, RippleButton, MultiSelectInput } from 'components/shared/Forms'
import TrainingOverview from 'components/ErcEmployeesOverview/TrainingOverview'
import { LoaderContainer } from 'components/shared/Loader'
import { TabNavRouter } from 'components/shared/TabNavRouter'
import CountForSUTQ from 'components/Employees/CountForSUTQ'
import { REASONS, JFS_TB_MEDICAL } from 'components/shared/Forms/ComplianceField'
import { cdaOptions } from 'constants/cda'
import { baseEvaluationFields } from './baseEvaluationFields'
import { ComplianceCode } from './ComplianceCode'
import { COMPLIANCE_CODES, INVALID_COMPLIANCE_DATE_COMBO_MESSAGE } from 'constants'
import {formatCDA, data_get, data_set, getFormKey} from "utils/evaluationUtils";
import {setOnbeforeunloadEvent, removeOnbeforeunloadEvent} from "utils/eventUtils";

const { OUT_OF_COMPLIANCE } = COMPLIANCE_CODES
const ReactSwal = withReactContent(swal)
const merge = require('deepmerge')


const invalidComplianceDateComboAlert = {
  title: "Are You Sure?",
  text: INVALID_COMPLIANCE_DATE_COMBO_MESSAGE,
  confirmButtonText: "I am sure",
  denyButtonText: "Cancel change",
  showDenyButton: true,
}

const { getAllEvaluations, getEmployees, deleteEmployees } = actions

const ErcEmployeesOverview = (props) => {
  const {
    evaluations,
    employees,
    program_id,
    inspection,
    isLoading,
    isSUTQ,
    isEditable,
    isLicensing,
    isProgramResident,
    inspection_id,
  } = props
  const dispatch = props

  const isJfsCenter = useSelector((state) => isProgramJfsCenter(state, { program_id }))
  const [currentTab, setCurrentTab] = useState()
  const [updatedEvaluations, setUpdatedEvaluations] = useState({})
  const location = useLocation()
  const field = location.hash?.replace("#/", "").split("/")[1]
  const licensingMatch = location.hash?.includes('licensing-overview')
  const [allCompliant, setAllCompliant] = useState({})

  useEffect(() => {
    if(!employees) {
      dispatch.getEmployees(inspection.id)
    }
    return () => {
      removeOnbeforeunloadEvent();
    }
  }, [])
  useEffect(() => {
    if(field && !currentTab) { // when page first load
      dispatch.getAllEvaluations(inspection.id)
    }
    if(field !== currentTab) { // when navigation to a new tab
      if(currentTab && isFormDirty()) {
        onSave()
      }
      setCurrentTab(field)
    }
  }, [field])

  useEffect(() => {
    if(evaluations.isLoading === false) {
      setUpdatedEvaluations({})
      removeOnbeforeunloadEvent()
    }
  }, [evaluations])

  const handleDelete = (payload) => {
    dispatch.deleteEmployees(payload)
  }

  usePrompt({
    message: "Are you sure? You have unsaved changes and they will be discarded.",
    when: isFormDirty
  })

  if (!employees) {
    return <LoaderContainer />
  }

  const baseColumns = [
    {
      Header: `Employee ${isProgramResident ? '/ Resident' : ''} Name`,
      id: 'full_name',
      style: { fontSize: 14 },
      headerStyle: { fontSize: 14 },
      accessor: (d) => `${d.last_name}, ${d.first_name}`,
      Cell: (row) => {
        const { erc_employee_id, program_id, inspection_id } = row.original
        return (
          <Link to={url({program_id, inspection_id, erc_employee_id})(routes.employee)}>{row.value}</Link>
        )
      },
    },
  ]

  const ConfirmReason = (key, complianceStatus, reasons, onSubmit, onCancel) => ReactSwal.fire({
    type: 'warning',
    html: (
      <div>
        <Formik
          initialValues={Map()
            .setIn(`${key}.compliance_status`.split('.'), complianceStatus)
            .toJS()}
          onSubmit={async (values, actions) => {
            onSubmit(values)
            actions.resetForm()
            ReactSwal.close()
          }}
        >
          {(props) => (
            <React.Fragment>
              <MultiSelectInput
                closeMenuOnSelect={false}
                {...props}
                options={reasons}
                label="Compliance Reasons"
                name={key + '.compliance_reasons'}
                required={true}
              />

              <ButtonContainer>
                <RippleButton
                  type="submit"
                  className="btn btn-success"
                  disabled={props.isSubmitting}
                  onClick={props.handleSubmit}
                >
                  {props.isSubmitting ? 'Saving...' : 'Confirm Reason'}
                </RippleButton>
                <RippleButton
                  onClick={() => {
                    onCancel && onCancel()
                    ReactSwal.close()
                  }}
                  type="button"
                  className={`btn btn-default`}
                >
                  Cancel
                </RippleButton>
              </ButtonContainer>
            </React.Fragment>
          )}
        </Formik>
      </div>
    ),
    showCancelButton: false,
    showConfirmButton: false,
  })

  const getEvaluationFields = (erc_employee_id) => {
    const evaluation = {
      qualifications: {
        ...baseEvaluationFields.licensing.qualifications,
      },
      staff_requirements: {
        ...baseEvaluationFields.licensing.staff_requirements,
      },
      training: {
        ...baseEvaluationFields.licensing.training,
      },
    }

    if (evaluations[erc_employee_id] && evaluations[erc_employee_id].evaluations) {
      const employee_eval = evaluations[erc_employee_id].evaluations
      const { licensing = {}, sutq = {} } = employee_eval
      if(licensing.qualifications?.development?.cda) {
        licensing.qualifications.development.cda = formatCDA(licensing.qualifications.development.cda)
      }
      if(sutq.sutq?.education?.cda) {
        sutq.sutq.education.cda = formatCDA(sutq.sutq.education.cda)
      }

      return merge(merge(evaluation, licensing), sutq)
    }

    return evaluation
  }

  const booleanFieldLookUp = {
    "eval.qualifications.education.hs_diploma": ['No', 'Yes'],
    "eval.staff_requirements.driver.is_driver": ['no', 'yes'],
    "eval.sutq.accounting.counts_for_edu": ['0', '1']
  }

  function isFormDirty() {
    return Object.keys(updatedEvaluations).length
  }

  function updateFormData({row, event, isBoolean = false /* for checkbox fields */}) {
    const { erc_employee_id } = row.original
    const { value, checked, evaluation_types } = event.target

    const { id: key } = row.column
    const formKey = getFormKey(key)
    const val = (isBoolean && booleanFieldLookUp[key])
      ? booleanFieldLookUp[key][!!checked + 0]
      : value;

    // if the full employee data is passed in, directly use that
    // otherwise use the form key and value to set
    let data = evaluation_types || data_set(
      formKey,
      updatedEvaluations[erc_employee_id],
      val
    );

    setUpdatedEvaluations(currentUpdates => ({
      ...currentUpdates,
      [erc_employee_id]: data,
    }))
    setOnbeforeunloadEvent()
  }

  async function onSave() {
    const data = []
    Object.keys(updatedEvaluations).forEach(key => {
      data.push({
        "erc_id": inspection_id,
        "erc_employee_id": key,
        "evaluation_types": updatedEvaluations[key]
      })
    })
    await dispatch.putEmployeeEvaluations({ employees: data, erc_id: inspection_id })
  }

  function buildOnChangeHandler(row, isBoolean) {
    return event => updateFormData({row, isBoolean, event})
  }

  const ComplianceHeader = (label) => (config) => {
    const key = config.column.id
    const formKey = getFormKey(key)
    const disabled = !config.data.find(row => !row[key]?.compliance_status)
    return (
      <div>
        {label}
        <div onClick={(event) => event.stopPropagation()}>
          <label className={"mts mls"}>
            <input
              type={"checkbox"}
              className={"mrs"}
              disabled={allCompliant[formKey] === undefined ? disabled : allCompliant[formKey]}
              checked={allCompliant[formKey] === undefined ? disabled : allCompliant[formKey]}
              onChange={(event)=>{
                const checked = event.target.checked
                if(checked) {
                  let data = {...updatedEvaluations}
                  config.data.forEach(row => {
                    const {erc_employee_id} = row._original
                    let newEmployeeData = data[erc_employee_id]
                    const savedValue = row[key]?.compliance_status
                    const currentValue = data_get(formKey + ".compliance_status", newEmployeeData)
                    if(!savedValue && !currentValue) {
                      newEmployeeData = data_set(formKey + ".compliance_status", newEmployeeData, COMPLIANCE_CODES.IN_COMPLIANCE)
                      newEmployeeData = data_set(formKey + ".compliance_reasons", newEmployeeData, [])
                      data[erc_employee_id] = newEmployeeData
                    }
                  })
                  setUpdatedEvaluations(data)
                  setAllCompliant({
                    ...allCompliant,
                    [formKey]: true
                  })
                }
              }}
            />
            Set All Compliant
          </label>
        </div>
      </div>
    )
  }

  const shouldConfirmDateChange = (date, field, original) => {
    const employeeId = original.erc_employee_id
    const updatedValue = data_get(getFormKey(field) + ".compliance_status", updatedEvaluations[employeeId])
    const originalValue = data_get(field + ".compliance_status", original)
    const complianceValue = updatedValue !== undefined ? updatedValue : originalValue
    const isFutureDate = moment(date) > moment()
    return complianceValue === COMPLIANCE_CODES.OUT_OF_COMPLIANCE && isFutureDate
  }

  const DateField = (roar = { value: undefined, data: '', shouldConfirmChange: false }) => (dataEntry) => {
    const { value: truethy, data = '', shouldConfirmChange } = roar
    const { id: key } = dataEntry.column
    const { erc_employee_id } = dataEntry.original
    const { original = {}, viewIndex, value } = dataEntry
    const updatedValue = data_get(getFormKey(key), updatedEvaluations[erc_employee_id])
    const dateValue = updatedValue === undefined ? value : updatedValue

    let display = true
    if(data) {
      const dataDefault = data_get(data, original)
      const dataNow = data_get(getFormKey(data), updatedEvaluations[erc_employee_id])
      display = (dataNow === undefined ? dataDefault : dataNow) == truethy
    }

    const submitCallback = buildOnChangeHandler(dataEntry, true)
    const date = (!dateValue || dateValue === '0000-00-00') ? '' : moment(dateValue).toDate()

    const onSelect = async (updated) => {
      if(
        shouldConfirmChange
        && shouldConfirmChange(
          updated,
          String(key).replace(".expiration_date", ""),
          dataEntry.original
        )
      ) {
        const result = await swal.fire(invalidComplianceDateComboAlert)
        if(!result.isConfirmed) {
          return;
        }
      }
      submitCallback({ // fake an event object
        target: {
          value: updated ? moment(updated).format('YYYY-MM-DD') : ''
        }
      })
    }

    const onBlur = (event) => {
      const value = event.target.value
      const dateValue = new Date(value);
      const dateParts = value.split('/');
      if (
        dateParts.length === 3
        && dateParts[0].length < 3
        && dateParts[1].length < 3
        && (dateParts[2].length === 4 || dateParts[2].length === 2)
      ) {
        onSelect(dateValue)
      } else {
        submitCallback({
          target: {
            value: ""
          }
        })
      }
    }

    return (
      <div style={{ width: 100 }}>
        {display && (
          <DatePicker
            id={`${viewIndex}.${key}`}
            label="Expiration Date"
            name={`${viewIndex}.${key}`}
            selected={date}
            onSelect={onSelect}
            onBlur={onBlur}
            showMonthDropdown
            dropdownMode="select"
            className="compact text-input validate-date form-control col-sm-12"
            disabled={!isEditable}
            showYearDropdown
          />
        )}
      </div>
    )
  }

  const InputField = (inputProps = {}) => (val) => {
    const { viewIndex } = val
    const {id: key} = val.column
    const { erc_employee_id } = val.original
    const onChange = buildOnChangeHandler(val)
    const defaultValue = data_get(key, val.original)
    const value = data_get(getFormKey(key), updatedEvaluations[erc_employee_id])

    return (
      <div className="flex__col">
        {
          !!inputProps.label &&
          <label htmlFor={`${viewIndex}.${key}`}>{inputProps.label}</label>
        }
        <input
          id={`${viewIndex}.${key}`}
          className="overflow--hidden whitespace--nowrap text--ellipsis"
          style={{
            width: "100%",
          }}
          disabled={!isEditable}
          defaultValue={value === undefined ? defaultValue : value}
          onBlur={onChange}
          {...inputProps}
        />
      </div>
    )
  }

  const CheckboxField = () => (val) => {
    const { viewIndex } = val
    const {id: key} = val.column
    const base = key.replace('eval.', '')
    const onChange = buildOnChangeHandler(val, true)
    const defaultChecked = data_get(key, val.original)
    const newChecked = data_get(getFormKey(key), updatedEvaluations[val.original.erc_employee_id])
    // use a soft equal here so '0' == 0 will be true
    const checked = (newChecked === undefined ? defaultChecked : newChecked) == booleanFieldLookUp[key][1]
    return (
      <div style={{ width: 100 }}>
        <input
          id={`${viewIndex}.${key}`}
          name={base}
          type="checkbox"
          disabled={!isEditable}
          onChange={onChange}
          checked={checked}
        />
      </div>
    )
  }

  const SelectField = (options) => (val) => {
    const { viewIndex } = val
    const { id: key } = val.column
    const defaultValue = data_get(key, val.original)
    const value = data_get(getFormKey(key), updatedEvaluations[val.original.erc_employee_id])
    const onChange = buildOnChangeHandler(val)

    return (
      <div>
        <select
          id={ `${viewIndex}.${key}` }
          key={ `${viewIndex}.${key}` }
          disabled={!isEditable}
          value={value === undefined ? defaultValue : value}
          className={"overflow--hidden  whitespace--nowrap text--ellipsis width--full"}
          onChange={onChange}
        >
          {options}
        </select>
      </div>
    )
  }

  const shouldConfirmComplianceChange = (complianceValue, field, original) => {
    const employeeId = original.erc_employee_id
    const updatedValue = data_get(getFormKey(field) + ".expiration_date", updatedEvaluations[employeeId])
    const originalValue = data_get(field + ".expiration_date", original)
    const date = updatedValue !== undefined ? updatedValue : originalValue
    const isFutureDate = moment(date) > moment()
    return complianceValue === COMPLIANCE_CODES.OUT_OF_COMPLIANCE && isFutureDate
  }

  const ComplianceCell = (roar = { value: undefined, data: '', shouldConfirmChange: false }) => (val) => {
    const { value: truethy, data = '', shouldConfirmChange = false } = roar
    const { id: key } = val.column
    const { erc_employee_id } = val.original
    const base = key.replace('eval.', '')

    const { compliance_status, compliance_reasons } = val.value
    const formKey = getFormKey(key)
    const complianceStatusKey = formKey + ".compliance_status"
    const complianceReasonsKey = formKey + ".compliance_reasons"
    const updatedComplianceStatus = data_get(complianceStatusKey, updatedEvaluations[erc_employee_id])
    const updatedComplianceReasons = data_get(complianceReasonsKey, updatedEvaluations[erc_employee_id])
    const complianceStatus = updatedComplianceStatus === undefined ? compliance_status : updatedComplianceStatus
    const complianceReasons = updatedComplianceReasons === undefined ? compliance_reasons : updatedComplianceReasons
    const attachTB = isJfsCenter && (base === 'staff_requirements.medical')
    const reasons = REASONS[base].concat(attachTB ? JFS_TB_MEDICAL : []) || ['Expired', 'Falsified']
    const onChange = buildOnChangeHandler(val)
    const onReasonsChanged = buildOnChangeHandler(val)

    let display = true
    if(data) {
      const dataDefault = data_get(data, val.original)
      const dataNow = data_get(getFormKey(data), updatedEvaluations[erc_employee_id])
      display = (dataNow === undefined ? dataDefault : dataNow) == truethy
    }

    return (
      <div className="flex__row flex--wrap">
        {display && (
          <React.Fragment>
            <ComplianceCode
              value={complianceStatus}
              isEditable={isEditable}
              defaultValue={complianceStatus}
              name={key}
              key={key}
              onChange={async (e) => {
                const complianceStatus = e.target.value
                if (!isEditable) {
                  return ''
                }

                if(
                  shouldConfirmChange
                  && shouldConfirmChange(
                    complianceStatus,
                    String(key).replace(".compliance_status", ""),
                    val.original
                  )
                ) {
                  const result = await swal.fire(invalidComplianceDateComboAlert)
                  if(!result.isConfirmed) {
                    return;
                  }
                }

                const onSubmit = (reasons_val) => {
                  let data = {...updatedEvaluations[erc_employee_id]}
                  data = data_set(complianceReasonsKey, data, data_get(complianceReasonsKey, reasons_val))
                  data = data_set(complianceStatusKey, data, data_get(complianceStatusKey, reasons_val))
                  onReasonsChanged({target: {evaluation_types: data}})
                  setAllCompliant({
                    ...allCompliant,
                    [formKey]: false
                  })
                }

                if (complianceStatus == OUT_OF_COMPLIANCE) {
                  return ConfirmReason(
                    formKey,
                    complianceStatus,
                    reasons.map((x, i) => ({ value: x, label: x })),
                    onSubmit,
                  )
                }

                let updated_evaluation_types = {...updatedEvaluations[erc_employee_id]}
                updated_evaluation_types = data_set(
                  complianceStatusKey,
                  updated_evaluation_types,
                  complianceStatus,
                )

                if (![OUT_OF_COMPLIANCE].includes(complianceStatus)) {
                  updated_evaluation_types = data_set(
                    complianceReasonsKey,
                    updated_evaluation_types,
                    null,
                  )
                }
                if(!complianceStatus) {
                  setAllCompliant({
                    ...allCompliant,
                    [formKey]: false
                  })
                }
                onChange({target: {evaluation_types: updated_evaluation_types}})
              }}
            />
            {
              Boolean(
                [OUT_OF_COMPLIANCE].includes(complianceStatus)
                && complianceReasons
                && complianceReasons.length
              ) &&
              complianceReasons.map((d, i) => (
                <span
                  key={i}
                  className="display--inline-block pas mbs mls"
                  style={{
                    fontSize: 12,
                    background: 'pink',
                    borderRadius: 3,
                  }}
                >
                  {d.label}
                </span>
              ))}
          </React.Fragment>
        )}
      </div>
    )
  }

  const bgcEligibility = (key) => {
    const options = {
      pending: 'Pending',
      eligible: 'Eligible',
      ineligible: 'Ineligible',
      preliminary: 'Preliminary',
      none: 'None',
    }
    return options[key]
  }

  const dynamic = {
    qualifications: [
      {
        Header: 'High School Diploma',
        accessor: 'eval.qualifications.education.hs_diploma',
        Cell: CheckboxField(),
      },
      {
        Header: ComplianceHeader('Education Qualifications Compliance'),
        accessor: 'eval.qualifications.education',
        Cell: ComplianceCell(),
      },
      {
        Header: 'Highest Education',
        accessor: 'eval.qualifications.education.level',
        Cell: SelectField(<EducationTypes onlyDegrees={true} />),
      },
      {
        Header: 'CDA',
        accessor: 'eval.qualifications.development.cda',
        Cell: SelectField(
          cdaOptions.map((option) => (
            <option value={option.key} key={option.key}>{option.value}</option>
          ))
        ),
      },
    ],
    ['staff-requirements']: [
      {
        Header: 'Medical',
        accessor: 'eval.staff_requirements.medical.date_completed',
        Cell: DateField(),
      },
      {
        Header: ComplianceHeader('Medical Compliance'),
        accessor: 'eval.staff_requirements.medical',
        Cell: ComplianceCell(),
      },
      {
        Header: 'BG Check Eligibility',
        accessor: 'eval.staff_requirements.jfs_01176.eligibility',
        Cell: (row) =>
          ['pending', 'eligible', 'ineligible', 'preliminary'].includes(row.value) ? bgcEligibility(row.value) : 'None',
      },
      {
        Header: 'BG Check Date Exp',
        accessor: 'eval.staff_requirements.jfs_01176.date_expired',
      },
      {
        Header: ComplianceHeader('BG Check Compliance'),
        accessor: 'eval.staff_requirements.jfs_01176',
        Cell: ComplianceCell(),
      },
      {
        Header: 'Driver?',
        accessor: 'eval.staff_requirements.driver.is_driver',
        Cell: CheckboxField(),
      },
      {
        Header: 'Driver Training Completed',
        accessor: 'eval.staff_requirements.driver_training.date_completed',
        Cell: DateField({
          data: 'eval.staff_requirements.driver.is_driver',
          value: 'yes',
        }),
      },
      {
        Header: ComplianceHeader('Driver Training Compliance'),
        accessor: 'eval.staff_requirements.driver_training',
        Cell: ComplianceCell({
          data: 'eval.staff_requirements.driver.is_driver',
          value: 'yes',
        }),
      },
      {
        Header: 'Driver License Exp Date',
        accessor: 'eval.staff_requirements.driver_license.date_expiration',
        Cell: DateField({
          data: 'eval.staff_requirements.driver.is_driver',
          value: 'yes',
        }),
      },
      {
        Header: ComplianceHeader('Driver License Compliance'),
        accessor: 'eval.staff_requirements.driver_license',
        Cell: ComplianceCell({
          data: 'eval.staff_requirements.driver.is_driver',
          value: 'yes',
        }),
      },
    ],
    ['training-and-pd']: [
      {
        Header: 'Staff Orientation',
        accessor: 'eval.training.orientation.date_completed',
        Cell: DateField(),
      },
      {
        Header: ComplianceHeader('Staff Orientation Compliance'),
        accessor: 'eval.training.orientation',
        Cell: ComplianceCell(),
      },
      {
        Header: 'Licensing PD',
        accessor: 'eval.training.licensing.pd',
        Cell: InputField({ type: 'number', step: "any", label: 'Hours', min: '0' }),
      },
      {
        Header: ComplianceHeader('License PD Compliance'),
        accessor: 'eval.training.licensing',
        Cell: ComplianceCell(),
      },
    ],
    ['health-and-safety-training']: [
      {
        Header: 'First Aid Exp Date',
        accessor: 'eval.training.first_aid.expiration_date',
        Cell: DateField({
          shouldConfirmChange: shouldConfirmDateChange
        }),
      },
      {
        Header: ComplianceHeader('First Aid License Compliance'),
        accessor: 'eval.training.first_aid',
        Cell: ComplianceCell({
          shouldConfirmChange: shouldConfirmComplianceChange
        }),
      },
      {
        Header: 'CPR Exp Date',
        accessor: 'eval.training.cpr.expiration_date',
        Cell: DateField({
          shouldConfirmChange: shouldConfirmDateChange
        }),
      },
      {
        Header: ComplianceHeader('CPR License Compliance'),
        accessor: 'eval.training.cpr',
        Cell: ComplianceCell({
          shouldConfirmChange: shouldConfirmComplianceChange
        }),
      },
      {
        Header: 'MCD Exp Date',
        accessor: 'eval.training.mcd.expiration_date',
        Cell: DateField({
          shouldConfirmChange: shouldConfirmDateChange
        }),
      },
      {
        Header: ComplianceHeader('MCD License Compliance'),
        accessor: 'eval.training.mcd',
        Cell: ComplianceCell({
          shouldConfirmChange: shouldConfirmComplianceChange
        }),
      },
      {
        Header: 'CA/N 6/3 Exp Date',
        accessor: 'eval.training.can63.expiration_date',
        Cell: DateField({
          shouldConfirmChange: shouldConfirmDateChange
        }),
      },
      {
        Header: ComplianceHeader('CA/N 6/3 License Compliance'),
        accessor: 'eval.training.can63',
        Cell: ComplianceCell({
          shouldConfirmChange: shouldConfirmComplianceChange
        }),
      },
      {
        Header: 'CA 1 Hour Overview Exp Date',
        accessor: 'eval.training.can1.expiration_date',
        Cell: DateField({
          shouldConfirmChange: shouldConfirmDateChange
        }),
      },
      {
        Header: ComplianceHeader('CA 1 Hour License Compliance'),
        accessor: 'eval.training.can1',
        Cell: ComplianceCell({
          shouldConfirmChange: shouldConfirmComplianceChange
        }),
      },
    ],
    education: [
      {
        Header: 'Role',
        accessor: 'role',
        className: "whitespace--unset",
      },
      {
        Header: 'Group(s)',
        accessor: 'groups',
        className: "whitespace--unset",
      },
      {
        Header: 'Counts for Education',
        accessor: 'eval.sutq.accounting.counts_for_edu',
        isSUTQ: true,
        Filter({ filter, onChange }) {
          return (
            <select
              disabled={!isEditable}
              onChange={(e) => onChange(e.target.value)}
              value={filter ? filter.value : 'all'}
              className={"width--full"}
            >
              <option value="">All</option>
              <option value="1">Counts</option>
              <option value="0">Excluded</option>
            </select>
          )
        },
        width: 100,
        Cell: CheckboxField(),
      },
      {
        Header: 'Education Level',
        accessor: 'eval.sutq.education.level',
        Cell: SelectField(<EducationTypes onlyDegrees={true} allowEmpty={true} />),
      },
      {
        Header: 'CPL',
        accessor: 'eval.sutq.development.cpl',
        Cell: SelectField(
          <>
            <option value="">--</option>
            {Array(6)
              .fill(0)
              .map((x, index) => (
                <option key={index + 1} value={index + 1}>
                  Level {index + 1}
                </option>
              ))}
          </>
        ),
      },
      {
        Header: 'CDA',
        accessor: 'eval.sutq.education.cda',
        Cell: SelectField(
          cdaOptions.map((option) => (
            <option value={option.key} key={option.key}>{option.value}</option>
          ))
        ),
      },
      {
        Header: 'ACL',
        accessor: 'eval.sutq.development.acl',
        Cell: InputField({ type: 'number', step: "any", min: '0' }),
      },
      {
        Header: 'SAE',
        accessor: 'eval.sutq.development.sae',
        Cell: SelectField(
          [
            { key: '', value: 'None' },
            { key: '1', value: 'Level I' },
            { key: '2', value: 'Level II' },
            { key: '3', value: 'Level III' },
          ].map((d, i) => (
            <option key={i} value={d.key}>
              {d.value}
            </option>
          ))
        ),
      },
    ],
    pd: [
      {
        Header: 'Date of Hire',
        accessor: 'hire_date',
      },
      {
        Header: 'OA-PD Previous Fiscal Year Hours',
        accessor: 'eval.sutq.pd_previous_fy.total_hours',
        Cell: InputField({ type: 'number', step: "any"}),
      },
      {
        Header: 'OA-PD Previous Fiscal Year Required',
        accessor: 'eval.sutq.pd_previous_fy.required_hours',
      },
      {
        Header: 'PD Topics Completed',
        accessor: 'eval.sutq.pd_previous_fy.completed_pd_topics',
        Cell: row => {
          const completedTopics = data_get('eval.sutq.pd_previous_fy.completed_pd_topics', row.original)
          if(completedTopics?.length) {
            const completedTopicsArr = JSON.parse(completedTopics)
            return (
              <div>
                {completedTopicsArr.map(topic => (<div style={{whiteSpace: "break-spaces"}}>{topic}</div>))}
              </div>
            );
          }
          return "";
        }
      },
      {
        Header: 'Current Biennium PD Hours',
        accessor: 'eval.sutq.pd_current_biennium.total_hours',
        Cell: InputField({ type: 'number', step: "any"}),
      },
      {
        Header: 'Hours PD Required',
        accessor: 'eval.sutq.pd_current_biennium.required_hours',
      },
      {
        Header: 'Previous Biennium PD Hours',
        accessor: "eval.sutq.pd_previous_biennium.total_hours",
        Cell: InputField({ type: 'number', step: "any" }),
      },
    ],
  }
  const dynamicColumns = []
    .concat(dynamic[field])
    .filter(Boolean)
    .map((d) => ({
      ...d,
      style: { fontSize: 14 },
      headerStyle: { fontSize: 14, whiteSpace: 'pre-line', wordWrap: 'break-word' },
    }))

  const getUrl = url({ inspection_id, program_id })

  const licensingTabs = [
    {
      key: 'qualifications',
      name: 'Qualifications',
      url: getUrl(routes.licensingOverviewFields).replace(':field', 'qualifications'),
      type: 'licensing',
    },
    {
      key: 'staff-requirements',
      name: 'Staff Requirements',
      url: getUrl(routes.licensingOverviewFields).replace(':field', 'staff-requirements'),
      type: 'licensing',
    },
    {
      key: 'training-and-pd',
      name: 'Training and PD',
      url: getUrl(routes.licensingOverviewFields).replace(':field', 'training-and-pd'),
      type: 'licensing',
    },
    {
      key: 'health-and-safety-training',
      name: 'Health and Safety Training',
      url: getUrl(routes.licensingOverviewFields).replace(':field', 'health-and-safety-training'),
      type: 'licensing',
    },
  ]
  const sutqTabs = [
    {
      key: 'education',
      name: 'SUTQ Education',
      url: getUrl(routes.sutqOverviewFields).replace(':field', 'education'),
      type: 'sutq',
    },
    {
      key: 'pd',
      name: 'SUTQ PD',
      url: getUrl(routes.sutqOverviewFields).replace(':field', 'pd'),
      type: 'sutq',
    },
  ]
  const tabs = licensingMatch ? licensingTabs : sutqTabs

  return (
    <TrainingOverview
      columns={[...baseColumns, ...dynamicColumns]}
      employees={[...employees].map((d) => ({ ...d, eval: getEvaluationFields(d.erc_employee_id) }))}
      removeEmployees={handleDelete}
      {...{
        inspection,
        program_id,
        inspection_id,
        isLoading,
        isLicensing,
        isEditable,
        isSUTQ,
        licensingMatch,
        onSave,
      }}
      isEvaluationsLoading={evaluations.isLoading}
      isFormDirty={isFormDirty()}
    >
      <div className="mtm">
        <TabNavRouter tabs={tabs} active={field} />
      </div>
    </TrainingOverview>
  )
}

export const ErcEmployeesOverviewContainer = connect(
  (state, { inspection, inspection_id, program }) => ({
    isLoading: isLoading(state, 'employees'),
    isEditable: isEditable(state, { id: inspection_id }),
    isProgramResident: isProgramResident(state, program.id),
    employees: getResourceBy(state, 'employees', { key: 'inspection_id', value: inspection.id }),
    evaluations: { ...state.evaluations },
    isLicensing: isLicensing(state, inspection_id),
    isSUTQ: isSUTQ(state, inspection_id),
  }),
  {
    getInspection,
    getAllEvaluations,
    getEmployees,
    deleteEmployees,
    putEmployeeEvaluations,
  },
)(ErcEmployeesOverview)
