import React from 'react'
import ReactTable from 'react-table-6'

import opinFormatter from 'utils/opinFormatter'
import { displayDate } from 'utils/dateFormatter'
import useSticky from "hooks/useSticky";

export default (props) => {
  const { data, selected, onSelect, isLoading } = props

  const tableName = 'employee-search-table'
  const {stickyClass} = useSticky(tableName)
  function onPageChange() {
    const offsetTop = document.getElementsByClassName(tableName)?.[0]?.offsetTop
    window.scrollTo(0,  offsetTop ? (offsetTop - 50) : 0)
  }
  return (
    <ReactTable
      data={data}
      minRows={2}
      loading={isLoading}
      defaultPageSize={5}
      onPageChange={onPageChange}
      className={`${tableName} ${stickyClass}`}
      defaultSorted={[
        {
          id: 'hire_date',
          desc: true,
        },
        {
          id: 'full_name',
          asc: true,
        },
      ]}
      columns={[
        {
          Header: '',
          accessor: 'id',
          width: 36,
          Cell: (row) => (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <input
                type="radio"
                id={row.value}
                name="selected"
                value={row.value}
                checked={selected === row.value}
                onChange={onSelect}
              />
            </div>
          ),
        },
        {
          Header: 'Name',
          id: 'full_name',
          style: { fontSize: '16px' },
          accessor: (d) => `${d.last_name}, ${d.first_name}`,
        },
        {
          Header: 'OPIN',
          style: { fontSize: '16px' },
          accessor: 'opin',
          Cell: (row) => opinFormatter(row.value),
        },
        {
          Header: 'Date of Hire',
          style: { fontSize: '16px' },
          accessor: 'hire_date',
          Cell: (row) => displayDate(row.value),
        },
      ]}
    >
      {(state, makeTable, instance) => {
        return (
          <div>
            {makeTable()}
            <div style={{ textAlign: 'center', padding: '1em' }}>Displaying {data.length} Records</div>
          </div>
        )
      }}
    </ReactTable>
  )
}
