import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux'
import { getUserCredential } from 'stores/users'

export default function useAuth(refresh = false) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => ({
    ...state.users
  }))

  useEffect(() => {
    if(refresh || (!userData.user && !userData.userLoginError && !userData.userLogout)) {
      dispatch(getUserCredential());
    }
  }, [userData]);

  return userData;
}