const actionTypes = {}

export const registerActionTypes = (resource) => {
    const name = resource.toUpperCase()
    const actions = [
        'CREATE_$1',
        'CREATE_$1_SUCCESS',
        'CREATE_$1_FAILURE',
        'CREATE_$1S',
        'CREATE_$1S_SUCCESS',
        'CREATE_$1S_FAILURE',
        'GET_$1',
        'GET_$1_SUCCESS',
        'GET_$1_FAILURE',
        'GET_$1S',
        'GET_$1S_SUCCESS',
        'GET_$1S_FAILURE',
        'UPDATE_$1',
        'SAVE_$1',
        'SAVE_$1_SUCCESS',
        'SAVE_$1_FAILURE',
        'SAVE_$1S',
        'SAVE_$1S_SUCCESS',
        'SAVE_$1S_FAILURE',
        'DELETE_$1',
        'DELETE_$1_SUCCESS',
        'DELETE_$1_FAILURE',
        'DELETE_$1S',
        'DELETE_$1S_SUCCESS',
        'DELETE_$1S_FAILURE',
    ]
    actions.forEach((action) => {
        actionTypes[action.replace('$1', name)] = action.replace('$1', name)
    })
}

export default actionTypes