import React, { useState, useEffect } from 'react'
import { withFormik, Form } from 'formik'
import * as Yup from 'yup'
import EducationTypes  from 'constants/EducationTypes'
import { LoaderContainer } from 'components/shared/Loader'
import {
  SelectInput,
  Input,
  FormContainer,
  DateInput,
  RippleButton,
  SelectButtonInput,
  Checkbox,
} from 'components/shared/Forms'
import { Row, SectionHeader, } from 'components/shared/Layout'
import { cdaOptions }  from 'constants/cda'
import {packageEvaluations, formatCDA} from 'utils/evaluationUtils'

const StaffWorksheetForm = (props) => {
  const {
    dirty,
    handleSubmit,
    isEditable,
    isSubmitting,
    validateForm,
    /* and other goodies */
  } = props

  useEffect(() => {
    validateForm()
    const completedPDTopicsField = document.getElementsByName('sutq.pd_previous_fy.completed_pd_topics')[0];
    if(completedPDTopicsField) {
      completedPDTopicsField.style.height = completedPDTopicsField.scrollHeight;
    }
    return () => dirty && !isSubmitting && handleSubmit()
  }, [])

  return (
    <FormContainer>
      <Form autoComplete={'off'}>
        <Row>
          <SectionHeader isInRow noMarginTop>
            <div className="flex__row">
              <div className="flex--auto">Step Up To Quality Employee Record Chart</div>
              {isEditable && (
                <div className="flex--nogrow">
                  <RippleButton className="btn btn-warning" type="submit">
                    Save
                  </RippleButton>
                </div>
              )}
            </div>
          </SectionHeader>
        </Row>
        <Row>
          <Checkbox
            label="Counts for Educational Requirements"
            name="sutq.accounting.counts_for_edu"
            className="col-sm-12"
            disabled={!isEditable}
            {...props}
          />
          <Checkbox
            label="Used in Ratio"
            name="sutq.accounting.used_in_ratio"
            className="col-sm-12"
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Education</SectionHeader>
          <SelectInput
            name="sutq.education.cda"
            label="CDA Credential"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          >
            {cdaOptions.map((option) => (
              <option value={option.key} key={option.key}>{option.value}</option>
            ))}
          </SelectInput>
          <SelectInput
            label="Education Level"
            name="sutq.education.level"
            className="col-md-3 padding"
            disabled={!isEditable}
            {...props}
          >
            <EducationTypes onlyDegrees={true} allowEmpty />
          </SelectInput>
          <Input
            label="Major"
            name="sutq.education.major"
            className="col-md-3 padding  "
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <DateInput
            name="sutq.education.cda_expiration"
            label="CDA expiration"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <Input
            label="Institution"
            name="sutq.education.institution"
            className="col-md-4 padding"
            disabled={!isEditable}
            {...props}
          />
          <SelectButtonInput
            label="Is Related"
            name="sutq.education.is_related"
            options={[
              { key: 'yes', value: 'Yes' },
              { key: 'no', value: 'No' },
            ]}
            {...props}
            className="col-md-2 padding"
            disabled={!isEditable}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Development</SectionHeader>
          <SelectInput
            label="Career Pathways Level"
            className="padding"
            name="sutq.development.cpl"
            disabled={!isEditable}
            {...props}
          >
            <option value="" disabled>
              Select An Option
            </option>
            {Array(6)
              .fill(0)
              .map((x, index) => (
                <option key={index + 1} value={index + 1}>
                  Level {index + 1}
                </option>
              ))}
          </SelectInput>
          <Input
            label="Administrator Credential Level"
            name="sutq.development.acl"
            type="number"
            step="any"
            className="padding"
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <Input
            name="sutq.development.sae"
            label="SAE Level"
            type="number"
            min="1"
            max="3"
            step="any"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <DateInput
            name="sutq.development.sae_expiration"
            label="SAE expiration"
            className="col-sm-4  padding"
            bottom={true}
            disabled={true}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>OA-PD Previous Fiscal Year</SectionHeader>
          <Input
            label="Total Number of Hours"
            name="sutq.pd_previous_fy.total_hours"
            type="number"
            step="any"
            className="col-sm-4 padding"
            disabled={!isEditable}
            {...props}
          />
          <Input
            label="Required Number of Hours"
            name="sutq.pd_previous_fy.required_hours"
            type="number"
            step="any"
            className="col-sm-4 padding"
            disabled={!isEditable}
            {...props}
          />
          <Input
            label="Completed PD Topics"
            name="sutq.pd_previous_fy.completed_pd_topics"
            className="col-sm-4 padding"
            disabled={true}
            component={"textarea"}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>OA-PD Current Biennium</SectionHeader>
          <Input
            label="Total Number of Hours"
            name="sutq.pd_current_biennium.total_hours"
            type="number"
            step="any"
            className="col-sm-4 padding"
            disabled={!isEditable}
            {...props}
          />
          <Input
            label="Required Number of Hours"
            name="sutq.pd_current_biennium.required_hours"
            type="number"
            step="any"
            className="col-sm-4 padding"
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>OA-PD Previous Biennium</SectionHeader>
          <Input
            label="Total Number of Hours"
            name="sutq.pd_previous_biennium.total_hours"
            type="number"
            step="any"
            className="col-sm-4 padding"
            disabled={!isEditable}
            {...props}
          />
          <Input
            label="Required Number of Hours"
            name="sutq.pd_previous_biennium.required_hours"
            type="number"
            step="any"
            className="col-sm-4 padding"
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Step Up To Quality</SectionHeader>
          <SelectButtonInput
            label="Role"
            name="sutq.quality.role"
            className="padding"
            disabled={!isEditable}
            options={[
              { key: 'Admin', value: 'Admin' },
              { key: 'Lead Teacher', value: 'Lead Teacher' },
              { key: 'Assistant Teacher', value: 'Assistant Teacher' },
              { key: 'Other', value: 'Other' },
            ]}
            {...props}
          />
        </Row>
        {isEditable && (
          <div className="flex__row">
            <div className="flex--auto" />
            <div className="flex--nogrow">
              <RippleButton className="btn btn-warning" type="submit">
                Save
              </RippleButton>
            </div>
          </div>
        )}
      </Form>
    </FormContainer>
  )
}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props }) => {
    setSubmitting(false)
    const { erc_id, erc_employee_id, putEmployeeEvaluations } = props
    putEmployeeEvaluations(packageEvaluations(erc_id, erc_employee_id, 'sutq', values))
    return values
  },
  mapPropsToValues: (props) => {
    const values = {...(props.evaluation || {})}
    if(values.sutq?.education?.cda) {
      values.sutq.education.cda = formatCDA(values.sutq.education.cda)
    }
    if(values.sutq?.pd_previous_fy?.completed_pd_topics) {
      const completedPdTopics = JSON.parse(values.sutq.pd_previous_fy.completed_pd_topics);
      values.sutq.pd_previous_fy.completed_pd_topics = completedPdTopics.join("\r\n")
    }
    return values
  },
  validationSchema: Yup.object().shape({}),
  displayName: 'StaffWorksheetForm',
})

const EnhancedForm = formikEnhancer(StaffWorksheetForm)

const StaffWorksheetFormCtrl = (props) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const { getEmployeeEvaluations, isLoading, erc_id, erc_employee_id, evaluation } = props
  useEffect(() => {
    if(!evaluation) {
      getEmployeeEvaluations(erc_id, erc_employee_id)
    } else {
      setFirstLoad(false)
    }
  }, [erc_id, erc_employee_id, evaluation])
  if (firstLoad || isLoading) {
    return <LoaderContainer />
  }
  return <EnhancedForm {...props} />
}

export default StaffWorksheetFormCtrl
