import React, { useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import InputContainer from './InputContainer'
import Error from './Error'
import getValue from './getValue'

const isRequired = (message) => (value) => (!!value ? undefined : message)

const SelectInput = (props) => {
  const {
    errors,
    touched,
    name,
    disabled,
    required = false,
    label,
    values,
    children,
    className,
    setFieldValue,
    hideLabel = false,
  } = props
  const showError = getValue(errors, name) && getValue(touched, name)

  const value = getValue(values, name)

  useEffect(() => {
    if (value === null) {
      setFieldValue(name, '', false)
    }
  }, [])

  return (
    <InputContainer className={className}>
      {!hideLabel && <label htmlFor={name}>{label}</label>}
      <Field
        id={name}
        name={name}
        component="select"
        value={value || ""}
        disabled={disabled || false}
        required={required || false}
        onChange={props.onChange || props.handleChange}
        validate={isRequired(required ? 'This field is required' : undefined)}
        onBlur={props.handleBlur}
        style={showError ? { borderColor: 'red' } : {}}
        className={(showError ? 'text-input error' : 'text-input') + ' form-control col-sm-12'}
      >
        {children}
      </Field>
      <ErrorMessage 
        style={showError ? { color: 'red' } : {}}
        name={props.name} 
        component={Error} 
      />
    </InputContainer>
  )
}

export default SelectInput
