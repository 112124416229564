import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { SectionHeader, Row, Paper } from 'components/shared/Layout'
import {
  Input,
  Checkbox,
  RippleButton,
  ButtonContainer,
  FormContainer,
  Label,
  Error
} from 'components/shared/Forms'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const promiseFn = (value) => (value ? Promise.resolve(false) : Promise.reject())
const rowStyle = { margin: '0em' }

export default (props) => {
  const { isCreate = false, onSubmit = promiseFn, onCancel = () => {}, group = {}, groups = [] } = props
  const existingGroupNames = groups.filter(g => (!group.id || group.id !== g.id )).map(g => String(g.name).trim())
  return (
    <FormContainer padding="1em 0em">
      <Paper
        header={
          <SectionHeader noMarginTop style={{ padding: '0.5em 1em', backgroundColor: '#173c56', color: 'white' }}>
            {isCreate ? 'Create' : 'Edit'} Group
          </SectionHeader>
        }
      >
        <Formik
          onSubmit={(values) => onSubmit(values)}
          initialValues={{
            name: '',
            lead_teacher: '',
            age_groups: {
              infants: false,
              toddlers: false,
              preschool: false,
              schoolage: false,
            },
            ...group,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .transform(value => String(value).trim().toLowerCase())
              .notOneOf(existingGroupNames.map(g => g.toLowerCase()), `Group name must be unique. The following group names have already been assigned: ${existingGroupNames.join(", ")}`)
              .required('Required'),
            age_groups: Yup.mixed().test(
              'age_groups',
              'Select at least one age group',
              (value) => value.infants || value.toddlers || value.preschool || value.schoolage
            )
          })}
        >
          {(props) => (
            <Form>
              <Row style={rowStyle}>
                <Input name="name" label="Name" className="col-md-4" {...props} />
              </Row>
              <Row style={rowStyle}>
                <div className="col"><Label>Age Groups</Label></div>
              </Row>
              <Row style={rowStyle}>
                <Checkbox className="col" name="age_groups.infants" label="Infants" {...props} />
                <Checkbox className="col" name="age_groups.toddlers" label="Toddlers" {...props} />
                <Checkbox className="col" name="age_groups.preschool" label="Preschool" {...props} />
                <Checkbox className="col" name="age_groups.schoolage" label="Schoolage" {...props} />
              </Row>
              <Row style={rowStyle}>
                <div className="col"><ErrorMessage name="age_groups" component={Error} /></div>
              </Row>

              <ButtonContainer left>
                <RippleButton className={`btn btn-${props.dirty ? 'warning' : 'success'}`} type="submit">
                  {isCreate ? (
                    <span>
                      <FontAwesomeIcon icon={faPlus}/>
                      Add
                    </span>
                  ) : (
                    'Save'
                  )}
                </RippleButton>
                <RippleButton onClick={() => onCancel()} className="btn btn-default" type="button">
                  Cancel
                </RippleButton>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Paper>
    </FormContainer>
  )
}
