import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import IconButton  from 'components/shared/Buttons/IconButton'
import {faArrowLeft, faBars} from '@fortawesome/free-solid-svg-icons'

const MenuItem = styled.div`
  min-height: 56px;
  max-height: 56px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    min-height: 45px;
    max-height: 45px;
  }
`

const Menu = styled.div`
  display: flex;
  padding: 0;
  font-size: 1.25em;
  margin: 0;
  background: #015a9b;
  color: white;
  min-height: 45px;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    min-height: 45px;
  }
`

const MenuCollapse = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { items, active } = props
  const selected = items.find((x) => x.key === active) || {}
  return (
    <Menu>
      <MenuItem className="flex--nogrow">
        <IconButton onClick={() => setIsOpen(!isOpen)} icon={isOpen ? faArrowLeft : faBars} />
      </MenuItem>
      {isOpen ? (
        <div className="flex--auto">
          {items.map((item) => (
            <MenuItem key={item.key}>
              <Link key={item.key} to={item.url} onClick={() => setIsOpen(false)} style={{ color: 'white' }}>
                {item.name}
              </Link>
            </MenuItem>
          ))}
        </div>
      ) : (
        <MenuItem>{selected.name}</MenuItem>
      )}
    </Menu>
  )
}

export default MenuCollapse
