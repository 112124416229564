import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Container } from "react-bootstrap"

import EmployeeRecordCharts from 'components/EmployeeRecordCharts'

import BlockHeader from 'components/shared/BlockHeader'
import { NiceHr } from 'components/shared/Layout'
import routes from "constants/routes"

export default props => {
  return (
    <Container>
      <BlockHeader>
        <div id="erc-table-header" style={{ display: 'flex' }}>
          <div style={{ flex: 'auto', textAlign: 'left' }}>Employee Record Charts</div>
          <Link className="btn btn-info text-white" to={routes.programs}>
            <FontAwesomeIcon icon={faSearch} className="mrs"/>
            Find a program
          </Link>
        </div>
      </BlockHeader>
      <NiceHr />

      <EmployeeRecordCharts />
    </Container>
  )
}