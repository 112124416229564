import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { LoaderContainer } from 'components/shared/Loader'
import {SectionHeader} from 'components/shared/Layout'
import axios from 'axios'
import apiUrls from "constants/apiUrls";
import ProfessionalTrainingList from "./ProfessionalTrainingList";

const EmployeeTrainingOverview = (props) => {
  const { erc_employee_id, employee, inspection_id: erc_id } = props
  const [trainings, setTrainings] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios.get(`${apiUrls.trainingOverview}?user_id=${employee.user_id}`)
      .then(({ data }) => {
        setTrainings(data)
        setLoading(false)
      }).catch(error => {
        console.log("error", error)
        setLoading(false)
      })
  }, [employee])

  if (!employee || !trainings) {
    return <LoaderContainer />
  }
  return (
    <div>
      <SectionHeader>Training Overview</SectionHeader>
      <ProfessionalTrainingList data={trainings} loading={loading}/>
    </div>
  )
}

export default connect((state, { employee_id, inspection_id }) => ({}), {})(EmployeeTrainingOverview)
