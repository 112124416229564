import moment from 'moment'

export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY'
export const DATE_STORAGE_FORMAT = 'YYYY-MM-DD'
export const DATE_DB_NULL = '0000-00-00'
export const DB_DATE_FORMAT = 'YYYY-MM-DD'

export const displayDate = (date) => (date && date !== DATE_DB_NULL ? moment(date).format(DATE_DISPLAY_FORMAT) : '')
export const isDateBeforeToday = (date) => moment(date).isBefore(moment())
export const isDateABeforeB = (dateA, dateB) => moment(dateA).isBefore(moment(dateB))
export const convertDateToStorageFormat = (date) => moment(date, DATE_DISPLAY_FORMAT).format(DATE_STORAGE_FORMAT)
export const convertDisplayDateToDB = (date) => moment(date).format(DB_DATE_FORMAT)