import React, { useState } from 'react'

import { SelectionBox } from './'

let mouseThrottle = false
let selectables = []
let boxes = []
let selectFrom = false
let offset = false
let touchId = false

const DEBUG = false

function getPosition() {
  const style = window.getComputedStyle(document.body)
  const t = style.getPropertyValue('margin-top')
  const l = style.getPropertyValue('margin-left')
  const mLeft = parseInt(l.slice(0, l.length - 2), 10)
  const mTop = parseInt(t.slice(0, t.length - 2), 10)

  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = document.getElementById('selectable-area').getBoundingClientRect()
  return { x: Math.round(elemRect.left - bodyRect.left + mLeft), y: Math.round(elemRect.top - bodyRect.top + mTop) }
}

function getSelection(selectTo) {
  const from = {}
  const to = {}
  if (selectFrom && selectTo) {
    from.x = selectFrom.x <= selectTo.x ? selectFrom.x : selectTo.x
    to.x = selectFrom.x <= selectTo.x ? selectTo.x : selectFrom.x
    from.y = selectFrom.y <= selectTo.y ? selectFrom.y : selectTo.y
    to.y = selectFrom.y <= selectTo.y ? selectTo.y : selectFrom.y
    for (let i = 0; i < selectables.length; i += 1) {
      let { x, y, width, height } = boxes[i]
      x += window.scrollX - offset.x
      y += window.scrollY - offset.y

      const p1 = { x, y }
      const p2 = { x: x + width, y: y + height }

      const isSelected = !(p2.x < from.x || to.x < p1.x || p2.y < from.y || to.y < p1.y)
      if (isSelected) {
        selectables[i].classList.add('selected')
      } else {
        selectables[i].classList.remove('selected')
      }
    }
  } else {
    for (let i = 0; i < selectables.length; i += 1) {
      selectables[i].classList.remove('selected')
    }
  }
}

function mapSelectables() {
  const elements = [...document.getElementsByClassName('selectable')]
  selectables = elements
  boxes = []
  for (let i = 0; i < elements.length; i += 1) {
    const { x, y, width, height } = elements[i].getBoundingClientRect()
    boxes.push({ x, y, width, height })
  }
}

const SelectableArea = (props) => {
  const { onSelection = () => {}, children, disabled } = props
  const [isSelectFrom, setIsSelectFrom] = useState(false)
  const [selectTo, setSelectTo] = useState({ x: 0, y: 0 })

  const updatePosition = (e) => {
    const to = {
      x: e.pageX - offset.x,
      y: e.pageY - offset.y,
    }
    getSelection(to)
  }

  const handleMouseMove = (e) => {
    const to = {
      x: e.pageX - offset.x,
      y: e.pageY - offset.y,
    }
    setSelectTo(to)
    const currentTime = Date.now()
    if (currentTime - mouseThrottle > 20) {
      mouseThrottle = currentTime
      getSelection(to)
    }
  }

  const startSelection = (e) => {
    mapSelectables()
    mouseThrottle = 0
    offset = getPosition()
    const from = { x: e.pageX - offset.x, y: e.pageY - offset.y }
    setIsSelectFrom(true)
    selectFrom = from
    setSelectTo(from)
  }

  const endSelection = (e) => {
    updatePosition(e)

    const selection = [...document.getElementsByClassName('selectable selected')]
    onSelection(selection.map((x) => x.id))
    window.globalThis.selectFrom = false
    selectFrom = false
    setIsSelectFrom(false)
    setSelectTo(false)
    getSelection(false)
  }

  const handleMouseDown = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (e.which === 3 || e.button === 2) {
      return
    }
    startSelection(e)

    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
  }

  const handleMouseUp = (e) => {
    e.stopPropagation()
    e.preventDefault()

    endSelection(e)

    window.removeEventListener('mouseup', handleMouseUp)
    window.removeEventListener('mousemove', handleMouseMove)
  }

  const handleTouchMove = (e) => {
    const touch = [...e.touches].find((x) => x.identifier === touchId)
    handleMouseMove(touch)
  }

  const handleTouchEnd = (e) => {
    if (e.changedTouches[0].identifier == touchId) {
      const touch = e.changedTouches[0]
      endSelection(touch)
      touchId = false

      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  }

  const handleTouchStart = (e) => {
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('touchend', handleTouchEnd)
    if (!touchId) {
      touchId = e.touches[0].identifier
      startSelection(e.touches[0])
    }
  }

  if (disabled) {
    return <div>{children}</div>
  }

  return (
    <div
      id="selectable-area"
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      style={{ position: 'relative', display: 'inline-block', touchAction: 'none' }}
    >
      {DEBUG && (
        <div style={{ position: 'fixed', top: '500', left: '0' }}>
          <div>
            x: {selectFrom && selectFrom.x} Y: {selectFrom && selectFrom.y}
          </div>
          <div>touch:{touchId}</div>
          <div>
            Width: {selectTo.x} Height: {selectTo.y}
          </div>
        </div>
      )}
      {selectFrom && selectTo && <SelectionBox from={selectFrom} to={selectTo} />}
      {children}
    </div>
  )
}

export default SelectableArea
