import React, { useState, useEffect } from 'react'
import { withFormik, Form } from 'formik'

import * as Yup from 'yup'
import getValue from 'components/shared/Forms/getValue'
import EducationTypes  from 'constants/EducationTypes'
import { cdaOptions } from 'constants/cda'
import {
  SelectInput,
  Input,
  FormContainer,
  DateInput,
  RippleButton,
  Checkbox,
} from 'components/shared/Forms'
import { LoaderContainer } from 'components/shared/Loader'
import { Row, SectionHeader } from 'components/shared/Layout'
import {packageEvaluations, formatCDA} from 'utils/evaluationUtils'

import { ComplianceField } from 'components/shared/Forms/ComplianceField'

const QualificationsForm = (props) => {
  const {
    values,
    dirty,
    handleSubmit,
    isSubmitting,
    isEditable,
    validateForm,
    /* and other goodies */
  } = props

  useEffect(() => {
    validateForm()
    return () => dirty && !isSubmitting && handleSubmit()
  }, [])

  const isEducationComplianceOther = getValue(values, 'qualifications.education.compliance_status') === 'other'

  return (
    <FormContainer>
      <Form autoComplete={'off'}>
        <SectionHeader noMarginTop>
          <div className="flex__row">
            <div className="flex--auto">Qualifications</div>
            {isEditable && (
              <div className="flex--nogrow">
                <RippleButton className="btn btn-warning" type="submit">
                  Save
                </RippleButton>
              </div>
            )}
          </div>
        </SectionHeader>
        <Row>
          <Checkbox
            label="Used in Ratio"
            name="staff_requirements.ratio.used_in_ratio"
            {...props}
            className="col-sm-12"
            disabled={!isEditable}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>User</SectionHeader>
          <Input
            name="qualifications.user.first_name"
            label="First Name"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <Input
            name="qualifications.user.last_name"
            label="Last Name"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <Input
            name="qualifications.user.opin"
            label="OPIN"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Development</SectionHeader>
          <SelectInput
            name="qualifications.development.cda"
            label="CDA Credential"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          >
            {cdaOptions.map((option) => (
              <option value={option.key} key={option.key}>{option.value}</option>
            ))}
          </SelectInput>

          <Input
            name="qualifications.development.acl"
            label="ACL Level"
            type="number"
            step="any"
            min="1"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <Input
            name="qualifications.development.sae"
            label="SAE Level"
            type="number"
            step="any"
            min="1"
            max="3"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <DateInput
            name="qualifications.development.cda_expiration"
            label="CDA expiration"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <DateInput
            name="qualifications.development.acl_expiration"
            label="ACL expiration"
            className="col-sm-4  padding"
            bottom={true}
            disabled={true}
            {...props}
          />
          <DateInput
            name="qualifications.development.sae_expiration"
            label="SAE expiration"
            className="col-sm-4  padding"
            bottom={true}
            disabled={true}
            {...props}
          />
        </Row>

        <Row>
          <SectionHeader isInRow>Career Pathways Level</SectionHeader>
          <Input
            name="qualifications.cpl.current_level"
            label="Current Level"
            type="number"
            min="1"
            max="6"
            step="any"
            className="col-sm-4 padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <Input
            name="qualifications.cpl.assessed_level"
            label="Assessed As"
            type="number"
            min="1"
            max="6"
            step="any"
            className="col-sm-4 padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
        </Row>

        <Row>
          <SectionHeader isInRow>Education</SectionHeader>
          <SelectInput
            name="qualifications.education.hs_diploma"
            label="High School Diploma"
            className="col-sm-4  padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          >
            <option></option>
            <option>Yes</option>
            <option>No</option>
          </SelectInput>
          <ComplianceField
            name="qualifications.education.compliance_status"
            stem="qualifications.education"
            label="Compliance Code"
            className="col-sm-4 padding"
            showOther={isEducationComplianceOther}
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <SelectInput
            label="Highest Education"
            name="qualifications.education.level"
            disabled={!isEditable}
            className="col-md-4 padding"
            {...props}
          >
            <EducationTypes onlyDegrees={true} allowEmpty={true} />
          </SelectInput>
        </Row>
        {isEditable && (
          <div className="flex__row">
            <div className="flex--auto" />
            <div className="flex--nogrow">
              <RippleButton className="btn btn-warning">Save</RippleButton>
            </div>
          </div>
        )}
      </Form>
    </FormContainer>
  )
}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  handleSubmit: (values, { setSubmitting, props }) => {
    const { erc_id, erc_employee_id, putEmployeeEvaluations } = props
    putEmployeeEvaluations(packageEvaluations(erc_id, erc_employee_id, 'licensing', values))
    setSubmitting(false)
    return values
  },
  mapPropsToValues: (props) => {
    const values = {...(props.evaluation || {})}
    if(values.qualifications?.development?.cda) {
      values.qualifications.development.cda = formatCDA(values.qualifications.development.cda)
    }
    return values
  },
  validationSchema: Yup.object().shape({}),
  displayName: 'QualificationsForm',
})

const EnhancedForm = formikEnhancer(QualificationsForm)

const QualificationsFormCtrl = (props) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const { getEmployeeEvaluations, isLoading, erc_id, erc_employee_id, evaluation } = props
  useEffect(() => {
    if(!evaluation) {
      getEmployeeEvaluations(erc_id, erc_employee_id)
    } else {
      setFirstLoad(false)
    }
  }, [erc_id, erc_employee_id, evaluation])
  if (firstLoad || isLoading) {
    return <LoaderContainer />
  }
  return <EnhancedForm {...props} />
}

export default QualificationsFormCtrl
