import React from "react";
import {Link} from "react-router-dom";
import {JFS_CENTER, ODE_CENTER} from "constants";
import ReactTable from "react-table-6";
import useSticky from "hooks/useSticky";

export default function(props) {
  const {
    data = [],
    pages,
    sorted,
    loading,
    onFetchData,
  } = props

  const tableName = 'program-search-table'
  const {stickyClass} = useSticky(tableName)
  function onPageChange() {
    const offsetTop = document.getElementsByClassName(tableName)?.[0]?.offsetTop
    window.scrollTo(0,  offsetTop ? (offsetTop - 50) : 0)
  }

  return (
    <ReactTable
      manual
      data={data}
      pages={pages}
      defaultSorted={sorted}
      loading={loading}
      defaultPageSize={20}
      onFetchData={onFetchData}
      columns={[
        {
          Header: 'Name',
          accessor: 'program_name',
          Cell: (row) => (
            <Link to={`/programs/${row.original.id}`}>
              <div>{row.value}</div>
            </Link>
          ),
        },
        {
          Header: 'Program Number',
          accessor: 'license_number',
          Cell: (row) => (
            <Link to={`/programs/${row.original.id}`}>
              <div>{row.value}</div>
            </Link>
          ),
        },
        {
          Header: 'Status',
          accessor: 'program_status',
        },
        {
          Header: 'County',
          accessor: 'county',
        },
        {
          Header: 'Type',
          accessor: 'center_type',
          Cell: (row) => {
            switch (row.value) {
              case JFS_CENTER:
                return 'JFS Center'
              case ODE_CENTER:
                return 'ODE Center'
              case "Inhome Aide":
                return "In-Home Aide"
              default:
                return row.value
            }
          },
        },
      ]}
      onPageChange={onPageChange}
      className={`${tableName} ${stickyClass}`}
    />
  )
}