import React from 'react';
import ReactDOM from 'react-dom';
import "datejs";
import 'styles/main.scss';
import axios from "axios";
import App from './App';

import store from 'stores';
import { Provider } from 'react-redux'
import { oprUrls } from "constants/apiUrls";
import swal from "sweetalert2";

if(process.env.REACT_APP_BASE_URL) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
}

axios.interceptors.response.use(
  response => response,
  error => {
    if(error?.response?.status === 401) {
      window.location.href = oprUrls.baseUrl
    } else {
      if(error?.response?.status === 403) {
        let message = "You do not have the permission to perform this action.";
        if(typeof error?.response?.data === "string") {
          message = error.response.data
        }
        swal.fire({
          title: 'Error',
          html: message,
          icon: 'error',
        })
      }
      return Promise.reject(error);
    }
  }
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('erc-container')
);