import { CREATE_INSPECTION_SUCCESS } from 'stores/inspections/actionTypes'

// Action Types
const CLEAR_TRANSITION = 'CLEAR_TRANSITION'

// Selectors
export const getTransition = (state) => state.transition.to

// Actions
export const clearTransition = () => ({
  type: CLEAR_TRANSITION,
})

// Reducer
const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INSPECTION_SUCCESS:
      const { inspection } = action.payload
      const { program_id, id } = inspection
      const to = `/${program_id}/${id}`
      return { to }
    case CLEAR_TRANSITION:
      return {}
    default:
      return state
  }
}
