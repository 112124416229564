import React, {Component} from 'react'
import axios from 'axios'
import apiUrls from "constants/apiUrls"

import Breadcrumbs from "components/shared/Breadcrumbs"
import TableControls from "components/shared/TableControls"
import BlockHeader from "components/shared/BlockHeader"
import { Container } from "react-bootstrap"
import { NiceHr } from "components/shared/Layout"
import routes from "constants/routes"
import ProgramSearchTable from "components/ProgramSearch/ProgramSearchTable";

let debouncer = null
const breadcrumbs = [
  {
    to: routes.base,
    place: 'ERC',
  },
  {
    to: routes.programs,
    place: 'Find a Program',
  },
]

class ProgramSearch extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      query: '',
      page: 1,
      loading: false,
      pageSize: 20,
      sorted: [{ id: 'program_name', desc: false }],
    }
    this.get = this.get.bind(this)
    this.search = this.search.bind(this)
  }

  get(state) {
    this.setState({ loading: true })
    const { pageSize, page, sorted, query } = state
    const params = {
      pageSize,
      page,
      sorted,
      query: (query || '')
        .replace(/[\,\.]/g, ' ')
        .split(' ')
        .map((x) => x.trim())
        .filter((x) => x)
        .join(' '),
    }
    axios
      .get(apiUrls.programs, { params })
      .then(({ data }) => {
        this.setState({ data: data.data, pages: data.pages })
      })
      .finally(() => this.setState({ loading: false }))
  }

  search(event) {
    const query = event?.target?.value || ""

    const context = this
    if (debouncer) {
      clearTimeout(debouncer)
    }
    context.setState({ query })
    debouncer = setTimeout(() => {
      const { pageSize, sorted } = this.state
      context.setState(
        { page: 0 },
        context.get({
          page: 0,
          pageSize,
          sorted,
          query,
        }),
      )
    }, 250)
  }

  render() {
    const { data, loading, pages, sorted, query } = this.state
    return (
      <Container>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <BlockHeader>Find A Program</BlockHeader>
        <NiceHr />
        <TableControls autofocus query={query} onSearch={this.search} placeholder="Search Programs..." noPadding/>
        <ProgramSearchTable {...{data, pages, sorted, loading, onFetchData: this.get}} />
      </Container>
    )
  }
}

export default ProgramSearch
