import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from "react-router-dom";
import routes, { url } from 'constants/routes'
import { TabNavRouter } from 'components/shared/TabNavRouter'
import { isProgramResident, isProgramCenter, centersWithCoverage } from 'stores/programs'
import { isLicensing, isSUTQ } from 'stores/inspections'

const InspectionTabs = (props) => {
  const location = useLocation()
  const { inspection_id, program_id, isProgramResident, isProgramCenter, centersWithCoverage, v, isLicensing, isSUTQ } = props
  const isLicensingOverview = location.pathname.includes('licensing-overview')
  const isSutqOverview = location.pathname.includes('sutq-overview')
  const view = String(location.hash).replace("#/", "").split("/")[0]
  const isEmployeeView = !!['employees', 'find', 'add'].find((x) => x === view)

  let active = view

  if (isLicensingOverview) {
    active = 'licensing-overview'
  }

  if (isSutqOverview) {
    active = 'sutq-overview'
  }

  if (isEmployeeView) {
    active = 'employees_view'
  }

  const getUrl = url({ inspection_id, program_id })
  let tabs = [
    {
      key: 'program_info',
      name: 'Program Information',
      url: getUrl(routes.programInformation),
    },
    {
      key: 'groups',
      name: 'Groups',
      url: getUrl(routes.groups),
    },
    {
      key: 'employees_view',
      name: isProgramResident ? 'Employees / Residents' : 'Employees',
      url: getUrl(routes.employees),
    },
    {
      key: 'licensing-overview',
      name: 'Licensing Program Overview',
      url: getUrl(routes.licensingOverview),
    },
    {
      key: 'sutq-overview',
      name: 'SUTQ Program Overview',
      url: getUrl(routes.sutqOverview),
    },
    {
      key: 'coverage_tool',
      name: 'Coverage Tool',
      url: getUrl(routes.coverageTool),
    },

  ]

  if (!isLicensing) {
    tabs = tabs.filter((tab) => tab.key !== 'licensing-overview')
  }

  if (!(isLicensing && centersWithCoverage)) {
    tabs = tabs.filter((tab) => tab.key !== 'coverage_tool')
  }

  if (!isSUTQ) {
    tabs = tabs.filter((tab) => tab.key !== 'sutq-overview')
  }

  return <TabNavRouter tabs={tabs} active={active} />
}

export default connect((state, props) => ({
  isProgramResident: isProgramResident(state, props.program_id),
  isLicensing: isLicensing(state, props.inspection_id),
  isSUTQ: isSUTQ(state, props.inspection_id),
  isProgramCenter: isProgramCenter(state, props),
  centersWithCoverage: centersWithCoverage(state, props),
}))(InspectionTabs)
