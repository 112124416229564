import React from 'react'
import ReactTable  from 'react-table-6'
import { Link } from 'react-router-dom'
import { displayDate } from 'utils/dateFormatter'
import routes, { url } from 'constants/routes'
import useSticky from "hooks/useSticky";

const ErcTable = (props) => {
  const {
    ercs = [],
    onFetchData,
    pages,
    pageSize,
    onClone,
    onUnlock,
    onDelete,
    onComplete,
    isLoading,
    isSupervisor,
    isODJFSManager,
  } = props

  const formattedErcs = ercs.map((e) => ({
    ...e,
    type_formatted: (e.evaluation_types && e.evaluation_abbrs) &&
      (new Set(e.evaluation_types.split(',')).size > 1
        ? 'Combined ERC'
        : Array.from(new Set(e.evaluation_abbrs.split(', '))).join(', ')),
  }))

  const actionDrawer = (row) => {
    return row.original.completed_by ? (
      <div className="flex__row" style={{ backgroundColor: 'rgba(0,0,0,0.03)' }}>
        {
          isODJFSManager &&
          <div className="flex--33" style={{ padding: '1em' }}>
            <button data-id={row.original.id} onClick={onDelete} className="btn btn-danger" style={{ margin: '0em 1em' }} data-status={row.original.status}>
              Delete ERC
            </button>
          </div>
        }
        {
          isSupervisor &&
          <div className="flex--33" style={{ padding: '1em' }}>
            <button data-id={row.original.id} onClick={onUnlock} className="btn btn-info text-white" style={{ margin: '0em 1em' }}>
              Unlock ERC
            </button>
          </div>
        }

        <div className="flex--33" style={{ padding: '1em' }}>
          <button data-id={row.original.id} onClick={onClone} className="btn btn-info text-white" style={{ margin: '0em 1em' }}>
            Clone ERC
          </button>
        </div>
      </div>
    ) : (
      <div className="flex__row" style={{ backgroundColor: 'rgba(0,0,0,0.03)' }}>
        <div className="flex--33" style={{ padding: '1em' }}>
          <button data-id={row.original.id} onClick={onDelete} className="btn btn-danger" style={{ margin: '0em 1em' }} data-status={row.original.status}>
            Delete ERC
          </button>
        </div>
        <div className="flex--33" style={{ padding: '1em' }}>
          <button data-id={row.original.id} onClick={onComplete} className="btn btn-info text-white" style={{ margin: '0em 1em' }}>
            Complete ERC
          </button>
        </div>
        <div className="flex--33" style={{ padding: '1em' }}></div>
      </div>
    )
  }
  const tableName = 'inspections-table'
  const {stickyClass} = useSticky(tableName)

  function onPageChange() {
    const offsetTop = document.getElementsByClassName(tableName)?.[0]?.offsetTop
    window.scrollTo(0,  offsetTop ? (offsetTop - 50) : 0)
  }

  return (
    <ReactTable
      manual
      data={formattedErcs}
      pages={pages}
      pageSize={pageSize}
      defaultPageSize={pageSize || 10}
      onFetchData={onFetchData}
      minRows={2}
      loading={isLoading}
      SubComponent={actionDrawer}
      defaultSorted={[{ id: 'date_updated', desc: true }]}
      onPageChange={onPageChange}
      className={`${tableName} ${stickyClass}`}
      columns={[
        {
          Header: 'Name',
          accessor: 'program_name',
          style: { fontSize: '16px' },
          Cell: (row) => {
            const { program_id, id } = row.original
            const inspectionUrl = url({inspection_id: id, program_id})(routes.inspection)
            return <Link to={inspectionUrl}>{row.value}</Link>
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
          style: { fontSize: '16px' },
          Cell: (row) => {
            const { revision, id, program_id } = row.original
            return (
              revision
                ? <Link to={url({inspection_id: revision, program_id})(routes.inspection)}>{row.value}</Link>
                : row.value
            )
          },
        },
        {
          Header: 'Type',
          accessor: 'evaluation_types',
          style: { fontSize: '16px' },
          Cell: (row) => row.original.type_formatted,
        },
        {
          Header: 'OCLQS ID',
          accessor: 'oclqs_id',
          style: { fontSize: '16px' },
        },
        {
          Header: 'OCLQS Date',
          accessor: 'oclqs_date',
          style: { fontSize: '16px' },
          Cell: (row) => <span>{displayDate(row.value)}</span>,
        },
        {
          Header: 'Last Modified',
          accessor: 'date_updated',
          style: { fontSize: '16px' },
          Cell: (row) => <span>{displayDate(row.value)}</span>,
        },
        {
          Header: 'Completed By',
          accessor: 'completed_by_name',
          style: { fontSize: '16px' },
        },
        {
          Header: 'Unlocked By',
          accessor: 'unlocked_by_name',
          style: { fontSize: '16px' },
          Cell: (row) => {
            const { program_id, previous_id, unlock_reason, unlocked_by_name } = row.original
            return row.value ? (
              <Link to={url({inspection_id: previous_id, program_id})(routes.inspection)}>
                {unlocked_by_name} - {unlock_reason}
              </Link>
            ) : (
              <span />
            )
          },
        },
      ]}
    >
      {(state, makeTable, instance) => {
        return (
          <div id="erc-table">
            {makeTable()}
            <div style={{ textAlign: 'center', padding: '1em' }}>Displaying {ercs.length} Records</div>
          </div>
        )
      }}
    </ReactTable>
  )
}

export default ErcTable
