import React, { Fragment, useState, useEffect } from 'react'
import { toUpperCaseWords } from "utils/stringUtils";

const educationTypesJson = {
  '0': { id: '397', name: '2-year Career Tech Education', type: 'basic_education' },
  '1': { id: '418', name: 'Affidavit of Diploma', type: 'basic_education' },
  '2': { id: '392', name: 'High School', type: 'basic_education' },
  '3': { id: '393', name: 'High School Equivalency - GED, TASC, HiSET', type: 'basic_education' },
  '5': { id: '445', name: 'No Degree - Child Development Exemption', type: 'degree' },
  '6': { id: '446', name: 'No Degree - College Coursework for PD Certificate', type: 'degree' },
  '7': { id: '465', name: 'No Degree - POWER Ohio', type: 'degree' },
  '8': { id: '399', name: 'Associates Degree', type: 'degree' },
  '10': { id: '386', name: "Bachelor's Degree", type: 'degree' },
  '12': { id: '389', name: "Master's Degree", type: 'degree' },
  '14': { id: '391', name: 'Doctorate', type: 'degree' },
}

const EducationTypes = ({ onlyDegrees, allowEmpty = false }) => {
  const [educationTypes, setEducationTypes] = useState(educationTypesJson)

  const educationGroups = Array.from(new Set(Object.values(educationTypes).map((x) => x.type)))

  return (
    <Fragment>
      <option value="" disabled={!allowEmpty}>
        Select an option
      </option>
      {educationGroups.map((group) => (
        <optgroup key={group} label={toUpperCaseWords(group.replace('_', ' '))}>
          {Object.values(educationTypes)
            .filter((x) => x.type === group && (!onlyDegrees || (onlyDegrees && !x.name.match('No Degree'))))
            .map((option) => (
              <option key={option.id} data-type={option.type} value={option.id}>
                {option.name}
              </option>
            ))}
        </optgroup>
      ))}
    </Fragment>
  )
}

export default EducationTypes
