import swal from "sweetalert2";

export default function (error) {
  if(error?.response?.status !== 403) {
    swal.fire({
      title: 'An Error has occurred.',
      icon: 'error',
      text: getErrorMessage(error),
    })
  }
}

export const getErrorMessage = (error) => {
  let data = error.response?.data
  if(data && typeof data === "object") {
    data = data.message || error.message || "We encountered a service error"
  }
  return data;
}