function getValue(object, name) {
  if (!object) return null

  const [first, ...rest] = name.split('.')
  const arrayRegEx = first.match(/(.*)\[([0-9]*)\]/)
  const [original, arrayPropName, index] = arrayRegEx || []

  if (original && arrayPropName && index) {
    if (object[arrayPropName] && object[arrayPropName][index]) {
      if (rest.length) {
        return getValue(object[arrayPropName][index], rest.join('.'))
      }
      return object[arrayPropName][index]
    }
    return null
  }
  if (rest.length) {
    return getValue(object[first], rest.join('.'))
  }
  return object[first]
}

export default getValue
