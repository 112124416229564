import React, { useState, useEffect } from 'react'
import { List, Set, fromJS } from 'immutable'
import TRow from './TableRow'
import ScheduleTableBody from './ScheduleTableBody'
import ScheduleTableRow from './ScheduleTableRow'
import SelectableArea from 'components/shared/SelectableArea'
import {
  createDaysFilter,
  createHoursFilter,
  getHour,
  convertToTimes,
  processSchedule,
  createTimeRange,
  getRowColumn,
  getNewEntry,
  determineViewSettings,
  getHours,
  createIntervals,
} from './'

const DAYS = [
  { label: 'Sun', day_of_week: 1 },
  { label: 'Mon', day_of_week: 2 },
  { label: 'Tue', day_of_week: 3 },
  { label: 'Wed', day_of_week: 4 },
  { label: 'Thu', day_of_week: 5 },
  { label: 'Fri', day_of_week: 6 },
  { label: 'Sat', day_of_week: 7 },
]

const ScheduleTable = (props) => {
  const {
    schedule = [],
    days_of_operation = [],
    hours_of_operation = [{ time_start: '00:00:00', time_end: '24:00:00' }],
    selected = [],
    columns = [],
    isSelectable,
    numIntervals = 4,
    setFieldValue,
    restrictTimes = false,
    options = {},
  } = props

  const [timeSlots, setTimeSlots] = useState({})
  const [dirty, setDirty] = useState(false)
  const [height, setHeight] = useState('432px')
  const [prevSchedule, setPrevSchedule] = useState(false)
  const selectedColors = selected.map((x) => x.value)
  const [intervals] = useState(createIntervals(numIntervals))
  const [activeCells, setActiveCells] = useState(false)
  const daysFilter = createDaysFilter(days_of_operation)
  const hoursFilter = createHoursFilter(hours_of_operation, numIntervals)
  const days = daysFilter.length ? daysFilter.map((x) => DAYS[x.day_of_week - 1]) : DAYS
  const hours = getHours(intervals, hoursFilter)

  const numColumns = (columns.length || 1) * days.length

  if (columns.length == 0) {
    columns.push(1)
  }
  const tableColumns = days.reduce(
    (x, day) =>
      x.concat(
        columns.map((column, index) => {
          return {
            key: `${day.day_of_week}:${index}`,
            day_of_week: day.day_of_week,
            field: column.key,
          }
        }),
      ),
    [],
  )

  useEffect(() => {
    if (hours_of_operation.length && restrictTimes) {
      const cells = hours_of_operation.reduce((acc, x) => {
        const range = createTimeRange(x, intervals)
        range.forEach((timeslot) => {
          Array(numColumns)
            .fill()
            .forEach((c, index) => {
              acc[`${x.day_of_week}:${index}-${timeslot}`] = true
            })
        })
        return acc
      }, {})
      setActiveCells(cells)
    }
  }, [hours_of_operation, numColumns, restrictTimes])

  const update = () => {
    const timeSlotsByColor = Object.entries(timeSlots).reduce((timeslots, [timeslot, colors]) => {
      colors &&
        colors.forEach((color) => {
          timeslots[color] = (timeslots[color] || []).concat(timeslot)
        })
      return timeslots
    }, {})

    selected.forEach((option) => {
      const schedule = convertToTimes(timeSlotsByColor[option.value], intervals)
      setFieldValue(option.key, schedule)
    })
  }

  useEffect(() => {
    if (dirty) {
      update()
      setDirty(false)
    }
    determineViewSettings(setHeight, timeSlots, intervals)
  }, [timeSlots, dirty])

  useEffect(() => {
    const keys = Object.keys(timeSlots).sort()
    const firstKey = (keys[0] && keys[0].split('-')[0]) || 4 * numIntervals
    document.getElementById('schedule-table').scrollTop = firstKey * 14
  }, [])

  useEffect(() => {
    if (!prevSchedule || (prevSchedule && prevSchedule.hashCode() !== List(schedule).hashCode())) {
      const newTimeSlots = processSchedule(schedule, intervals, tableColumns, hoursFilter.time_start)
      setTimeSlots(newTimeSlots || {})
      setPrevSchedule(List(schedule))
    }
  }, [schedule])

  const handleSelection = (selectedTimeSlots) => {
    const newSelection = { ...timeSlots }
    for (let i = 0; i < selectedTimeSlots.length; i += 1) {
      const timeslot = selectedTimeSlots[i]
      newSelection[timeslot] = getNewEntry(timeSlots, timeslot, selectedColors)
    }
    setTimeSlots(newSelection)
    setDirty(true)
  }

  const handleClick = (timeSlot) => {
    const newSelection = {
      ...timeSlots,
      [timeSlot]: getNewEntry(timeSlots, timeSlot, selectedColors),
    }
    setTimeSlots(newSelection)
    setDirty(true)
  }

  return (
    <SelectableArea onSelection={handleSelection} disabled={!isSelectable}>
      <table style={{borderSpacing: 0}}>
        <thead style={{ display: 'block', width: '100%', paddingRight: '16px' }}>
          <TRow>
            <th style={{ minWidth: '50px', width: '50px', border: '2px solid white' }} />
            <th style={{ minWidth: '24px', width: '24px', border: '2px solid white' }} />
            {days.map((day) => (
              <th key={day.day_of_week} colSpan={columns.length || 1}>
                {day.label}
              </th>
            ))}
          </TRow>
          {columns && (
            <TRow>
              <th style={{ minWidth: '50px', width: '50px', border: '2px solid white' }} />
              <th style={{ minWidth: '24px', width: '24px', border: '2px solid white' }} />
              {days.map((day) =>
                columns.map((column, index) => (
                  <th
                    id={`${day.day_of_week}:${index}`}
                    key={`${day.day_of_week}:${index}`}
                    style={{
                      width: columns.length > 1 ? column.width : '60px',
                      minWidth: columns.length > 1 ? column.width : '60px',
                      fontSize: '12px',
                      fontWeight: 'normal',
                      boxSizing: 'border-box',
                      borderRight: index + 1 == columns.length ? '2px solid gray' : 'none',
                      // borderLeft: index === 0 ? '2px solid gray' : 'none',
                    }}
                  >
                    {column.label}
                  </th>
                )),
              )}
            </TRow>
          )}
        </thead>
        <ScheduleTableBody id="schedule-table" style={{ height: 12 * numIntervals * 14 + 'px' }}>
          {!hours.length && <div className="ptl text--center">Please add hours of operation for the program.</div>}
          {!!hours.length && hours.map((x, index) => (
            <ScheduleTableRow
              hoursFilter={hoursFilter}
              key={index}
              {...{
                activeCells,
                data: x.value,
                rowKey: x.key,
                rowIndex: index,
                timeSlots,
                handleClick,
                intervals,
                columns: tableColumns,
                numColumns,
              }}
            />
          ))}
        </ScheduleTableBody>
      </table>
    </SelectableArea>
  )
}

const isEqual = (prevProps, nextProps) => {
  const scheduleEqual = fromJS(nextProps.schedule || []).hashCode() === fromJS(prevProps.schedule || []).hashCode()
  const selectedEqual = fromJS(nextProps.selected || []).hashCode() === fromJS(prevProps.selected || []).hashCode()

  return scheduleEqual && selectedEqual
}

export default React.memo(ScheduleTable, isEqual)
