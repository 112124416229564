import React, { useState, useEffect, useRef } from 'react'
import { RippleButton, InputContainer, ButtonContainer, Label } from './'
import { ErrorMessage } from 'formik'
import Error from './Error'
import getValue from './getValue'

const SelectButtonInput = (props) => {
  const { name, options, label, errors, touched, disabled, required, values, children, className } = props
  const showError = errors[name] && touched[name]
  const containerRef = useRef(null)
  const [isVertical, setIsVertical] = useState(false)
  const onAnswer = (answer) => props.setFieldValue(name, answer)

  function update() {
    const width = containerRef?.current?.offsetWidth
    const buttonWidth = Array.from(containerRef?.current?.children || []).reduce((width, x) => width + x.offsetWidth, 0)
    if (width <= buttonWidth) {
      setIsVertical(true)
    } else if (width > buttonWidth) {
      setIsVertical(false)
    }
  }
  useEffect(() => {
    function requestUpdate() {
      window.requestAnimationFrame(update)
    }
    requestUpdate()
    window.addEventListener('resize', requestUpdate)
    return () => window.removeEventListener('resize', requestUpdate)
  }, [])

  const style = { flexDirection: isVertical ? 'column' : 'row' }
  const value = getValue(values, name)

  return (
    <InputContainer className={className}>
      <Label htmlFor={name}>
        {label}
      </Label>

      <input
        id={name}
        name={name}
        hidden
        value={value || ''}
        required={required || false}
        type="text"
        onChange={props.handleChange}
      />

      <ButtonContainer
        ref={containerRef}
        left
        isVertical={isVertical}
        top="0em"
        style={showError ? { border: 'thin solid red', padding: '0em 0.5em', borderRadius: '0.5em' } : {}}
      >
        {(options || []).map((option) => (
          <RippleButton
            name={option.key}
            key={option.key}
            type="button"
            disabled={disabled}
            style={{ marginTop: '5px', paddingTop: '7px', paddingBottom: '7px' }}
            className={`btn ${value === option.key ? 'btn-success' : 'btn-default'}`}
            onClick={() => onAnswer(option.key)}
          >
            {option.value}
          </RippleButton>
        ))}
      </ButtonContainer>
      <ErrorMessage name={props.name} component={Error} />
    </InputContainer>
  )
}

export default SelectButtonInput
