export const cdaOptions = [
  { key: '', value: 'Please Select' },
  { key: 'CDA - Infant Toddler', value: 'Infant / Toddler' },
  { key: 'CDA - Infant Toddler Bilingual', value: 'Infant / Toddler Bilingual' },
  { key: 'CDA - Preschool', value: 'Preschool' },
  { key: 'CDA - Preschool Bilingual', value: 'Preschool Bilingual' },
  { key: 'CDA - Family Child Care', value: 'Family Child Care' },
  { key: 'CDA - Family Child Care Bilingual', value: 'Family Child Care Bilingual' },
  { key: 'CDA - Home Visitor', value: 'Home Visitor' },
  { key: 'CDA - Home Visitor Bilingual', value: 'Home Visitor Bilingual' },
  { key: 'CDA - Bilingual', value: 'Bilingual' },
]
