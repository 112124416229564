import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'

const Header = styled.div`
  position: fixed;
  top: ${(props) => props.top}px;
  z-index: 999;
  width: 100%;
  height: ${(props) => props.height}px;
  left: 0px;
  background-color: #fefefe;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
`

const StickyHeader = (props) => {
  const [scrollTop, setScrollTop] = useState(0)
  const { scrollStart = 0, height = 0, top = 0, children } = props
  useEffect(
    () => {
      const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop)
      }
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    },
    [scrollTop],
  )

  useEffect(() => {
    setScrollTop(document.scrollingElement.scrollTop)
  }, [])

  if (scrollTop > scrollStart) {
    return (
      <Fragment>
        <Header height={height} top={top}>
          {children({ scrollTop })}
        </Header>
        <div name="spacer" style={{ height }} />
      </Fragment>
    )
  } else {
    return <div>{children({ scrollTop })}</div>
  }
}

export default StickyHeader
