import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const TabContainer = styled.div`
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

const Tab = (props) => {
  const { tab, active, external } = props
  const { key, name, url } = tab
  if (external) {
    return (
      <li key={key} className={active ? 'active' : ''}>
        <a href={url}>{name}</a>
      </li>
    )
  }
  return (
    <li key={key} className={active ? 'active' : ''}>
      <Link className="btn btn-ripple" to={url}>
        {name}
      </Link>
    </li>
  )
}

const stickyStyle = { paddingTop: 5, background: 'white', position: 'sticky', top: '50px', zIndex: 999 }
const nestedStyle = { paddingTop: 0, borderRadius: '0', borderTop: '#1e242b solid thin' }
const ulNestedStyle = { borderTopLeftRadius: '0', borderTopRightRadius: '0' }
const TabNavRouter = (props) => {
  const { active = '', tabs, sticky = false, nested = false, external } = props
  let style = sticky ? stickyStyle : {}
  style = nested ? { ...style, ...nestedStyle } : style
  return (
    <TabContainer className="tab-container" style={style}>
      <ul
        className="profile-tabs nav nav-pills"
        style={nested ? ulNestedStyle : {}}
        // style={{ borderRadius: '0em', boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)' }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            external={tab.external}
            active={
              active
              && tab.key
              && active.trim().toLowerCase() === tab.key.trim().toLowerCase()
            }
            tab={tab}
          />
        ))}
      </ul>
    </TabContainer>
  )
}

export default TabNavRouter
