import React from 'react'
import { connect } from 'react-redux'
import { in_ratio } from 'stores/evaluations'

const InRatio = (props) => {
  const {
    name,
    erc_employee_id,
    isChecked,
    isEditable,
    isLicensing,
    isSUTQ,
    updateFormData,
    updatedEvaluations,
  } = props

  const updatedValue = updatedEvaluations?.[erc_employee_id]?.licensing?.staff_requirements?.ratio?.[name]
    || updatedEvaluations?.[erc_employee_id]?.sutq?.sutq?.accounting?.[name]
  const checked = updatedValue !== undefined ? updatedValue : isChecked

  const onClick = () => {
    const updates = {}
    if(isLicensing) {
      updates["licensing.staff_requirements.ratio."+name] = !Boolean(Number(checked))
    }
    if(isSUTQ) {
      updates["sutq.sutq.accounting."+name] = !Boolean(Number(checked))
    }

    updateFormData({
      erc_employee_id,
      updates
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <form>
        <input
          type="checkbox"
          name={name}
          onChange={onClick}
          disabled={!isEditable}
          checked={Boolean(Number(checked))}
        />
      </form>
    </div>
  )
}

export default connect(
  (state, props) => ({
    isChecked: in_ratio(state, props),
  })
)(InRatio)
