import { ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { map, mergeMap, catchError } from 'rxjs/operators'
import axios from 'axios'

import { getEmployeeEvaluationsSuccess, putEmployeeEvaluationsSuccess } from './actions'
import { GET_EMPLOYEE_EVALUATIONS, PUT_EMPLOYEE_EVALUATIONS } from './actionTypes'
import apiUrls from "constants/apiUrls"
import { getErrorMessage } from "utils/serviceErrorHandler";

const mapper = (fn) => ({ data }) => fn(data)
const errorHandler = (actionType) => (error) => {
  return of({
    type: actionType,
    payload: getErrorMessage(error),
    error: error,
  })
}

export default (action$) =>
  merge(
    action$.pipe(
      ofType(GET_EMPLOYEE_EVALUATIONS),
      mergeMap((action) => {
        const { erc_id, erc_employee_id } = action.payload
        return from(axios.get(`${apiUrls.evaluations}/${erc_id}/${erc_employee_id}`)).pipe(
          map(mapper(getEmployeeEvaluationsSuccess)),
          catchError(errorHandler(`${action.type}_FAILURE`)),
        )
      }),
    ),
    action$.pipe(
      ofType(PUT_EMPLOYEE_EVALUATIONS),
      mergeMap((action) =>
        from(axios.put(apiUrls.evaluations, { ...action.payload })).pipe(
          map(mapper(putEmployeeEvaluationsSuccess)),
          catchError(errorHandler(`${action.type}_FAILURE`)),
        ),
      ),
    ),
  )
