import React, { useEffect, Fragment } from 'react'
import { withFormik, Form, ErrorMessage } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimesCircle, faTrashCan, faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  SelectInput,
  ButtonContainer,
  Input,
  FormContainer,
  DateInput,
  TimeInput,
  Checkbox,
  Error,
  InputContainer,
} from 'components/shared/Forms'
import { Row, SectionHeader } from 'components/shared/Layout'
import AdministratorsForm from './AdministratorsForm'
import apiUrls from "constants/apiUrls";

const styles = {
  dayOfWeek: (sel) => ({
    padding: '0 15px 15px 15px',
    marginBottom: sel ? 15 : 0,
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: sel ? '#ccc' : 'white',
  }),
  removeHours: {
    float: 'right',
    marginRight: -15,
    backgroundColor: "#efefef",
  },
  selectAllWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '2em'
  },
  selectAllLabel: {
    marginBottom: 0,
    marginLeft: '.5em'
  },
  flexDisplay: {
    display: 'flex',
    flexWrap: "wrap",
  },
  flexWrapWrap: {
    flexWrap: "wrap"
  },
  removeShiftWrapper: {
    justifyContent: "center"
  },
  removeShiftButton: (disabled) => ({
    marginTop: "1.5em",
    cursor: (disabled ? "not-allowed" : "pointer"),
    opacity: (disabled ? "0.6" : "1")
  }),
  addShiftWrapper: {
    alignSelf: "flex-end",
    marginBottom: "0.5em"
  },
  addShiftButton: {
    marginRight: "0.5em"
  }
}

const MonthOptions = () => (
  moment.months().map((x, index) => (
    <option key={index} value={index + 1}>
      {x}
    </option>
  ))
)

const emptyShift = { time_start: '', time_end: '' };
const hoursDefault = { shifts: [{ ...emptyShift }, { ...emptyShift }] }
const daysOfWeek = moment.weekdays()
const compareDays = (a, b) => daysOfWeek.reduce((result, day) => result && a[day] === b[day], true)
const TIME_START_24 = "00:00:00", TIME_END_24 = "23:59:00";
const isAllDay = (shift) => (shift.time_start === TIME_START_24 && shift.time_end === TIME_END_24)
const addMinutes = (startTime, minutesToAdd) => {
  if(startTime) {
    const date = new Date()
    const hms = startTime.split(":")
    date.setHours(hms[0])
    date.setMinutes(hms[1])
    date.addMinutes(minutesToAdd !== undefined ? minutesToAdd : 1)
    return `${date.getHours()}:${date.getMinutes()}:00`
  }
}

const InspectionForm = (props) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    cancel,
    setFieldValue,
    validateForm,
    inspection,
    isEditable,
    isLicensing,
    isProgramCenter,
    isSUTQ,
    isLoading,
  } = props

  const isFinalized = inspection.completed_by

  useEffect(() => {
    validateForm()
  }, [])

  const handleAdminChange = (e) => {
    const dateName = e.target.name.replace('employee_id', 'date_training')
    setFieldValue(dateName, '')
    handleChange(e)
  }

  const isAdminSelected = (id) => {
    return values.administrators.some((admin) => admin.employee_id === id)
  }

  const adminOptions = props.employees.map((employee) => (
    <option
      key={employee.id}
      disabled={isAdminSelected(employee.id)}
      value={employee.id}
    >{`${employee.first_name} ${employee.last_name}`}</option>
  ))

  const handleCheckAllDays = (index) => (e) => {
    daysOfWeek.forEach((day) => {
      setFieldValue(`hours_of_operation[${index}].${day}`, e.target.checked)
    })
  }

  const handleCheckAllWeekdays = (index) => (e) => {
    daysOfWeek.slice(1, 6).forEach((day) => {
      setFieldValue(`hours_of_operation[${index}].${day}`, e.target.checked)
    })
  }

  const handleCheckAllAgeGroups = (e) => {
    const initialValue = Object.values(values.program_age_groups).every((x) => x)
    Object.keys(values.program_age_groups).forEach((key) => {
      setFieldValue(`program_age_groups.${key}`, !initialValue)
    })
  }

  const handleAllDaySelect = (e, index) => {
    let time_start = "", time_end = "";
    if(e.target.checked) {
      time_start = TIME_START_24;
      time_end = TIME_END_24;
    }
    props.handleChange && props.handleChange(e);
    setFieldValue(
      `hours_of_operation[${index}].shifts`,
      [{time_start, time_end}]
    );
  }

  const SelectAll = ({ checked, onChange, label, name }) => (
    <div style={styles.selectAllWrapper}>
      <input
        disabled={!isEditable}
        checked={checked}
        type="checkbox"
        name={name}
        onChange={onChange}
        style={{ marginTop: '1em', marginBottom: '1em' }}
      />
      <label htmlFor={name} style={styles.selectAllLabel}>
        {label}
      </label>
    </div>
  )

  const hasAllWeekdays = (value) => daysOfWeek.slice(1, 6).every((d) => value[d])
  const hasAllDaysOfWeek = (value) => daysOfWeek.every((d) => value[d])

  values.program_age_groups.all = Object.entries(values.program_age_groups)
    .filter(([key, value]) => key !== 'all')
    .every(([key, value]) => value)

  const addAdditionalHours = () => {
    setFieldValue(`hours_of_operation[${values.hours_of_operation.length}]`, hoursDefault)
  }

  const addShift = (index) => {
    const currentShifts = values.hours_of_operation[index].shifts;
    setFieldValue(
      `hours_of_operation[${index}].shifts`,
      [
        ...currentShifts,
        { ...emptyShift },
      ]
    );
  }

  const removeShift = (hoursIndex, shiftIndex) => {
    const currentShifts = values.hours_of_operation[hoursIndex].shifts;
    setFieldValue(
      `hours_of_operation[${hoursIndex}].shifts`,
      [
        ...currentShifts.slice(0, shiftIndex),
        ...currentShifts.slice(shiftIndex + 1, currentShifts.length),
      ]
    );
  }

  const removeAdditionalHours = (row) => () => {
    setFieldValue(
      'hours_of_operation',
      values.hours_of_operation.filter((value, index) => index !== row),
    )
  }

  return (
    <FormContainer className="container">
      <Form autoComplete={'off'}>
        <input type="hidden" id="course_number" name="course_number" value={values.course_number} />
        <Row>
          <Input name="program_name" label="Program Name" className="col-sm-6" disabled {...props} />
          <Input name="license_number" label="Program Number" className="col-sm-6" disabled {...props} />
        </Row>
        {isLicensing && (
          <Row>
            <DateInput
              name="licensing_start_date"
              label="Licensing Inspection Start Date"
              className="col-sm-6"
              bottom
              maxDate={new Date()}
              disabled={!isEditable}
              {...props}
            />
            <Input
              name="licensing_inspection_number"
              label="Licensing Inspection Number"
              className="col-sm-6"
              disabled={!isEditable}
              {...props}
            />
          </Row>
        )}
        {isSUTQ && (
          <Fragment>
            <Row>
              <DateInput
                name="sutq_registration_submission_date"
                label="SUTQ Registration Submission Date"
                className="col-sm-6"
                bottom
                maxDate={new Date()}
                disabled={!isEditable}
                {...props}
              />
              <Input
                name="sutq_transaction_number"
                label="SUTQ Transaction Number"
                className="col-sm-6"
                disabled={!isEditable}
                {...props}
              />
            </Row>
            <Row>
              <DateInput
                name="sutq_onsite_start_date"
                label="SUTQ Onsite Start Date"
                className="col-sm-6"
                bottom
                maxDate={new Date()}
                disabled={!isEditable}
                {...props}
              />
              <Input
                name="sutq_onsite_inspection_number"
                label="SUTQ Onsite Inspection Number"
                className="col-sm-6"
                disabled={!isEditable}
                {...props}
              />
            </Row>
          </Fragment>
        )}

        <Row>
          <Input name="completed_by_name" label="Completed By" disabled className="col-sm-4" {...props} />
          <Input name="contributors" label="Specialists" className="col-sm-8" disabled {...props} />
        </Row>
        <SectionHeader>Hours Of Operation</SectionHeader>
        {values.hours_of_operation.length === 0 && (
          <ErrorMessage name="hours_of_operation" component={Error} {...props} />
        )}
        {values.hours_of_operation.map((row, index) => {
          const baseName = `hours_of_operation[${index}]`

          return (
            <div key={index} className="push-down-20">
              <div style={styles.dayOfWeek(true)}>
                {!!isEditable && values.hours_of_operation.length > 1 && (
                  <button
                    type="button"
                    className="btn btn-default"
                    style={styles.removeHours}
                    onClick={removeAdditionalHours(index)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} color={"darkred"} size={"sm"}/>
                  </button>
                )}
                <div style={styles.flexDisplay}>
                  {daysOfWeek.map((day) => (
                    <Checkbox key={day} disabled={!isEditable} name={`${baseName}.${day}`} label={day} {...props} />
                  ))}
                  <SelectAll
                    checked={hasAllWeekdays(row)}
                    name={`${baseName}.weekdays`}
                    onChange={handleCheckAllWeekdays(index)}
                    label="Weekdays"
                  />
                  <SelectAll
                    checked={hasAllDaysOfWeek(row)}
                    name={`${baseName}.alldays`}
                    onChange={handleCheckAllDays(index)}
                    label="All"
                  />
                </div>
                <Row>
                  <div className="col-sm-1">
                    <InputContainer>
                      <label htmlFor={`${baseName}.allDay`}>24 Hour</label>
                      <Checkbox
                        disabled={!isEditable}
                        checked={`${baseName}.allDay`}
                        name={`${baseName}.allDay`}
                        {...props}
                        handleChange={e => handleAllDaySelect(e, index)}
                      />
                    </InputContainer>
                  </div>
                  <div className="col-sm-11">
                    <Row style={{...styles.flexDisplay, ...styles.flexWrapWrap}}>
                      {
                        row.shifts && row.shifts.map((shift, shiftIndex) => (
                          <div className="col-sm-6" key={`shift-${shiftIndex}`}>
                            <Row style={styles.flexDisplay}>
                              <TimeInput
                                name={`${baseName}.shifts[${shiftIndex}].time_start`}
                                label="From"
                                className="col-sm-5"
                                disabled={
                                  !isEditable || !!row.allDay ||
                                  (shiftIndex && !(row.shifts[shiftIndex - 1].time_start && row.shifts[shiftIndex - 1].time_end))
                                }
                                timeIntervals={15}
                                minTime={row.shifts[shiftIndex - 1] ? row.shifts[shiftIndex - 1].time_end : undefined}
                                maxTime={addMinutes(shift.time_end, -1)}
                                {...props}
                              />
                              <TimeInput
                                name={`${baseName}.shifts[${shiftIndex}].time_end`}
                                label="To"
                                className="col-sm-5"
                                disabled={
                                  !isEditable || !!row.allDay ||
                                  (shiftIndex && !(row.shifts[shiftIndex - 1].time_start && row.shifts[shiftIndex - 1].time_end))
                                }
                                timeIntervals={15}
                                minTime={addMinutes(shift.time_start, 1)}
                                maxTime={row.shifts[shiftIndex + 1] ? row.shifts[shiftIndex + 1].time_start : undefined}
                                {...props}
                              />
                              {
                                !!isEditable &&
                                <InputContainer className="col-sm-2" style={styles.removeShiftWrapper}>
                                  <span onClick={row.shifts.length > 1 ? (() => removeShift(index, shiftIndex)) : undefined}>
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      color={"darkred"}
                                      size={"lg"}
                                      style={styles.removeShiftButton(row.shifts.length <= 1)}
                                    />
                                  </span>
                                </InputContainer>
                              }
                            </Row>
                          </div>
                        ))
                      }
                      {
                        !!isEditable &&
                        <div className="col-sm-6" style={styles.addShiftWrapper}>
                          <button
                            style={styles.addShiftButton}
                            type="button"
                            name={`${baseName}.moreShifts`}
                            className="btn btn-primary btn-sm"
                            onClick={() => addShift(index)}
                            disabled={!!row.allDay}
                          >
                            <FontAwesomeIcon icon={faPlus}/>
                          </button>
                          <label htmlFor={`${baseName}.moreShifts`}>Add Shift</label>
                        </div>
                      }
                    </Row>
                  </div>
                </Row>
                <ErrorMessage name={`${baseName}`} component={Error} {...props} />
              </div>
            </div>
          )
        })}
        {
          !!isEditable &&
          <button type="button" className="btn btn-primary btn-sm" onClick={addAdditionalHours}>
            Add Additional Days
          </button>
        }
        <SectionHeader>Months Of Operation</SectionHeader>
        <Row>
          <SelectInput
            name="months_of_operation.month_start"
            label="From"
            className="col-sm-6"
            disabled={!isEditable}
            required={true}
            {...props}
          >
            <option value="0" />
            <MonthOptions />
          </SelectInput>
          <SelectInput
            name="months_of_operation.month_end"
            label="To"
            className="col-sm-6"
            disabled={!isEditable}
            required={true}
            {...props}
          >
            <option value="0" />
            <MonthOptions />
          </SelectInput>
        </Row>
        <SectionHeader>Age Groups Served</SectionHeader>
        <div style={styles.flexDisplay}>
          <Checkbox
            disabled={!isEditable}
            name="program_age_groups.all"
            label="All"
            {...props}
            handleChange={handleCheckAllAgeGroups}
          />
          <Checkbox disabled={!isEditable} name="program_age_groups.infants" label="Infants" {...props} />
          <Checkbox disabled={!isEditable} name="program_age_groups.toddlers" label="Toddlers" {...props} />
          <Checkbox disabled={!isEditable} name="program_age_groups.preschool" label="Preschool" {...props} />
          <Checkbox disabled={!isEditable} name="program_age_groups.school_age" label="School Age" {...props} />
        </div>

        <AdministratorsForm {...{ ...props, isEditable, adminOptions, handleAdminChange }} />

        {isEditable && (
          <Row className="push-down-20">
            <ButtonContainer className="col-sm-12">
              {!isFinalized && (
                <Fragment>
                  <button
                    type="submit"
                    className={`btn ${Object.values(errors).length === 0 ? 'btn-success' : 'btn-warning'}`}
                    onClick={handleSubmit}
                    disabled={!!isLoading}
                    style={{minWidth: "4em"}}
                  >
                    {!isLoading ? "Save" : <FontAwesomeIcon icon={faSpinner} spin />}
                  </button>
                  <button type="button" className="btn" onClick={cancel}>
                    Cancel
                  </button>
                </Fragment>
              )}
            </ButtonContainer>
          </Row>
        )}
      </Form>
    </FormContainer>
  )
}

const padInspection = (value, padLength) => {
  return value ? value.padStart(padLength, '0') : value
}

const checkDuplicateId = (ercId, key) => (value) => {
  if (!value) {
    return true
  }
  const paddedValue = padInspection(value, key === 'sutq_transaction_number' ? 7 : 6)
  return new Promise((resolve) => {
    axios
      .get(`${apiUrls.checkId}?erc_id=${ercId}&key=${key}&value=${paddedValue}`)
      .then(({ data }) => resolve(!data))
      .catch(() => resolve(false))
  })
}

const hasTimeOverlap = (shift1 = {}, shift2 = {}) => {
  const day1Start = new Date();
  const day2Start = new Date();
  const endOfEarlier = new Date();

  if(
    shift1.time_start && shift1.time_end &&
    shift2.time_start && shift2.time_end
  ) {
    day1Start.setHours(shift1.time_start.substring(0,2));
    day1Start.setMinutes(shift1.time_start.substring(3,5));
    day1Start.setSeconds(0);

    day2Start.setHours(shift2.time_start.substring(0,2));
    day2Start.setMinutes(shift2.time_start.substring(3,5));
    day2Start.setSeconds(0);

    const startOfLater = day2Start > day1Start ? day2Start : day1Start;
    const earlierShift = day2Start > day1Start ? shift1 : shift2;
    endOfEarlier.setHours(earlierShift.time_end.substring(0,2));
    endOfEarlier.setMinutes(earlierShift.time_end.substring(3,5));
    endOfEarlier.setSeconds(0);

    return startOfLater < endOfEarlier;
  }
  return false;
}

const isInValidTimeInterval = (time_start, time_end) => {
  if(!time_start && !time_end) {
    return false;
  }
  if(!time_start || !time_end) {
    return true;
  }
  const time1 = new Date();
  const time2 = new Date();
  time1.setHours(time_start.substring(0,2));
  time1.setMinutes(time_start.substring(3,5));
  time1.setSeconds(0);
  time2.setHours(time_end.substring(0,2));
  time2.setMinutes(time_end.substring(3,5));
  time2.setSeconds(0);
  return time_start >= time_end;
}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  handleSubmit: (values, { setSubmitting, props }) => {
    const hours_of_operation = []
    values.hours_of_operation.forEach((row) => {
      daysOfWeek.forEach((day, dayIndex) => {
        const day_of_week = dayIndex + 1
        if (row[day]) {
          row.shifts.forEach(({ time_start, time_end }, shift_order) => {
            if (time_start && time_end) {
              hours_of_operation.push({ day_of_week, time_start, time_end, shift_order })
            }
          })
        }
      })
    })
    props.submit({
      ...values,
      licensing_inspection_number: padInspection(values.licensing_inspection_number, 6),
      sutq_transaction_number: padInspection(values.sutq_transaction_number, 7),
      sutq_onsite_inspection_number: padInspection(values.sutq_onsite_inspection_number, 6),
      hours_of_operation,
    })
    setSubmitting(false)
    return values
  },
  mapPropsToValues: ({ inspection }) => {
    const administrators = Array(3)
    administrators.fill({
      employee_id: '',
      date_training: '',
    });
    (inspection.administrators || []).forEach(({ admin_order, employee_id, date_training = '' }) => {
      administrators[admin_order] = {
        employee_id,
        date_training,
      }
    })

    const groupByShift = {};
    (inspection.hours_of_operation || []).forEach(({ time_start, time_end, day_of_week }) => {
      const timeKey = `${time_start}-${time_end}`
      if (!groupByShift[timeKey]) {
        groupByShift[timeKey] = { shifts: [{ time_start, time_end }] }
      }
      groupByShift[timeKey][daysOfWeek[day_of_week - 1]] = true
    })

    const arrayOfHours = (Object.entries(groupByShift).map(([, v]) => v) || [hoursDefault]).sort(
      (a, b) => a.shifts[0].time_start - b.shifts[0].time_start,
    )

    const hours_of_operation = []
    arrayOfHours.forEach((row) => {
      const match = hours_of_operation.find((h) => compareDays(h, row))
      const allDay = row.shifts && row.shifts[0] && isAllDay(row.shifts[0]);
      if (match) {
        match.allDay = allDay;
        match.shifts.push(row.shifts[0])
      } else {
        row.allDay = allDay;
        hours_of_operation.push(row)
      }
    })

    return {
      course_number: '',
      months_of_operation: { month_start: 0, month_end: 0 },
      program_name: '',
      licensing_start_date: '',
      licensing_inspection_number: '',
      sutq_registration_submission_date: '',
      sutq_transaction_number: '',
      sutq_onsite_start_date: '',
      sutq_onsite_inspection_number: '',
      completed_by: '',
      ...Object.entries(inspection).reduce((a, [k, v]) => (v === null ? a : { ...a, [k]: v }), {}),
      hours_of_operation,
      program_age_groups: {
        infants: false,
        toddlers: false,
        preschool: false,
        school_age: false,
        ...inspection.program_age_groups,
        all: inspection.program_age_groups && Object.values(inspection.program_age_groups).every((x) => x),
      },
      contributors: inspection.contributors
        ? inspection.contributors.map((x) => `${x.first_name} ${x.last_name}`).join(', ')
        : '',
      administrators,
    }
  },
  validationSchema: (props) => {
    const ercId = props.inspection.id
    return Yup.object().shape({
      licensing_inspection_number: Yup.string()
        .nullable()
        .matches(/^[0-9]*$/, 'Must be numeric')
        .max(6, 'Only 6 numbers allowed')
        .test(
          'checkDuplicates',
          'OCLQS Id is already used in another ERC',
          checkDuplicateId(ercId, 'licensing_inspection_number'),
        ),
      sutq_transaction_number: Yup.string()
        .nullable()
        .matches(/^[0-9]*$/, 'Must be numeric')
        .max(7, 'Only 7 numbers allowed')
        .test(
          'checkDuplicates',
          'OCLQS Id is already used in another ERC',
          checkDuplicateId(ercId, 'sutq_transaction_number'),
        ),
      sutq_onsite_inspection_number: Yup.string()
        .nullable()
        .matches(/^[0-9]*$/, 'Must be numeric')
        .max(6, 'Only 6 numbers allowed')
        .test(
          'checkDuplicates',
          'OCLQS Id is already used in another ERC',
          checkDuplicateId(ercId, 'sutq_onsite_inspection_number'),
        ),
      hours_of_operation: Yup.array()
        .min(1, 'At least one set of hours is required')
        .of(
          Yup.object()
            .test('hasDay', 'At least one day must be selected', (value) =>
              daysOfWeek.reduce((r, day) => r || value[day], false)
            )
            .test('shift1', 'From and To are required', ({ shifts }) => shifts[0].time_start && shifts[0].time_end)
            .test('shift2To', 'To is required', ({ shifts }) =>
              (shifts[1] || {}).time_start ? !!shifts[1].time_end : true
            )
            .test('shift2From', 'From is required', ({ shifts }) =>
              (shifts[1] || {}).time_end ? !!shifts[1].time_start : true
            )
            .test(
              "validInterval",
              "Invalid time interval",
              ({shifts}) => !shifts.find(
                s => isInValidTimeInterval(s.time_start, s.time_end)
              )
            )
            .test("overlap", "Shifts should not overlap", function (value = {}) {
              const context = this; // can only access 'this' context when not using arrow function
              const { parent = [], path = "" } = context;
              const { shifts = [] } = value;
              const indexStr = path.match(/[0-9]+/);
              const currentIndex = indexStr && Number(indexStr);
              const selectedWeekdays = daysOfWeek.filter(day => value[day] === true);
              if(
                parent.length &&
                shifts.length &&
                selectedWeekdays.length &&
                !Number.isNaN(currentIndex)
              ) {
                const match = parent.filter((row, index) => (
                  selectedWeekdays.filter(weekday => !!row[weekday]).length && index !== currentIndex
                ));
                if(match && match.length) {
                  const allShifts = match.map(matchRow => matchRow.shifts).flat();
                  const overlap = shifts.filter(
                    shift => !!allShifts.find(matchShift => hasTimeOverlap(shift, matchShift))
                  );
                  return !overlap.length;
                }
              }
              return true;
            }),
        ),
      months_of_operation: Yup.object().shape({
        month_start: Yup.number().test('mustExistIfEnd', 'Must include a starting month', function(value) {
          const { month_end } = this.parent
          return !month_end || (!!month_end && !!value)
        }),
        month_end: Yup.number().test('mustExistIfStart', 'Must include an ending month', function(value) {
          const { month_start } = this.parent
          return !month_start || (!!month_start && !!value)
        }),
      }),
    })
  },
  displayName: 'ProgramInformationForm',
})

export default formikEnhancer(InspectionForm)