import styled from 'styled-components'

export default styled.h4`
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #1e242b;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  margin: 0 0 0.4em;
  margin-top: ${(props) => (props.noMarginTop ? '0em' : '2em')};
  padding: 0;
  ${(props) => (props.isInRow ? 'padding-left: 15px;' : '')}
  color: #595959;
  position: relative;
  text-transform: none;
  word-wrap: break-word;
`
