import swal from 'sweetalert2'

export default (title, confirmText) =>
  swal.fire({
    title,
    icon: 'error',
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: confirmText || 'Remove',
    customClass: {
      confirmButton: 'btn btn-danger margin1em',
      cancelButton: 'btn btn-default margin1em',
    },
    buttonsStyling: false,
    reverseButtons: true,
  })
