import { fromJS } from 'immutable'

const getEvaluationOfType = (state, props, key) => {
  const { erc_employee_id } = props
  const evaluation = state.evaluations[erc_employee_id]
  if (!evaluation) {
    return false
  }
  if ('evaluation_types' in evaluation) {
    const data = evaluation.evaluation_types[key]
    return data || {}
  }
  return false
}

export const getSutqEvaluation = (state, props) => getEvaluationOfType(state, props, 'sutq')

export const getLicensingEvaluation = (state, props) => getEvaluationOfType(state, props, 'licensing')

export const isLoading = (state) => state.evaluations.isLoading

export const counts_for = (state, props) => {
  const employee_eval = state.evaluations[props.erc_employee_id]

  return (
    (employee_eval && fromJS(employee_eval.evaluations).getIn(`sutq.sutq.accounting.${props.name}`.split('.'))) ||
    (!employee_eval && props.value)
  )
}

export const in_ratio = (state, props) => {
  const employee_eval = state.evaluations[props.erc_employee_id]

  return (
    (employee_eval && fromJS(employee_eval.evaluations).getIn(`licensing.staff_requirements.ratio.${props.name}`.split('.'))) ||
    (employee_eval && fromJS(employee_eval.evaluations).getIn(`sutq.sutq.accounting.${props.name}`.split('.'))) ||
    (!employee_eval && props.value)
  )
}
