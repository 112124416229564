import React, { useState, useEffect } from 'react'
import { withFormik, Form } from 'formik'
import * as Yup from 'yup'
import { fromJS } from 'immutable'
import moment from 'moment'
import {
  Input,
  FormContainer,
  DateInput,
  RippleButton,
  Checkbox,
} from 'components/shared/Forms'
import { LoaderContainer } from 'components/shared/Loader'
import { Row, SectionHeader } from 'components/shared/Layout'
import {packageEvaluations} from 'utils/evaluationUtils'

import { ComplianceField } from 'components/shared/Forms/ComplianceField'
import { DateStaleIndicator } from 'components/shared/DateStaleIndicator'
import { COMPLIANCE_CODES, INVALID_COMPLIANCE_DATE_COMBO_MESSAGE } from 'constants'

const TrainingForm = (props) => {
  const {
    values,
    dirty,
    handleSubmit,
    isSubmitting,
    isEditable,
    validateForm,
    /* and other goodies */
  } = props

  useEffect(() => {
    validateForm()
    return () => dirty && !isSubmitting && handleSubmit()
  }, [])

  const valuesMap = fromJS(values || {})
  const getValue = (key) => valuesMap.getIn(key.split('.'))
  const isOther = (key) => getValue(key) == 'other'

  const isLicensingPdComplianceOther = isOther('training.licensing.compliance_status')
  const isTrainingOrientationComplianceOther = isOther('training.orientation.compliance_status')
  const isFirstAidComplianceOther = isOther('training.first_aid.compliance_status')
  const isTrainingCprComplianceOther = isOther('training.cpr.compliance_status')
  const isTrainingMcdComplianceOther = isOther('training.mcd.compliance_status')
  const isCan63ComplianceOther = isOther('training.can63.compliance_status')
  const isCan1ComplianceOther = isOther('training.can1.compliance_status')

  const firstAidDateCompleted = getValue('training.first_aid.date_completed');
  const cprDateCompleted = getValue('training.cpr.date_completed');
  const mcdDateCompleted = getValue('training.mcd.date_completed');
  const can63DateCompleted = getValue('training.can63.date_completed');
  const can1DateCompleted = getValue('training.can1.date_completed');

  const shouldConfirmDateChange = (date, field) => {
    const complianceValue = getValue(field + ".compliance_status")
    const isFutureDate = moment(date) > moment()
    return complianceValue === COMPLIANCE_CODES.OUT_OF_COMPLIANCE && isFutureDate
  }

  const shouldConfirmComplianceChange = (value, field) => {
    const expiration = getValue(field + ".expiration_date");
    const isFutureDate = moment(expiration) > moment();
    return value === COMPLIANCE_CODES.OUT_OF_COMPLIANCE && isFutureDate;
  }

  return (
    <FormContainer>
      <Form autoComplete={'off'}>
        <Row>
          <SectionHeader isInRow noMarginTop>
            <div className="flex__row">
              <div className="flex--auto">Training</div>
              {isEditable && (
                <div className="flex--nogrow">
                  <RippleButton type="submit" className="btn btn-warning">
                    Save
                  </RippleButton>
                </div>
              )}
            </div>
          </SectionHeader>
        </Row>
        <Row>
          <Checkbox
            label="Used in Ratio"
            name="staff_requirements.ratio.used_in_ratio"
            {...props}
            className="col-sm-12"
            disabled={!isEditable}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>First Role Date</SectionHeader>
          <DateInput
            name="training.user.hire_date"
            className="col-sm-4 padding"
            bottom={true}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Staff Orientation</SectionHeader>
          <DateInput
            name="training.orientation.date_completed"
            label="Date Completed"
            className="col-sm-4 padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <ComplianceField
            stem="training.orientation"
            className="col-sm-4 padding"
            showOther={isTrainingOrientationComplianceOther}
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>First Aid</SectionHeader>
          <div className="padding">
            Training Date: {
              firstAidDateCompleted && moment(firstAidDateCompleted).isValid()
              ? moment(firstAidDateCompleted).format("MM/DD/YYYY")
              : "-"
          }
          </div>
          <DateStaleIndicator date={getValue('training.first_aid.expiration_date')} expireNum="0">
            {({ expired }) => (
              <DateInput
                name="training.first_aid.expiration_date"
                label="Expiration Date"
                className="col-sm-4 padding"
                bottom={true}
                disabled={!isEditable}
                expired={expired}
                shouldConfirmChange={(date) => shouldConfirmDateChange(date, "training.first_aid")}
                confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
                {...props}
              />
            )}
          </DateStaleIndicator>
          <ComplianceField
            stem="training.first_aid"
            label="Compliance Code"
            className="col-sm-4 padding"
            showOther={isFirstAidComplianceOther}
            disabled={!isEditable}
            shouldConfirmChange={(date) => shouldConfirmComplianceChange(date, "training.first_aid")}
            confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>CPR</SectionHeader>
          <div className="padding">
            Training Date: {
              cprDateCompleted && moment(cprDateCompleted).isValid()
              ? moment(cprDateCompleted).format("MM/DD/YYYY")
              : "-"
            }
          </div>
          <DateStaleIndicator date={getValue('training.cpr.expiration_date')} expireNum="0">
            {({ expired }) => (
              <DateInput
                name="training.cpr.expiration_date"
                label="Expiration Date"
                className="col-sm-4 padding"
                bottom={true}
                expired={expired}
                disabled={!isEditable}
                shouldConfirmChange={(date) => shouldConfirmDateChange(date, "training.cpr")}
                confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
                {...props}
              />
            )}
          </DateStaleIndicator>
          <ComplianceField
            stem="training.cpr"
            label="Compliance Code"
            className="col-sm-4 padding"
            showOther={isTrainingCprComplianceOther}
            disabled={!isEditable}
            shouldConfirmChange={(date) => shouldConfirmComplianceChange(date, "training.cpr")}
            confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>MCD (Management of Communicable Disease)</SectionHeader>
          <div className="padding">
            Training Date: {
              mcdDateCompleted && moment(mcdDateCompleted).isValid()
              ? moment(mcdDateCompleted).format("MM/DD/YYYY")
              : "-"
            }
          </div>
          <DateStaleIndicator date={getValue('training.mcd.expiration_date')} expireNum="0">
            {({ expired }) => (
              <DateInput
                name="training.mcd.expiration_date"
                label="Expiration Date"
                className="col-sm-4 padding"
                bottom={true}
                disabled={!isEditable}
                expired={expired}
                shouldConfirmChange={(date) => shouldConfirmDateChange(date, "training.mcd")}
                confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
                {...props}
              />
            )}
          </DateStaleIndicator>

          <ComplianceField
            stem="training.mcd"
            label="Compliance Code"
            className="col-sm-4 padding"
            disabled={!isEditable}
            showOther={isTrainingMcdComplianceOther}
            shouldConfirmChange={(date) => shouldConfirmComplianceChange(date, "training.mcd")}
            confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>CA/N 6/3 </SectionHeader>
          <div className="padding">
            Training Date: {
              can63DateCompleted && moment(can63DateCompleted).isValid()
              ? moment(can63DateCompleted).format("MM/DD/YYYY")
              : "-"
          }
          </div>
          <DateStaleIndicator date={getValue('training.can63.expiration_date')} expireNum="0">
            {({ expired }) => (
              <DateInput
                name="training.can63.expiration_date"
                label="Expiration Date"
                className="col-sm-4 padding"
                bottom={true}
                disabled={!isEditable}
                expired={expired}
                shouldConfirmChange={(date) => shouldConfirmDateChange(date, "training.can63")}
                confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
                {...props}
              />
            )}
          </DateStaleIndicator>

          <ComplianceField
            stem="training.can63"
            label="Compliance Code"
            className="col-sm-4 padding"
            disabled={!isEditable}
            showOther={isCan63ComplianceOther}
            shouldConfirmChange={(date) => shouldConfirmComplianceChange(date, "training.can63")}
            confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>CA 1 Hour </SectionHeader>
          <div className="padding">
            Training Date: {
              can1DateCompleted && moment(can1DateCompleted).isValid()
              ? moment(can1DateCompleted).format("MM/DD/YYYY")
              : "-"
          }
          </div>
          <DateStaleIndicator date={getValue('training.can1.expiration_date')} expireNum="0">
            {({ expired }) => (
              <DateInput
                name="training.can1.expiration_date"
                label="Expiration Date"
                className="col-sm-4 padding"
                bottom={true}
                disabled={!isEditable}
                expired={expired}
                shouldConfirmChange={(date) => shouldConfirmDateChange(date, "training.can1")}
                confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
                {...props}
              />
            )}
          </DateStaleIndicator>
          <ComplianceField
            stem="training.can1"
            label="Compliance Code"
            className="col-sm-4 padding"
            disabled={!isEditable}
            showOther={isCan1ComplianceOther}
            shouldConfirmChange={(date) => shouldConfirmComplianceChange(date, "training.can1")}
            confirmText={INVALID_COMPLIANCE_DATE_COMBO_MESSAGE}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Licensing PD Hours</SectionHeader>

          <Input
            name="training.licensing.pd"
            type="number"
            label="Hours"
            min="0"
            step="any"
            className="col-sm-4 padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <ComplianceField
            stem="training.licensing"
            className="col-sm-4 padding"
            showOther={isLicensingPdComplianceOther}
            disabled={!isEditable}         
            {...props}
          />
        </Row>
        {isEditable && (
          <div className="flex__row">
            <div className="flex--auto" />
            <div className="flex--nogrow">
              <RippleButton type="submit" className="btn btn-warning">
                Save
              </RippleButton>
            </div>
          </div>
        )}
      </Form>
    </FormContainer>
  )
}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  handleSubmit: (values, { setSubmitting, props }) => {
    const { erc_id, erc_employee_id, putEmployeeEvaluations } = props
    putEmployeeEvaluations(packageEvaluations(erc_id, erc_employee_id, 'licensing', values))
    setSubmitting(false)
    return values
  },
  mapPropsToValues: (props) => props.evaluation || {},
  validationSchema: Yup.object().shape({}),
  displayName: 'TrainingForm',
})

const EnhancedForm = formikEnhancer(TrainingForm)

const TrainingFormCtrl = (props) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const { getEmployeeEvaluations, isLoading, erc_id, erc_employee_id, evaluation } = props
  useEffect(() => {
    if(!evaluation) {
      getEmployeeEvaluations(erc_id, erc_employee_id)
    } else {
      setFirstLoad(false)
    }
  }, [erc_id, erc_employee_id, evaluation])
  if (firstLoad || isLoading) {
    return <LoaderContainer />
  }
  return <EnhancedForm {...props} />
}

export default TrainingFormCtrl
