import React, { useState, useEffect } from 'react'
import { actions } from 'stores/resources'
import { Link, Navigate } from 'react-router-dom'
import { connect } from "react-redux";
import axios from 'axios'

import { Container } from "react-bootstrap"

import EmployeeSearchTable from './SearchTable'
import TableControls from 'components/shared/TableControls'
import { SectionHeader, NiceHr } from 'components/shared/Layout'
import { ButtonContainer, RippleButton } from 'components/shared/Forms'

import apiUrls from "constants/apiUrls"
import routes, {url} from "constants/routes"

let debouncer = false
let contextQuery = ''

const EmployeeSearch = (props) => {
  const [selected, setSelected] = useState(undefined)
  const [query, setQuery] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { program, inspection, saveEmployees, isLoading: employeeSaving } = props

  useEffect(() => {
    if (debouncer) {
      clearTimeout(debouncer)
    }
    contextQuery = query
    if (!query) {
      return
    }
    debouncer = setTimeout(() => {
      setIsLoading(true)
      axios
        .get(
          `${apiUrls.employeeSearch}?query=${query
            .replace(/[\,\.]/g, ' ')
            .split(' ')
            .map((x) => x.trim())
            .filter((x) => x)
            .join(' ')}&program_id=${program.id}`,
        )
        .then(({ data }) => {
          if (query === contextQuery) {
            setFilteredData(data)
          }
        })
        .finally(() => setIsLoading(false))
    }, 250)
  }, [query])

  const selectUser = (event) => setSelected(event.target.value)

  const onSearch = (event) => {
    const newQuery = event.target.value.toLowerCase()
    setQuery(newQuery)
    setSelected(undefined)
    setFilteredData([])
  }

  const addUser = (program_id, inspection_id, user_id) => {
    saveEmployees({ id: inspection_id, user_id, program_id })
  }


  if (employeeSaving) {
    return <Navigate to={url({program_id: program.id, inspection_id: inspection.id})(routes.employees)} />
  }

  return (
    <Container>
      <SectionHeader>Professional Search</SectionHeader>
      <NiceHr />
      <TableControls
        selected={selected}
        query={query}
        onSearch={onSearch}
        autofocus
        noPadding
        placeholder="Search for a Professional by OPIN or Name"
      />

      <EmployeeSearchTable data={filteredData} onSelect={selectUser} isLoading={isLoading} selected={selected} />

      <ButtonContainer>
        <RippleButton
          type="button"
          className="btn btn-success"
          disabled={!selected || isLoading}
          onClick={() => addUser(program.id, inspection.id, selected)}
        >
          Add User
        </RippleButton>
        <Link
          to={url({program_id: program.id, inspection_id: inspection.id})(routes.employees)}
          className="btn btn-default btn-ripple"
        >
          Cancel
        </Link>
      </ButtonContainer>
      <div className="text--center mvl">
        <Link to={url({program_id: program.id, inspection_id: inspection.id})(routes.employeesAdd)}>
          Click Here to manually add an employee/resident.
        </Link>
      </div>
    </Container>
  )
}

export default connect(
  (state, props) => ({
    isLoading: state.employees.isLoading,
  }),
  {
    saveEmployees: actions.saveEmployees,
  },
)(EmployeeSearch)
