export const url = ({inspection_id = '', program_id = '', erc_employee_id = '' }) => (
   url => (
     url.replace(':inspection_id', inspection_id)
       .replace(':program_id', program_id)
       .replace(':erc_employee_id', erc_employee_id)
   )
)

const base = '/'
const programs = `/programs`
const program = `${programs}/:program_id`
const inspection = `/:program_id/:inspection_id`
const employees = `${inspection}#/employees`
const employee = `${inspection}#/employees/:erc_employee_id`
const licensingOverview = `${inspection}#/licensing-overview`
const sutqOverview = `${inspection}#/sutq-overview`

export const subRoutes = {
  program_info: "#/program_info",
  groups: "#/groups",
  employees: "#/employees",
  // employee
  employeesAdd: "#/employees/add",
  employeesFind: "#/employees/find",
  employee: "#/employees/:erc_employee_id",
  // schedule: "schedule",
  qualifications: "qualifications",
  training: "training",
  staff_requirements: "staff_requirements",
  sutq: "sutq",
  training_overview: "training_overview",
  role_history: "role_history",
  licensingOverview: "#/licensing-overview",
  sutqOverview: "#/sutq-overview",
  // licensing and sutq overview fields
  field: ":field",
  coverage_tool: "#/coverage_tool"
}

export default {
  // Base
  base,
  programs,
  program,
  inspection,
  // Program Information
  programInformation: `${inspection}${subRoutes.program_info}`,
  // Group
  groups: `${inspection}${subRoutes.groups}`,
  // Employees
  employees,
  employeesAdd: `${inspection}${subRoutes.employeesAdd}`,
  employeesFind: `${inspection}${subRoutes.employeesFind}`,
  // One Employee
  employee: `${inspection}${subRoutes.employee}`,
  employeeStaffSchedule: `${employee}`,
  employeeQualifications: `${employee}/${subRoutes.qualifications}`,
  employeeTraining: `${employee}/${subRoutes.training}`,
  employeeStaffRequirements: `${employee}/${subRoutes.staff_requirements}`,
  employeeStaffWorksheet: `${employee}/${subRoutes.sutq}`,
  trainingOverview: `${employee}/${subRoutes.training_overview}`,
  roleHistory: `${employee}/${subRoutes.role_history}`,
  // Licensing Program Overview
  licensingOverview,
  licensingOverviewFields: `${licensingOverview}/${subRoutes.field}`,
  // SUTQ Program Overview
  sutqOverview,
  sutqOverviewFields: `${sutqOverview}/${subRoutes.field}`,
  // Coverage Tool
  coverageTool: `${inspection}/${subRoutes.coverage_tool}`,
}
