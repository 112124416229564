import React from "react";

import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom'

import routes from 'constants/routes'

import PageContainer from 'components/PageContainer'
import PageHeader from "components/shared/PageHeader"
import PageFooter from "components/shared/PageFooter";

import {
  Home,
  ProgramSearch,
  ProgramLoader,
  InspectionLoader,
} from 'components/containers'
import useAuth from "hooks/useAuth";
import Unauthorized from "components/shared/Unauthorized";

function App() {

  const auth = useAuth();

  return (
    <>
      <PageHeader />
      <PageContainer style={{ fontSize: '16px' }} is_loading={!auth.user && !auth.userLoginError}>
        { !!auth.user &&
          <RouterProvider
            router={createBrowserRouter([
              {path:routes.base, element:<Home />},
              {path:routes.programs, element:<ProgramSearch />},
              {path:routes.program, element:<ProgramLoader />},
              {path:routes.inspection, element:<InspectionLoader />},
            ])}
          />
        }
        {
          !!auth.userLoginError &&
          <Unauthorized />
        }
      </PageContainer>
      <PageFooter />
    </>
  );
}

export default App;
