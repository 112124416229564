import React, {useEffect} from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { connect } from "react-redux"
import { getInspection, getInspectionById } from "stores/inspections"
import { getProgram, getProgramById } from "stores/programs"
import { LoaderContainer } from 'components/shared/Loader'
import { Container } from "react-bootstrap"

import routes, {url} from "constants/routes"
import Inspection from 'components/Inspection'
import Employee from 'components/Employee'
import Breadcrumbs from "components/shared/Breadcrumbs"
import { isLoading } from "stores/resources";

export default (props) => {
  return (
    <InspectionLoader {...props} {...useParams()} />
  )
}

const InspectionLoader = connect(
  (state, props) => ({
    inspection: getInspectionById(state, props.inspection_id),
    program: getProgramById(state, props.program_id),
    isLoading: isLoading(state, 'inspections') || isLoading(state, 'programs'),
  }),
  {
    getInspectionConnect: getInspection,
    getProgramConnect: getProgram,
  },
)(props => {
  const {
    inspection_id,
    program_id,
    inspection,
    program,
    getInspectionConnect,
    getProgramConnect,
    isParent,
    isLoading,
    ...rest
  } = props

  const location = useLocation()
  const locationHashes = location.hash?.replace("#/", "").split('/')
  const isEmployeeView = locationHashes[0].match(/employees/) && locationHashes[1] && locationHashes[1] !== 'add' && locationHashes[1] !== 'find'

  let pageBreadcrumbs = [
    {
      to: '/',
      place: 'All ERCs',
    },
    {
      to: '/programs',
      place: 'Programs',
    }
  ]

  if (program) {
    pageBreadcrumbs.push({
      to: `/programs/${program.id}`,
      place: program.program_name,
    })
  }

  if (isEmployeeView && inspection) {
    const isComplete = !!inspection.completed_by
    pageBreadcrumbs.push({
      to: url({inspection_id, program_id,})(routes.employees),
      place: `ERC-${inspection_id}: ${isComplete ? 'Completed' : 'In Progress'}`,
    })
  }

  useEffect(() => {
    if(!inspection || inspection.id !== inspection_id) {
      getInspectionConnect(inspection_id)
    }
    if(!program || program.id !== program_id) {
      getProgramConnect(program_id)
    }
  }, [])

  if(inspection && program) {
    return (
      <div>
        <Container>
          <Breadcrumbs breadcrumbs={pageBreadcrumbs} />
        </Container>
        {
          isEmployeeView
          ?
          <Employee erc_employee_id={locationHashes[1]} {...{program, inspection, inspection_id, program_id}} />
          :
          <Inspection {...{ ...rest, program, inspection, inspection_id, program_id }} />
        }
      </div>
    )
  }
  if(!isLoading) {
    return <div></div>;
  }

  return <LoaderContainer />
})
