import React, { useState, useEffect, Fragment } from 'react'
import { withFormik, Form, FieldArray } from 'formik'
import { fromJS } from 'immutable'
import * as Yup from 'yup'
import getValue from 'components/shared/Forms/getValue'
import {
  SelectInput,
  ButtonContainer,
  Input,
  FormContainer,
  DateInput,
  TimeInput,
  RippleButton,
  SelectButtonInput,
  Checkbox,
} from 'components/shared/Forms'
import { LoaderContainer } from 'components/shared/Loader'
import { DateStaleIndicator } from 'components/shared/DateStaleIndicator'

import { ComplianceField } from 'components/shared/Forms/ComplianceField'
import { Row, SectionHeader } from 'components/shared/Layout'
import { packageEvaluations } from 'utils/evaluationUtils'

const StaffRequirementsForm = (props) => {
  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isEditable,
    isSubmitting,
    isLicensing,
    course,
    program,
    cancel,
    submit,
    onRemove,
    setFieldValue,
    validateForm,
    /* and other goodies */
  } = props

  useEffect(() => {
    validateForm()
    return () => dirty && !isSubmitting && handleSubmit()
  }, [])

  const isOther = (key) => getValue(values, key) === 'other'

  const isMedicalComplianceOther = isOther('staff_requirements.medical.compliance_status')
  const isJfs01176ComplianceOther = isOther('staff_requirements.jfs_01176.compliance_status')
  const isDriversTrainingComplianceOther = isOther('staff_requirements.driver_training.compliance_status')
  const isDriversLicenseComplianceOther = isOther('staff_requirements.driver_license.compliance_status')
  const isDriver = getValue(values, 'staff_requirements.driver.is_driver') === 'yes'
  const bcValues = getValue(values, 'staff_requirements.jfs_01176')

  return (
    <FormContainer>
      <Form autoComplete={'off'}>
        <Row>
          <SectionHeader isInRow noMarginTop>
            <div className="flex__row">
              <div className="flex--auto">Staff Requirements</div>
              {isEditable && (
                <div className="flex--nogrow">
                  <RippleButton type="submit" className="btn btn-warning">
                    Save
                  </RippleButton>
                </div>
              )}
            </div>
          </SectionHeader>
        </Row>
        <Row>
          <Checkbox
            label="Used in Ratio"
            name="staff_requirements.ratio.used_in_ratio"
            {...props}
            className="col-sm-12"
            disabled={!isEditable}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Medical</SectionHeader>

          <DateInput
            name="staff_requirements.medical.date_completed"
            label="Medical Completed"
            className="col-md-4 padding"
            bottom={true}
            disabled={!isEditable}
            {...props}
          />
          <ComplianceField
            stem="staff_requirements.medical"
            label="Compliance Code"
            className="col-md-4 padding"
            disabled={!isEditable}
            centerType={program.center_type}
            showOther={isMedicalComplianceOther}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Background Check Request</SectionHeader>
          <div style={{ paddingLeft: '8px' }}>
            <h5>JFS 01176</h5>
            {
              bcValues && bcValues.request_id
              ?
                bcValues.notification_link
                ?
                <a href={bcValues.notification_link} target="_blank">Request ID: {bcValues.request_id}</a>
                :
                <div>Request ID: {bcValues.request_id}</div>
              :
              "None"
            }
          </div>
          <DateInput
            name="staff_requirements.jfs_01176.date_expired"
            label="Expiration Date"
            disabled
            className="col-md-4 padding"
            bottom={true}
            {...props}
          />
          <SelectInput
            name={`staff_requirements.jfs_01176.on_file`}
            label="Is On File"
            disabled
            className="col-md-4 padding"
            bottom={true}
            {...props}
          >
            <option value="">Please Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </SelectInput>
        </Row>
        <Row>
          <SelectInput
            name="staff_requirements.jfs_01176.eligibility"
            label="Eligibility"
            disabled
            className="col-md-4 padding"
            {...props}
          >
            {[
              { key: '', value: '' },
              { key: 'pending', value: 'Pending' },
              { key: 'eligible', value: 'Eligible' },
              { key: 'ineligible', value: 'Ineligible' },
              { key: 'preliminary', value: 'Preliminary' },
              { key: 'none', value: 'None' },
            ].map((x, i) => (
              <option key={i} value={x.key}>
                {x.value}
              </option>
            ))}
          </SelectInput>
          <ComplianceField
            stem="staff_requirements.jfs_01176"
            label="Compliance Code"
            className="col-md-4 padding"
            showOther={isJfs01176ComplianceOther}
            disabled={!isEditable}
            {...props}
          />
        </Row>
        <Row>
          <DateInput
            name="staff_requirements.jfs_01175.submission_date"
            label="Most Recent Submission Date"
            className="col-md-4 padding"
            disabled
            bottom={true}
            {...props}
          />
        </Row>
        <Row>
          <SectionHeader isInRow>Driver</SectionHeader>

          <SelectButtonInput
            name="staff_requirements.driver.is_driver"
            label="Is Driver?"
            className="col-md-12 padding"
            {...props}
            disabled={!isEditable}
            options={[
              { key: 'yes', value: 'Yes' },
              { key: 'no', value: 'No' },
            ]}
          />
        </Row>
        {isDriver && (
          <Fragment>
            <Row>
              <DateInput
                name="staff_requirements.driver_training.date_completed"
                label="Training Completed"
                className="col-md-4 padding"
                disabled={!isEditable}
                bottom={true}
                {...props}
              />
              <ComplianceField
                stem="staff_requirements.driver_training"
                label="Compliance Code"
                className="col-md-4 padding"
                showOther={isDriversTrainingComplianceOther}
                disabled={!isEditable}
                {...props}
              />
            </Row>
            <Row>
              <DateStaleIndicator date={getValue(values, "staff_requirements.driver_license.date_expiration")} expireNum="0">
                {
                  ({expired}) => (
                    <DateInput
                      name="staff_requirements.driver_license.date_expiration"
                      label="License Expiration Date"
                      className="col-md-4 padding"
                      bottom={true}
                      expired={expired}
                      disabled={!isEditable}
                      {...props}
                    />
                  )
                }
              </DateStaleIndicator>
              <ComplianceField
                stem="staff_requirements.driver_license"
                label="Compliance Code"
                className="col-md-4 padding"
                showOther={isDriversLicenseComplianceOther}
                disabled={!isEditable}
                {...props}
              />
            </Row>
          </Fragment>
        )}
        {isEditable && (
          <div className="flex__row">
            <div className="flex--auto" />
            <div className="flex--nogrow">
              <RippleButton type="submit" className="btn btn-warning">
                Save
              </RippleButton>
            </div>
          </div>
        )}
      </Form>
    </FormContainer>
  )
}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  handleSubmit: (values, { setSubmitting, props }) => {
    const { erc_id, erc_employee_id, putEmployeeEvaluations } = props
    if ('staff_requirements' in values && 'jfs_01175' in values.staff_requirements) {
      const jfs01175 = fromJS(values)
        .getIn(['staff_requirements', 'jfs_01175'])
        .toJS()
      Object.keys(jfs01175).forEach(function(key) {
        if(key === "submission_date") {
          values.staff_requirements.jfs_01175 = {
            "submission_date": jfs01175[key]
          }
        } else {
          values.staff_requirements[`jfs_01175_${key}`] = jfs01175[key]
        }
      })
    }
    putEmployeeEvaluations(packageEvaluations(erc_id, erc_employee_id, 'licensing', values))
    setSubmitting(false)
    return values
  },
  mapPropsToValues: (props) => {
    const { evaluation } = props

    const values = evaluation || {}

    if (values && values.staff_requirements) {
      const { jfs_01176 = {} } = values.staff_requirements
      if (
        !jfs_01176.eligibility ||
        !['pending', 'eligible', 'ineligible', 'preliminary'].includes(jfs_01176.eligibility)
      ) {
        jfs_01176.on_file = '0'
        jfs_01176.eligibility = 'none'
      }
      values.staff_requirements.jfs_01176 = jfs_01176
    }
    return values
  },
  validationSchema: Yup.object().shape({}),
  displayName: 'StaffRequirementsForm',
})

const EnhancedForm = formikEnhancer(StaffRequirementsForm)

const StaffRequirementsFormCtrl = (props) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const { getEmployeeEvaluations, isLoading, erc_id, erc_employee_id, evaluation } = props
  useEffect(() => {
    if(!evaluation) {
      getEmployeeEvaluations(erc_id, erc_employee_id)
    } else {
      setFirstLoad(false)
    }
  }, [erc_id, erc_employee_id, evaluation])
  if (firstLoad || isLoading) {
    return <LoaderContainer />
  }
  return <EnhancedForm {...props} />
}

export default StaffRequirementsFormCtrl
