import styled from 'styled-components'

export default styled.tbody`
  display: block;
  overflow: auto;
  min-height: 432px;
  width: 100%;
  border: solid 1px gray;
  touch-action: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    -webkit-overflow-scrolling: auto;
  }
  
  &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`
