import React from 'react'
import { InputGroup, Button } from 'react-bootstrap'
import { faSearch, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const defaultPlaceholder = 'Search for a record'

const HEIGHT = '42px'
export default ({
  query = '',
  onSearch,
  onRefresh,
  placeholder = defaultPlaceholder,
  autofocus,
  noPadding,
  noMargin,
}) => {
  return (
    <InputGroup
      id="cy-table-controls"
      style={{ padding: noPadding ? '1em 0em' : '1em', marginBottom: noMargin ? '0em' : '1em' }}
    >
      <InputGroup.Text>
        <FontAwesomeIcon icon={faSearch}/>
      </InputGroup.Text>
      <input
        autoFocus={autofocus}
        className="form-control"
        placeholder={placeholder}
        type="search"
        style={{ height: HEIGHT }}
        value={query || ''}
        onChange={onSearch}
      />
      {onRefresh && (
        <Button variant="outline-secondary" onClick={onRefresh}>
          <FontAwesomeIcon icon={faRefresh}/>
          <span> Refresh</span>
        </Button>
      )}
    </InputGroup>
  )
}
