import {
  GET_EMPLOYEE_EVALUATIONS,
  GET_EMPLOYEE_EVALUATIONS_SUCCESS,
  PUT_EMPLOYEE_EVALUATIONS,
  PUT_EMPLOYEE_EVALUATIONS_SUCCESS,
} from './actionTypes'

export const getEmployeeEvaluations = (erc_id, erc_employee_id) => ({
  type: GET_EMPLOYEE_EVALUATIONS,
  payload: { erc_id, erc_employee_id },
})
export const getEmployeeEvaluationsSuccess = (employee_evaluations) => ({
  type: GET_EMPLOYEE_EVALUATIONS_SUCCESS,
  payload: { employee_evaluations },
})

export const putEmployeeEvaluations = (evaluation) => ({
  type: PUT_EMPLOYEE_EVALUATIONS,
  payload: evaluation,
})
export const putEmployeeEvaluationsSuccess = (employee_evaluations) => ({
  type: PUT_EMPLOYEE_EVALUATIONS_SUCCESS,
  payload: { employee_evaluations },
})
