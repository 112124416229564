import React from 'react'
import ReactTable from 'react-table-6'
import { removeItemModal } from 'components/shared/Modals'
import useSticky from "hooks/useSticky";

const EmployeeTable = (props) => {
  const {
    employees,
    removeEmployees,
    isEditable,
    isLoading,
    isLicensing,
    isSUTQ,
    isProgramResident,
    columns = [],
    onSave
  } = props

  const handleRemoveEmployees = async (event) => {
    const { inspection_id, employee_id } = event.target.dataset
    const { first_name, last_name } = employees.find((employee) => employee.id === employee_id)
    const { value: accept } = await removeItemModal(`Remove ${first_name} ${last_name}?`)
    if (accept) {
      await onSave()
      removeEmployees({
        inspection_id,
        employees: [employee_id],
      })
    }
  }

  const actionDrawer = (row) => {
    return (
      <div className="flex__row" style={{ backgroundColor: row.viewIndex % 2 ? 'white' : 'rgba(0,0,0,0.03)' }}>
        <div className="flex--33" style={{ padding: '1em' }}>
          <button
            data-employee_id={row.original.id}
            data-inspection_id={row.original.inspection_id}
            className="btn btn-danger"
            onClick={handleRemoveEmployees}
            style={{ margin: '0em 1em' }}
          >
            Remove {isProgramResident ? 'Employee / Resident' : 'Employee'}
          </button>
        </div>
      </div>
    )
  }

  const displayColumns = columns
    .filter((col) => !col.isSUTQ || (col.isSUTQ && isSUTQ))
    .filter((col) => !col.isLicensing || (col.isLicensing && isLicensing))

  const tableName = 'employees-table';
  const {stickyClass} = useSticky(tableName)

  function onPageChange() {
    const offsetTop = document.getElementsByClassName(tableName)?.[0]?.offsetTop
    window.scrollTo(0,  offsetTop ? (offsetTop - 50) : 0)
  }

  return (
    <ReactTable
      data={employees}
      minRows={2}
      loading={isLoading}
      SubComponent={isEditable && actionDrawer}
      filterable
      onPageChange={onPageChange}
      className={`${tableName} ${stickyClass}`}
      defaultFilterMethod={(filter, row) =>
        String(filter.value)
          .toLowerCase()
          .replace(',', ' ')
          .split(' ')
          .every((x) =>
            String(row[filter.id])
              .toLowerCase()
              .match(x),
          )
      }
      defaultSorted={[
        {
          id: 'full_name',
          desc: false,
        },
      ]}
      columns={displayColumns}
    >
      {(state, makeTable, instance) => {
        return (
          <div>
            {makeTable()}
            <div style={{ textAlign: 'center', padding: '1em' }}>Displaying {employees.length} Records</div>
          </div>
        )
      }}
    </ReactTable>
  )
}

export default EmployeeTable
