import {GET_PROGRAM_SUCCESS, GET_PROGRAM_FAILURE, GET_PROGRAM} from "./actionTypes";
import serviceErrorHandler from "utils/serviceErrorHandler";

const initialState = {}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROGRAM:
            return {
                ...state,
                isLoading: true
            }
        case GET_PROGRAM_SUCCESS:
            const { program } = action.payload
            return {
                ...state,
                [program.id]: program,
                isLoading: false
            }
        case GET_PROGRAM_FAILURE:
            serviceErrorHandler(action.error)

            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}