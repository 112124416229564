import React from 'react'

export function ComplianceCode(props) {
  const { isEditable, name, onChange } = props
  const value = props.value || props.defaultValue
  return (
    <select disabled={!isEditable} value={value || ""} onChange={onChange} className="erc-compliance-code--field">
      <option value=""> - </option>
      <option value="I">I</option>
      <option value="O">O </option>
      <option value="NA">NA</option>
      <option value="PV">PV</option>
      <option value="NV">NV</option>
    </select>
  )
}
