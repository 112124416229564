import {fromJS} from "immutable";

export const packageEvaluations = (erc_id, erc_employee_id, evaluationType, values) => {
  const payload = {
    erc_id,
    erc_employee_id,
    evaluation_types: {
      [evaluationType]: values,
    },
  }
  return payload
}

export const formatCDA = cda => (
  String(cda)
    .trim()
    .replace(" (", " - ")
    .replace(")", "")
)

export const data_get = function(dot_string, obj) {
  return dot_string.split('.').reduce((o, i) => {
    if (!o) {
      return undefined
    }
    return o[i]
  }, obj)
}

export const data_set = function(dot_string, obj, val) {
  return fromJS(obj || {})
    .setIn(dot_string.split("."), val)
    .toJS()
}

export const getFormKey = function(key) {
  const base = key.replace("eval", "")
  const fieldType = (base.indexOf('sutq') !== -1) ? "sutq" : "licensing"
  return fieldType + base
}
