import React from "react"
import {Alert} from "react-bootstrap";

export default () => (
  <Alert variant="danger" className="text--center mtl">
    <Alert.Heading>Unauthorized</Alert.Heading>
    <p>
      Unable to obtain user information.
    </p>
  </Alert>
)