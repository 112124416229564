import React from 'react'
import styled from 'styled-components'

export default ({value, maxLength}) => {
    const Counter = styled.div`
    font-size: smaller;
    display: flex;
    flex-direction: row-reverse;
    `
    return <Counter><span>{value && value.length || 0} / {maxLength}</span></Counter>
}