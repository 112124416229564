import serviceErrorHandler from "utils/serviceErrorHandler";

import {
  CREATE_INSPECTION_SUCCESS,
  GET_INSPECTION_SUCCESS,
  GET_INSPECTIONS_SUCCESS,
  COMPLETE_ERC_SUCCESS,
  CLONE_ERC_SUCCESS,
  UNLOCK_ERC_SUCCESS,
  UPDATE_INSPECTION_SUCCESS,
  DELETE_INSPECTION_SUCCESS,
} from './actionTypes'

const initialState = {}

const updateState = (state, inspection) => {
  return {
    ...state,
    isLoading: false,
    [inspection.id]: inspection,
  }
}

const removeInspection = (state, id) => {
  const newState = { ...state, isLoading: false }
  delete newState[id]
  return newState
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INSPECTION_SUCCESS:
    case UPDATE_INSPECTION_SUCCESS:
      return updateState(state, action.payload.inspection)
    case DELETE_INSPECTION_SUCCESS:
      return removeInspection(state, action.payload.id)
    case GET_INSPECTION_SUCCESS:
      return updateState(state, action.payload.inspection)
    case CLONE_ERC_SUCCESS:
    case COMPLETE_ERC_SUCCESS:
      return (action.payload.inspections || []).reduce((prev, curr) => {
        return updateState(prev, curr)
      }, state)
    case UNLOCK_ERC_SUCCESS:
      return (action.payload.inspections || []).reduce((prev, curr) => {
        return updateState(prev, curr)
      }, state)
    case GET_INSPECTIONS_SUCCESS:
      return (action.payload.inspections || []).reduce(
        (prev, curr) => {
          return updateState(prev, curr)
        },
        {
          ...state,
          isLoading: false,
        },
      )
    default:
      if (action.type.match('INSPECTION') || action.type.match('ERC')) {
        if (!action.type.match('FAILURE')) {
          return {
            ...state,
            isLoading: true,
          }
        }
        if (action.error) {
          serviceErrorHandler(action.error)
        }
        return {
          ...state,
          isLoading: false,
        }
      }
      return state
  }
}
