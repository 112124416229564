import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Button = styled.button`
  width: 2em;
  height: 2em;
  border: none;
  max-height: 32px;
  display: flex;
  margin: auto 0.75em;
  align-items: center;
  justify-content: center;
  background: none;
  &:hover {
    background: rgba(1, 1, 1, 0.25);
  }
`

const IconButton = (props) => {
  const { onClick, icon, color } = props
  return (
    <Button className="flex--nogrow" onClick={onClick}>
      <FontAwesomeIcon icon={icon} color={color || "white"} />
    </Button>
  )
}
export default IconButton
